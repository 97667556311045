html {
    overflow-y: scroll;
    overflow-x: hidden;
    overscroll-behavior: none;
}
body {
    overscroll-behavior: none;
    background-color: var(--color-body-background);
}

a:hover,
a:focus {
    color: var(--color-primary) !important;
}

::-moz-selection {
    /* Code for Firefox */
    color: var(--white);
    background: var(--color-primary);
}

::selection {
    color: var(--white);
    background: var(--color-primary);
}

* {
    font-family: 'Visby', sans-serif;
    letter-spacing: 0.5px;
    font-weight: normal;
    font-size: 1rem;
}

.fz-15 {
    font-size: 15px;
}

.nav-fix-top {
    background-color: var(--color-body-background);
}

.nav-fix-top-mobile-placeholder {
    height: 165px;
}

.fade-in {
    opacity: 1;
}

.text-bold {
    font-weight: bold;
}

.text-error {
    font-weight: 600;
    color: #af0016;
}

.nav-fix-top-desktop-placeholder {
    height: 130px;
}

.main-menu {
    min-height: 56px;
}

.nav-item.nav-mega {
    display: flex;
    align-items: center;
}

.nav-mega-list li {
    margin-right: 25px;
}

.nav-mega-link-row {
    height: 310px !important;
}

.main-menu .nav-mega .dropdown-menu .nav-mega-footer-row {
    position: absolute;
    margin-bottom: 0px !important;
    bottom: 0;
}
.col-sec-list {
    height: 100%;
    border-right: 1px solid rgba(0, 0, 0, 0.09);
}
.nav-sec-list {
    padding: 5px 5px;
    &:hover,
    .active {
        color: var(--color-primary);
    }
    a {
        color: black;
        &:hover {
            color: var(--color-primary);
        }
    }
    .fa-caret-right {
        display: none;
        margin-left: 3px;
    }
}

.custom-nav-bar-mega.nav-link {
    cursor: pointer;
}
.dropdown--active .custom-nav-bar-mega.nav-link {
    color: var(--color-primary);
}

.cart-dropdown-img {
    display: flex;
    justify-content: center;
    max-height: 80px;
}

.__react_component_tooltip {
    text-align: center;
    font-size: 15px !important;
    padding: 20px 21px !important;
    width: 250px;
}

.slider-pagination {
    background-color: black;
    //Dot:
    width: 20px;
    height: 20px;
    border-radius: 20px;
    transition: 0.3s ease;

    margin: 20px 12px;
    &:hover {
        cursor: pointer;
        transform: scale(1.2);
    }
}

.slider-pagination.active {
    background-color: var(--color-primary) !important;
    //Dot:
    width: 45px;
    height: 20px;
    border-radius: 20px;
    transition: 0.3s ease-out;
}

.rec-carousel-wrapper {
    width: calc(100% + 3rem) !important;
    margin-left: -1.5rem;
}

.rec-carousel {
    height: 386px !important;
}

.shop-side-bar {
    .filter-title {
        padding-left: 0;
    }
    .list-group-item {
        padding: 0.75rem 0 0.75rem 0.75rem;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        &:hover:not(.active) {
            background-color: #ebebeb;
        }
    }
    .list-group-item.active::after {
        content: '';
        float: right;
        width: 6px;
        height: 9px;
        margin-top: 3px;
        margin-right: 10px;
        border-right: 3px solid #fff;
        border-bottom: 3px solid #fff;
        transform: rotate(45deg);
    }

    .btn-link {
        text-align: left;
    }
    .btn-link:hover {
        transform: none;
    }

    .btn-link::after {
        content: '+';
        color: #979797;
        margin-top: 12.5px;
        font-size: 1.5em;
        font-weight: 300;
        line-height: 0.5em;
        top: 9px;
        right: 9px;
        position: absolute;
        // transform: rotate(45deg);
        transition: transform 0.3s ease-in-out;
    }
}

.btn-link.rotated::after {
    transform: rotate(-225deg);
}

.modal-open {
    padding-right: 0 !important;
}

.modal-steves {
    max-width: 100%;
    z-index: 2010;
    .modal-dialog {
        max-width: 100%;
    }
    .modal-content {
        max-width: 650px;
        margin: auto;
        border-radius: 40px;

        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        pointer-events: auto;
        background-color: #fff;
        background-clip: padding-box;
        // border: 1px solid rgba(26,26,26,0.2);
        // border-radius: .3rem;
        outline: 0;
    }
}

.react-toast-notifications__container {
    z-index: 2050 !important;
    // width:50%;
    // justify-content:center;
    // .react-toast-notifications__toast{
    //     width:100%;
    // }
}

// .react-toast-notifications__toast--success{
// color: white !important;
// background-color: #0d6b4d !important;
// }

.noUi-connect {
    background-color: var(--color-primary) !important;
}

.hover-underline:hover {
    text-decoration: underline;
    text-decoration-color: black;
}

.mobile-menu-container .user-section:after {
    top: 130px;
}

.react-toast-notifications__toast {
    width: 100% !important;
    min-width: 360px !important;
}

.btn .fa.fa-long-arrow-right {
    color: white;
    font-size: 14px;
}

.mobile-menu-container {
    .mega-menu ul li:after {
        background: none;
        border-top: 0.5px dashed grey;
    }
    .mega-menu .sub-menu {
        top: 170px;
    }
}

.scrollTop {
    cursor: pointer;
    position: fixed;
    z-index: 1041;
    width: 50px;
    height: 50px;
    right: 20px;
    bottom: 20px;
    box-shadow: 0 0 15px 0 rgba(255, 255, 255, 0.7);
    border-radius: 50%;
    border: none;
}

.main-menu-top {
    .item:before {
        color: var(--color-primary);
        background-color: var(--color-primary);
    }
}

.no-vertical-line::before {
    background: transparent !important;
}

@media (max-width: 600px) {
    .mb-sm-7 {
        margin-bottom: 3rem;
    }
    .awssld__bullets {
        display: none;
    }
    .m-px-0 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}
