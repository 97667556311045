
.dropdown-menu{
    width:375px;

    .dropdown-header{
      font-size: 1rem;
    }
    #cart-dropdown-item{
      overflow-x: hidden ;
      overflow-y: scroll;
      max-height: calc(80vh - 115px);
  
      cursor: auto;
  
      .cart-dropdown-ptitle:hover{
        text-decoration: underline;
      }
  
      .remove-line-item{
        font-size: 20px;
        position: absolute;
        top: 50%;
        right:5%;
        transform: translateY(-50%);
        z-index:100;
        &:hover{
          color:red;
        }
      }
  
      .remove-loader{
        font-size: 20px;
        position: absolute;
        top: 40%;
        right:5%;
        transform: translateY(-50%);
        z-index:100;
      }
  
      .fa-spin {
        -webkit-transform-origin: 50% calc(50% - .5px);
        transform-origin: 50% calc(50% - .5px);
      }
    }
    max-width: calc(100vw - 10px);
    p{
      color: #000 !important;
    }
  }
  
  
  .cart-dropdown:after {
    bottom: 100%;
    left: 85%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: white;
    border-width: 8px;
    margin-left: -8px;
  }
  
  .badge-number-dropdown{
    position: relative;
    font-size: 0.7rem;
    //padding: 10px 4px;
    border-radius: 10rem;
    min-width: 1.5rem;
    min-height: 1rem;
  }
  //
  //.dropdown-item{
  //  width: inherit;
  //}
  
  .row-line-items{
    margin-left: 0;
    margin-right:0;
    max-width: calc(100% - 3px);
    &:hover{
      background-color:#e8f8fe ;
    }
  }
  
  .discount-text{
    color: grey;
    font-size: 12px;
    letter-spacing: 0;
  }