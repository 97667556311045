// Theme
//
// Change the default theme color, fonts, sizing, etc. by modifying
// the following variables.
// Google fonts
// @import url("https://use.typekit.net/yfn0fdk.css");
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700|Roboto:500&display=swap');

@font-face {
    font-family: 'Visby';
    font-style: normal;
    font-weight: normal;
    src: local('VisbyCF-Medium'), url('../../fonts/VisbyCF-Medium.otf') format('opentype');
}

@font-face {
    font-family: 'Visby';
    font-style: normal;
    font-weight: bold;
    src: local('VisbyCF-Bold'), url('../../fonts/VisbyCF-Bold.otf') format('opentype');
}

:root {
    --blue: #1c97d3;
    --indigo: #6610f2;
    --purple: #926dde;
    --pink: #e83e8c;
    --red: #fa6e6e;
    --orange: #ffbe00;
    --yellow: #ffba00;
    --green: #3cd458;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #ffffff;
    --grey: #919191;
    //--black: #141313;
    //--black: #4a4c4a;
    --black: #1a1a1a;
    --light-grey: #a5a5a5;

    --color-success: var(--green);
    --color-info: var(--light-grey);
    --color-warning: var(--yellow);
    --color-danger: var(--red);
    --color-light: #f8f9fa;
    --color-dark: var(--black);
    --color-grey: #979797;
    --color-light-grey: var(--light-grey);
    --color-body-background: #f9f9f9;

    --color-third: var(--color-primary);
    --color-icon: var(--color-third);

    //----------------------------------------
    // Background colors
    //----------------------------------------
    --color-bg-lightest: #fcfdfe;
    --color-bg-lighter: #f9fafb;
    --color-bg-light: #f5f6f7;
    --color-bg-body: #ffffff;
    --color-bg-dark: #191919;
    --color-bg-gray: #fafbfb;
    --color-dark-gray: #e6e6e6;
    --color-error: #fa6e6e;

    //----------------------------------------
    // Text colors
    //----------------------------------------
    --color-text-darker: #323d47;
    --color-text-dark: #555555;
    --color-text: var(--black);
    --color-text-light: #999999;
    --color-text-lighter: #bfc5ca;
    --color-text-lightest: #d3d3d3;
    --color-text-secondary: #929daf;
    --color-text-disable: #a5b3c7;
    --color-text-placeholder: #c9ccce;
    --color-title: var(--black);
    --color-subtitle: var(--color-text-light);

    // Divider color
    --color-divider: #eaeff4;
    --color-divider-light: #f1f2f3;
    --color-divider-dark: #e8e8e8;

    ////----------------------------------------
    // Fonts
    ////----------------------------------------
    //brandon-grotesque, sans-serif; 400,900
    //"Open Sans", sans-serif; 400, 700
    --font-family-base: 'Open Sans', sans-serif;
    // --font-family-title: 'Rubik', sans-serif;
    --font-family-title: 'Visby', sans-serif;
    --font-family-special: brandon-grotesque, sans-serif;
    --font-family-number: 'Roboto', sans-serif;

    --font-size-base: 0.9375rem;

    --font-weight-base: 400;
    --line-height-base: 1.2;

    --h1-font-size: --font-size-base * 1.75;
    --h2-font-size: --font-size-base * 1.6;
    --h3-font-size: --font-size-base * 1.45;
    --h4-font-size: --font-size-base * 1.3;
    --h5-font-size: --font-size-base * 1.15;
    --h6-font-size: --font-size-base * 1;

    --h1-font-weight: 500;
    --h2-font-weight: 500;
    --h3-font-weight: 500;
    --h4-font-weight: 500;
    --h5-font-weight: 500;
    --h6-font-weight: 500;

    --display1-size: 5rem;
    --display2-size: 4rem;
    --display3-size: 3.5rem;
    --display4-size: 3rem;

    --display1-weight: 200;
    --display2-weight: 200;
    --display3-weight: 200;
    --display4-weight: 200;

    //----------------------------------------
    // Navbar
    //----------------------------------------
    --navbar-min-height: 56px;
    --navbar-offset-top: 10px;

    //----------------------------------------
    // Hamburger Menu Variables
    //----------------------------------------
    --bar-width: 30px;
    --bar-height: 1px;
    --bar-spacing: 10px;

    //----------------------------------------
    // Easing
    //----------------------------------------
    --fluid: 0.7, 0.3, 0, 1;
    --fluidless: 0.5, 0.5, 0, 1;
    --smootheaseout: 0.62, 0.02, 0.34, 1;
    --intenseeaseout: 1, 0, 0, 1;
    --lessintenseperfect: 0.55, 0, 0.45, 1;
    --suckitin: 0.38, -0.32, 0.89, 0.34;
    --smoothswing: 0.4, 0.3, 0.1, 0.4;
    --gentleeasein: 0.5, 0, 0.57, 1;
    --lineareaseout: 0.45, 0.65, 0.64, 1;
    --sweepinginout: 0.52, 0.01, 0.16, 1;
    --lineareasein: 0.52, 0.7, 0.41, 1;
    --gentleout: 0.11, 0.62, 0.3, 0.98;
    --sweetout: 0.19, 1, 0.22, 1;
    --bouncey: 0.1, 0.55, 0.29, 1.44;

    //----------------------------------------
    // Added for Bootstrap Select
    //----------------------------------------
    --color-red-error: rgb(185, 74, 72);
    --color-green-success: #28a745;
    --color-grey-arrow: rgba(204, 204, 204, 0.2);
    --width-default: 220px; // 3 960px-grid columns
    --zindex-select-dropdown: 1060; // must be higher than a modal background (1050)

    //** Placeholder text color
    --input-color-placeholder: #999999;
    --input-alt-color-placeholder: rgba(255, 255, 255, 0.5);

    --input-padding-y-sm: 0.25rem;
    --input-padding-x-sm: 0.5rem;

    --input-padding-y-lg: 0.5rem;
    --input-padding-x-lg: 1rem;

    --custom-control-indicator-border-color: #555555;
}

a {
    color: var(--color-primary);
}

//Bootstrap Updates See https://github.com/twbs/bootstrap/issues/19402
a:not([href]) {
    &,
    &:hover {
        color: inherit !important;
        text-decoration: none;
    }
}

.container {
    max-width: 1280px;
}

.btn {
    border-radius: 6px;
}

.btn-primary,
.btn-primary:disabled,
.btn-primary:active,
.btn-primary:focus {
    background-color: var(--color-primary);
    border: var(--color-primary);
    color: white;
}

.btn-secondary,
.btn-secondary:disabled,
.btn-secondary:active,
.btn-secondary:focus {
    background-color: var(--color-secondary);
    border: var(--color-secondary);
    color: white;
}

.btn-primary:hover,
.btn-secondary:hover {
    background-color: black;
    border: none !important;
}

.btn-primary:active,
.btn-secondary:active {
    border: none !important;
}

.color-primary {
    color: var(--color-primary) !important;
}

.color-secondary {
    color: var(--color-secondary) !important;
}

.color-third {
    color: var(--color-third) !important;
}

.badge-danger {
    background-color: var(--color-third);
    border-bottom-right-radius: 15px;
}

.main-menu .nav-mega .dropdown-menu .nav-link:hover {
    color: var(--color-primary) !important;
}

.recommend-item .wish-list-icon {
    color: var(--color-primary);
}

.recommend-item .price .price-lowest span {
    color: var(--color-third);
}

.variant-panel .price-lg {
    color: var(--color-primary);
}

.cart-pop-price {
    color: var(--color-primary);
}

#couponCode:focus,
#customer-info-form input:focus,
#customer-info-form select:focus,
#customer-login-form input:focus,
#paymentForm input:focus {
    border-color: var(--color-primary);
}

.badge-primary {
    background-color: var(--color-primary);
}

.commerce-progress-bar .current i {
    color: var(--color-primary);
}

.footer {
    border-top: 8px solid var(--color-primary);
}

.page-header .title-header {
    color: var(--color-primary);
}
.breadcrumb .label {
    color: var(--color-primary);
}

.bg-primary {
    background-color: var(--color-primary) !important;
}

.bg-secondary {
    background-color: var(--color-secondary) !important;
}

.list-group-item.active {
    background-color: var(--color-primary);
}

.form-control:active,
.phone-number:active {
    border: 1px solid #757575;
}

.modal-backdrop {
    z-index: 1043;
}
