.checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

  
  /* Hide the browser's default checkbox */
   input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: calc(50% - 1.125rem/2);
    left: 0;
    height: 1.125rem;
    width: 1.125rem;
    // background-color: #eee;
    border-radius: 2px;
    border: 1px solid #555555;

  }
  
  /* On mouse-over, add a grey background color */
//   &:hover input ~ .checkmark {
//     background-color: #ccc;
//   }
  
  /* When the checkbox is checked, add a blue background */
   input:checked ~ .checkmark {
    background-color:var(--color-primary);
    border: none;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .checkmark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.checkbox-container.is-invalid{
  color: var(--color-error);
  .checkmark{
    border: 1px solid var(--color-error);
  }
  a{
    font-weight: bold;
    color: var(--color-error);
  }
}