//the sass default theme variable
$font-size-base:                  1rem;

$font-weight-base:                400;
$line-height-base:                1.9;

$h1-font-size:                    $font-size-base * 1.75;
$h2-font-size:                    $font-size-base * 1.60;
$h3-font-size:                    $font-size-base * 1.45;
$h4-font-size:                    $font-size-base * 1.30;
$h5-font-size:                    $font-size-base * 1.25;
$h6-font-size:                    $font-size-base * 1.00;

$h1-font-weight:                  500;
$h2-font-weight:                  500;
$h3-font-weight:                  500;
$h4-font-weight:                  500;
$h5-font-weight:                  500;
$h6-font-weight:                  500;

$display1-size:                   5rem;
$display2-size:                   4rem;
$display3-size:                   3.5rem;
$display4-size:                   3rem;

$display1-weight:                 300;
$display2-weight:                 300;
$display3-weight:                 300;
$display4-weight:                 300;

$nav-tabs-link-active-color:      #000;

$input-line-height:               1.2;
$button-line-height:              1.2;
$card-cap-bg:                     transparent;