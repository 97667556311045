
////----------------------------------------
// Overlay Effect
////----------------------------------------
.cart-qty{
    .spinner{
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      z-index: 3;
  
      .fa.fa-spinner.fa-spin{
        color: $black !important;
        font-size: 50px;
        animation-duration: 1s;
      }
    }
  
    .form-quantity {
      display: flex;
      justify-content: center;
      align-items: center;
      z-index:2;
    }
  
    .value-button {
      display: inline-block;
      width: 25px;
      height: 30px;
      text-align: center;
      vertical-align: middle;
      padding: 8px 0;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      i{
        font-size:14px;
        line-height: 10px;
      }
    }
  
    .value-button:hover {
      cursor: pointer;
    }
  
    .input-number {
      text-align: center;
      border: 1px solid #ddd;
      margin: 5px 0;
      width: 50px;
      height: 30px;
  
      &:focus {
        outline-color: transparent !important;
        outline-style: none !important;
      }
    }
  
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin-top: 0;
    }
  
    .variant-label{
      color: #000000;
      font-size: 1rem;
      font-weight: 600;
      letter-spacing: -0.3px;
      line-height: 1rem;
      .original{
        display: block;
      }
      .shorten{
        display: none;
      }
    }
  
    .fa{
      color: $white;
    }
    //.fa-spinner{
    //  color: $black;
    //  position: absolute;
    //  left: 37%;
    //  font-size: 20px;
    //}
  }
  .list-view-overlay{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    //overflow-x: auto;
    width: 100%;
    left:0;
  }
  
  .quantity-overlay-show {
    background-color: white;
    overflow-y: auto;
    bottom: 0;
    height: 100%;
  }
  
  .fa-chevron-up{
    color: $white;
    margin-left:5px;
    &.add-to-cart-list{
      color:$primary;
    }
  }
  
  .rotate180{
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  
  .list-view.recommend-item{
    .variant-label{
      @media (max-width: 576px) {
        .original{
          display: none;
        }
        .shorten{
          display: block;
        }
      }
    }
  }
  
  
  