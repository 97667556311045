.color-dark {
    color: var(--color-dark) !important;
  }
  
  .color-white {
    color: var(--white) !important;
  }
  
  .color-primary {
    color: var(--color-primary) !important;
  }
  
  .color-secondary {
    color: var(--color-secondary)  !important;
  }
  
  .modal-dialog{
      max-width: 90%;
      margin: auto;
      .close{
          position: absolute;
          right: 10px;
          top: 10px;
          span{
              font-size: 26px;
              font-weight: bold;
          }
      }
  }
  
  .modal-steves {
    max-width:     650px;
    margin:        auto;
    border-radius: 40px;
  
    h1, h2, h3, h4, h5, h6 {
      color: var(--color-secondary);
    }
  
    h4 {
      font-weight: bold;
    }
  
    select[name="store-selected"],
    .modal-body input {
      height:           45px;
      //width: 400px;
      border-radius:    4px;
      background-color: #eeeeee;
      box-shadow:       inset 0 1px 0 0 rgba(0, 0, 0, 0.1);
  
    }
      input[type="text"]{
          &:focus{
              cursor: text!important;
          }
      }
      .error-message{
          bottom: -30px;
          left: auto;
          right: 0;
          width: 100%;
          text-align: center;
      }
  }
  
  .table-steves {
    margin: 0px auto;
    border: 6px solid var(--color-primary);
  
    tr {
      padding: 0
    }
  
    td {
      height: 40px;
    }
  
    .steves {
      font-weight:    200;
      width:          152px;
      color:          var(--color-primary);
      font-size:      41px;
      //font-weight: 900;
      letter-spacing: -0.77px;
      line-height:    34px;
      text-align:     center;
    }
  
    .liquor {
      width:          152px;
      color:          var(--color-primary);
      font-size:      41px;
      font-weight:    900;
      letter-spacing: -0.77px;
      line-height:    34px;
    }
  }
  
  //----------------------------------------
  // Radio Style
  //----------------------------------------
  .modal {
    .custom-radio .custom-control-label::after {
      top:     6.5px !important;
      z-index: 100;
    }
  
    .custom-control-label::before {
      border: #adb5bd solid 1px !important;
    }
  }
  
  //----------------------------------------
  // Checkbox Style
  //----------------------------------------
  .checkbox-big {
    //padding: 50px;
  
    .form-group {
      display:       block;
      margin-bottom: 15px;
    }
  
    .form-group input {
      padding:       0;
      height:        initial;
      width:         initial;
      margin-bottom: 0;
      display:       none;
      cursor:        pointer;
    }
  
    //.form-group label
    .form-group input[type="checkbox"] + label {
      position: relative;
      cursor:   pointer;
    }
  
    //.form-group label:before
    .form-group input[type="checkbox"] + label:before {
      content:            '';
      -webkit-appearance: none;
      border:             1px solid var(--grey);
      border-radius:      4px;
      //box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
      padding:            7px;
      display:            inline-block;
      position:           relative;
      vertical-align:     middle;
      cursor:             pointer;
      margin-right:       5px;
    }
  
    .form-group input[type="checkbox"]:checked + label:before {
      border-color:     var(--color-primary) !important;
      background-color: var(--color-primary);
    }
  
    .form-group input[type="checkbox"]:checked + label:after {
        font-family: "FontAwesome";
      content:  '\f00c';
      color:    white;
      display:  block;
      position: absolute;
      top:      1px;
      left:     1.5px;
      //width: 6px;
      height:   14px;
      //border: solid white;
      //border-width: 0 2px 2px 0;
      //transform: rotate(45deg);
    }
  
    .border-right-secondary {
      border-right: 1px solid var(--color-secondary);
    }
  
  }
  
  //----------------------------------------
  // Sign up
  //----------------------------------------
  .btn-google {
    background-color: #db3236;
    border-radius:    4px !important;
  }
  
  .btn-facebook {
    background-color: #225b99;
    border-radius:    4px !important;
  }
  