//--------------------------------------------------------------------------
// Commerce - Checkout Widget Progress Bar
//--------------------------------------------------------------------------

.commerce-progress-bar {
    border-bottom:  1px solid var(--grey);
    padding-bottom: 2px;
  
    .fa {
      font-size:    25px;
      margin-right: 5px;
    }
  
    .media-body {
      p {
        font-weight: 600;
        color:       var(--black);
      }
  
      a {
        font-family:  var(--font-family-title);
  
        font-size:    18px;
        font-weight:  800;
        cursor:       pointer;
        color:        var(--color-secondary);
      }
    }
  
    .current {
      i {
        color: var(--color-primary);
      }
  
      .media-body a {
        color:         var(--color-primary);
        border-bottom: 5px solid var(--color-primary);
      }
    }
  
    .link-disable {
      .media-body {
        a {
          cursor: default !important;
          color:  var(--grey);
        }
  
        p, i {
          color: var(--grey);
        }
  
      }
    }
  
    @media(max-width: 500px) {
      display: none !important;
    }
  
  }