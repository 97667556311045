:root {
    --color-primary: #fe6c1c!important;
    --color-secondary: #000000!important;
    --color-third: #6e702e!important;
    --color-icon: var(--color-third);
}

.static-page {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Libre Baskerville', serif !important;
    }
}

.btn-secondary,
.btn-secondary:disabled {
    background-color: var(--color-third) !important;
    color: white !important;
    border: var(--color-third) !important;
}

.btn-secondary:hover {
    background-color: var(--color-secondary) !important;
    color: white !important;
    border: var(--color-secondary) !important;
}

.logo-wrapper {
    margin-bottom: -30px;
    .theme-logo {
        height: 60px;
    }
}

.awssld__bullets--active {
    width: 45px !important;
}



// .main-menu .nav-navbar {
//     width: 75% !important;
//     .nav-link {
//         padding: 0 0.75rem !important;
//     }
// }

// .main-menu .search {
//     width: 25% !important;
//     background-color: #eaeff4;
//     border: #e6e6e6;
//     // e6e6e6
//     // eaeff4
// }

.m-logo {
    width: 150px;
    height: 49px;
}

@media (max-width: 1250px) {
    // .main-menu .nav-navbar {
    //     width: 70% !important;
    // }
    // .main-menu .search {
    //     width: 30% !important;
    // }

    // .main-menu .nav-navbar {
    //     .nav-link {
    //         padding: 0 0.6rem !important;
    //     }
    // }
}
