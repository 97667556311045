.google {
    .icon {
        width: 25px;
    }
}

.facebook {
    .icon {
        width: 30px;
        height: 30px;
    }
}

.social-btn-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.social-btn-wrapper-mobile {
    max-height: 110px;
    flex-wrap: wrap;
    justify-content: center;
    .social-btn {
        // background-color: white !important;
        width: 140px;
        height: 43px;
        &:not(:last-child) {
            margin-bottom: 15px;
        }
    }
}

.social-btn {
    background-color: transparent !important;
    color: #333 !important;
    border: 1px solid #d5d8db !important;
    line-height: 30px;
    min-width: 167px;
    box-shadow: none !important;
    border-radius: 6px;

    padding-left: 15px;
    padding-right: 15px;

    &:hover,
    &:focus,
    &:active {
        border: 1px solid #000 !important;
        background-color: transparent !important;
        color: #333 !important;
    }

    .btn-inner {
        display: flex;
        justify-content: space-around;
    }

    .fa {
        font-size: 15px;
        color: black;
    }
}
