.desktop-popup-wrapper{
    width: 500px;
    min-height: 300px;
    z-index: 1400;
    position: fixed;
    background-color: white;
    display: flex;
    // flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    // align-items: center;

    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
    border: 1px solid #bebebe;

    left: auto;
    right: 24px;
    bottom: 24px;

    .btn-close{
        width: 100%;
        position: absolute;
        padding: 10px;
        display: flex;
        justify-content: flex-end;
    }

    .img-wrapper{
        padding: 15% 10% 0;
        img{
            width: 100%;
            max-width: 300px;
            min-height: 100px;
        }
    }

    .text1{
        display: flex;
        justify-content: center;
        padding: 15px 30px;
    }

    .text2{
        display: flex;
        justify-content: center;
        font-weight: bold;
        padding: 0 30px 15px 30px;
    }
    .btn-bookmark{
        width: calc(100% - 30px);
        margin: 0 15px 15px 15px;
        min-height: 45px;
    }
}

.ios-popup-wrapper{
    width: 230px;
    // height: 200px;
    z-index: 2100;
    position: fixed;

    background-color: var(--color-dark-gray);
    display: flex;
    // flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    // align-items: center;

    // box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
    border: 1px solid var(--light-grey);

    left: calc(50% - 115px);
    right: calc(50% - 115px);
    bottom: 30px;

    text-align: center;
    padding: 5px 0;

    .btn-close{
        position: absolute;
        background-color: var(--light-grey);
        right: 0;
        top:0;
        height: 23px;
        color: white;
    }

    .img-wrapper{
        padding-top: 5%;
        overflow: hidden;
        img{
            border-radius: 14px;
            width: 80px;
            height: 80px;
        }
    }
    &:after{
        content: '';
        width: 50px;
        height: 50px;
        position: absolute;
        bottom: -25px;
        background-color: inherit;
        z-index: -1;
        transform: rotate(45deg);
        border: 1px solid var(--light-grey);
        border-top: none;
        border-left: none;
    }
}

.android-popup-wrapper{
    width: 230px;
    // height: 200px;
    z-index: 2100;
    position: fixed;

    background-color: var(--color-dark-gray);
    display: flex;
    // flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    // align-items: center;

    // box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
    border: 1px solid var(--light-grey);

    left: calc(50% - 115px);
    right: calc(50% - 115px);
    bottom: 30px;

    text-align: center;
    padding: 5px 0;

    .btn-close{
        position: absolute;
        background-color: var(--light-grey);
        right: 0;
        top:0;
        height: 23px;
        color: white;
    }

    .img-wrapper{
        padding-top: 5%;
        overflow: hidden;
        img{
            border-radius: 14px;
            width: 80px;
            height: 80px;
        }
    }
    p.small{
        border-top: 1px solid var(--color-third);
        padding-top: 5px;
        font-size: 8px;
    }
}