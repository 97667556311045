.nav{
    .awssld  {
        --slider-height-percentage: 60%;
        --slider-transition-duration: 525ms;
        --organic-arrow-thickness: 4px;
        --organic-arrow-border-radius: 0px;
        --organic-arrow-height: 15px;
        --organic-arrow-color: #26456f;
        --control-button-width: 20%;
        --control-button-height: 25%;
        --control-button-background: transparent;
        --control-bullet-active-color: black;
        --loader-bar-color: #f2be44;
        --loader-bar-height: 6px;

        width: 200px;
        height: 300px;
    }

    .awssld__bullets button {
        background-color: black;
        width: 30px;
        height: 5px;
        border-radius: 2px;
        margin-bottom:0 !important;
    }

    .awssld__bullets--active{
        background-color:var(--color-primary) !important;
        width: 30px;
        height: 5px;
        -webkit-transform: scale(1.1) !important;
        transform: scale(1.1) !important;
    }

    .slider{
        display: flex;
        justify-content: center;
    }

    .awssld__content{
        background-color: white;
        height: 300px;
    }

    .awssld__content img{
        // background-color:white;
        max-width: 200px;
        width:auto;
        height: 200px;
        max-height: 200px;
        cursor: pointer;

        left: auto;
    }

    .awssld__bullets{
        bottom: -15px !important;
    }

    .slider-title{
        font-size: 16px;
        font-weight: 700;
        color: #1a1a1a;
        margin: 5px 0;
        text-align: center;
    }

    .special-info{
        // background-color: var(--color-dark-gray);
        position: relative;
        left: 0;
        bottom: -100px;
        width: 200px;
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* 100%; */
    }

    .nav-slider-col{
        border-right: 1px solid var(--color-primary);
    }
}