@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700|Roboto:500&display=swap);
.google .icon {
  width: 25px; }

.facebook .icon {
  width: 30px;
  height: 30px; }

.social-btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px; }

.social-btn-wrapper-mobile {
  max-height: 110px;
  flex-wrap: wrap;
  justify-content: center; }
  .social-btn-wrapper-mobile .social-btn {
    width: 140px;
    height: 43px; }
    .social-btn-wrapper-mobile .social-btn:not(:last-child) {
      margin-bottom: 15px; }

.social-btn {
  background-color: transparent !important;
  color: #333 !important;
  border: 1px solid #d5d8db !important;
  line-height: 30px;
  min-width: 167px;
  box-shadow: none !important;
  border-radius: 6px;
  padding-left: 15px;
  padding-right: 15px; }
  .social-btn:hover, .social-btn:focus, .social-btn:active {
    border: 1px solid #000 !important;
    background-color: transparent !important;
    color: #333 !important; }
  .social-btn .btn-inner {
    display: flex;
    justify-content: space-around; }
  .social-btn .fa {
    font-size: 15px;
    color: black; }

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  user-select: none;
  /* Hide the browser's default checkbox */
  /* Create a custom checkbox */
  /* On mouse-over, add a grey background color */
  /* When the checkbox is checked, add a blue background */
  /* Create the checkmark/indicator (hidden when not checked) */
  /* Show the checkmark when checked */
  /* Style the checkmark/indicator */ }
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
  .checkbox-container .checkmark {
    position: absolute;
    top: calc(50% - 1.125rem/2);
    left: 0;
    height: 1.125rem;
    width: 1.125rem;
    border-radius: 2px;
    border: 1px solid #555555; }
  .checkbox-container input:checked ~ .checkmark {
    background-color: var(--color-primary);
    border: none; }
  .checkbox-container .checkmark:after {
    content: "";
    position: absolute;
    display: none; }
  .checkbox-container input:checked ~ .checkmark:after {
    display: block; }
  .checkbox-container .checkmark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg); }

.checkbox-container.is-invalid {
  color: var(--color-error); }
  .checkbox-container.is-invalid .checkmark {
    border: 1px solid var(--color-error); }
  .checkbox-container.is-invalid a {
    font-weight: bold;
    color: var(--color-error); }

.boxes-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap; }

.box-wrapper {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16), 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  background-clip: border-box;
  padding: 0 30px 10px 30px;
  max-height: 127px; }

.image-text {
  text-align: center;
  color: var(--color-primary);
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  border-width: 0 !important; }

.nav .awssld {
  --slider-height-percentage: 60%;
  --slider-transition-duration: 525ms;
  --organic-arrow-thickness: 4px;
  --organic-arrow-border-radius: 0px;
  --organic-arrow-height: 15px;
  --organic-arrow-color: #26456f;
  --control-button-width: 20%;
  --control-button-height: 25%;
  --control-button-background: transparent;
  --control-bullet-active-color: black;
  --loader-bar-color: #f2be44;
  --loader-bar-height: 6px;
  width: 200px;
  height: 300px; }

.nav .awssld__bullets button {
  background-color: black;
  width: 30px;
  height: 5px;
  border-radius: 2px;
  margin-bottom: 0 !important; }

.nav .awssld__bullets--active {
  background-color: var(--color-primary) !important;
  width: 30px;
  height: 5px;
  -webkit-transform: scale(1.1) !important;
  transform: scale(1.1) !important; }

.nav .slider {
  display: flex;
  justify-content: center; }

.nav .awssld__content {
  background-color: white;
  height: 300px; }

.nav .awssld__content img {
  max-width: 200px;
  width: auto;
  height: 200px;
  max-height: 200px;
  cursor: pointer;
  left: auto; }

.nav .awssld__bullets {
  bottom: -15px !important; }

.nav .slider-title {
  font-size: 16px;
  font-weight: 700;
  color: #1a1a1a;
  margin: 5px 0;
  text-align: center; }

.nav .special-info {
  position: relative;
  left: 0;
  bottom: -100px;
  width: 200px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* 100%; */ }

.nav .nav-slider-col {
  border-right: 1px solid var(--color-primary); }

.dropdown {
  display: inline-block; }

.dropdown__content {
  display: none;
  position: absolute; }

.dropdown--active .dropdown__content {
  display: block; }

.dropdown--active .dropdown__content {
  position: inherit !important; }

.dropdown__content {
  display: none;
  position: inherit !important; }

.dropup, .dropright, .dropdown, .dropleft {
  position: inherit; }

.section-sub-menu {
  display: flex;
  justify-content: space-around;
  height: 310px;
  overflow-y: auto; }

#cart-dropdown-wrapper .cart-dropdown-img img {
  max-height: 80px; }

#cart-dropdown-wrapper .row-line-items-image {
  display: flex;
  justify-content: center;
  align-items: center; }

#cart-dropdown-wrapper .cart-dropdown-ptitle {
  font-size: 14px; }

.top-info {
  height: 35px;
  background-color: var(--color-third);
  color: var(--white);
  display: flex;
  align-items: center; }
  .top-info .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    letter-spacing: 0 !important;
    font-size: 14px; }
    .top-info .container a {
      color: var(--white); }

.link-block {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #e6e6e6;
  border-radius: 4px;
  padding: 5px 15px;
  margin-left: 15px;
  font-weight: bold; }
  .link-block:hover {
    color: var(--color-third); }

.main-menu {
  min-height: 56px;
  position: relative;
  border-top: 1px black solid;
  border-bottom: 1px black solid; }
  .main-menu .nav-link {
    font-size: 16px;
    padding-left: 1rem;
    color: black;
    padding-right: 1rem; }
    @media (max-width: 1250px) {
      .main-menu .nav-link {
        font-size: 14px;
        letter-spacing: 0; } }
  .main-menu .fa-caret-down {
    font-size: 12px; }
  .main-menu .nav-navbar {
    width: 65%; }
    @media (max-width: 1250px) and (min-width: 992px) {
      .main-menu .nav-navbar {
        width: 70%; } }
  .main-menu .search {
    width: 35%;
    background: #e6e6e6;
    border-radius: 5px; }
    @media (max-width: 1250px) and (min-width: 992px) {
      .main-menu .search {
        width: 30%; } }
    .main-menu .search .search-text {
      background: none !important;
      border: none !important;
      color: var(--color-gray);
      padding-right: 0; }
      .main-menu .search .search-text:focus {
        color: #263b46; }
      @media (max-width: 1100px) {
        .main-menu .search .search-text {
          font-size: 14px;
          letter-spacing: 0; } }
      @media (max-width: 991px) {
        .main-menu .search .search-text {
          font-size: 12px;
          letter-spacing: 0; } }
      .main-menu .search .search-text::-webkit-input-placeholder {
        color: var(--color-dark); }
      .main-menu .search .search-text::placeholder {
        color: var(--color-dark); }
    .main-menu .search .input-group-text {
      background: none !important; }
  .main-menu .nav-mega .dropdown-menu {
    background: #e6e6e6 !important;
    padding: 0;
    min-width: 900px;
    width: calc(1280px / 12 * 10 - 30px);
    border-radius: 30px;
    overflow: hidden;
    height: 408px; }
    .main-menu .nav-mega .dropdown-menu .title {
      font-family: var(--font-family-title);
      font-weight: 700;
      font-size: 30px; }
    .main-menu .nav-mega .dropdown-menu .nav-mega-link-row {
      height: 274px; }
    .main-menu .nav-mega .dropdown-menu .nav-mega-footer-row {
      margin-bottom: -50px !important; }
      .main-menu .nav-mega .dropdown-menu .nav-mega-footer-row a {
        font-size: 16px; }
    .main-menu .nav-mega .dropdown-menu .nav-link {
      font-size: 14px;
      font-weight: 400;
      color: black;
      text-transform: none;
      padding: 0; }
      .main-menu .nav-mega .dropdown-menu .nav-link:hover {
        color: var(--color-primary); }
    .main-menu .nav-mega .dropdown-menu .col-md-4:after {
      padding: 0 40px 0 0;
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 1px;
      height: 100%;
      opacity: 0.2; }
    .main-menu .nav-mega .dropdown-menu .col-md-4:last-child:after {
      content: none; }
    .main-menu .nav-mega .dropdown-menu .product-item {
      text-align: center; }
      .main-menu .nav-mega .dropdown-menu .product-item .title {
        font-size: 16px;
        font-weight: 700;
        color: var(--color-dark);
        margin: 0; }
      .main-menu .nav-mega .dropdown-menu .product-item .subtitle {
        font-weight: 400;
        color: var(--color-dark);
        margin: 0; }
      .main-menu .nav-mega .dropdown-menu .product-item .price {
        color: var(--color-dark);
        margin: 0; }
      .main-menu .nav-mega .dropdown-menu .product-item p {
        margin-bottom: 0; }
    .main-menu .nav-mega .dropdown-menu .slick-slider img {
      height: 200px; }
    .main-menu .nav-mega .dropdown-menu .slider-dots-line .slick-dots .slick-active button {
      width: 30px;
      background-color: var(--color-info); }
    .main-menu .nav-mega .dropdown-menu .slider-dots-line .slick-dots li button {
      background-color: var(--white);
      width: 30px;
      height: 5px; }
    .main-menu .nav-mega .dropdown-menu .nav-mega-list {
      height: 200px; }
      .main-menu .nav-mega .dropdown-menu .nav-mega-list li {
        height: 20px; }
        .main-menu .nav-mega .dropdown-menu .nav-mega-list li a:hover {
          color: var(--color-primary);
          font-weight: 600; }
  .main-menu .nav-mega .dropdown-toggle[aria-hidden="false"] {
    display: block !important;
    height: auto; }
  .main-menu .nav-mega .dropdown-toggle[aria-hidden="true"] {
    display: block !important;
    height: 0;
    overflow: hidden; }

.main-menu-top {
  position: relative; }
  .main-menu-top .item {
    padding-left: 10px;
    position: relative;
    width: 250px; }
    .main-menu-top .item:first-child {
      width: 300px;
      margin-left: auto; }
    .main-menu-top .item:last-child {
      width: 150px; }
    .main-menu-top .item:before {
      content: "";
      position: absolute;
      top: 0;
      left: -3px;
      background: var(--color-primary);
      width: 1px;
      height: 100%; }
    .main-menu-top .item .icon {
      height: 100%; }
      .main-menu-top .item .icon img {
        vertical-align: middle; }
    .main-menu-top .item .details {
      font-size: 1rem; }
      .main-menu-top .item .details .title {
        margin-bottom: 0;
        line-height: 1;
        color: var(--color-dark);
        font-family: var(--font-family-number); }
      .main-menu-top .item .details small {
        display: block;
        margin-top: 1px; }
  .main-menu-top .cart-icon {
    min-width: 25px; }
    .main-menu-top .cart-icon span {
      color: #ffffff;
      font-family: var(--font-family-title);
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      display: block;
      position: absolute;
      top: -8px;
      right: 85px;
      border-radius: 50%;
      height: 18px;
      width: 18px;
      background-color: #fa6e6e; }

.ilg-logo {
  margin-left: auto;
  margin-right: auto; }
  .ilg-logo:before {
    content: none !important; }

.nav-link-block {
  color: var(--color-third); }
  .nav-link-block:hover {
    color: var(--color-primary); }

@media (max-width: 1250px) {
  .main-menu .nav-link {
    font-size: 14px;
    letter-spacing: 0; } }

.scrollTop {
  position: fixed;
  height: 50px;
  right: 20px;
  bottom: 20px;
  z-index: 1000;
  cursor: pointer;
  -webkit-animation: fadeIn 0.3s;
          animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.5;
  border-radius: 50%;
  box-shadow: 0 0 15px 0 var(--color-primary); }
  .scrollTop:hover {
    opacity: 1; }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 0.5; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 0.5; } }

.footer {
  padding: 50px 0 20px;
  color: var(--white); }
  .footer a {
    color: inherit; }
    .footer a:hover {
      color: var(--color-primary); }
  .footer .title {
    color: var(--color-light); }
  .footer .nav-link {
    color: var(--color-light); }
  .footer .fa {
    color: var(--color-light); }
  .footer small {
    color: var(--color-light);
    display: inline-block;
    line-height: 1.5; }
  .footer .newsletter .input-group {
    border: none; }
  .footer .footer-bottom {
    color: var(--white); }
    .footer .footer-bottom p {
      color: #d8d8d8;
      font-size: 12px; }
    .footer .footer-bottom .nav-link {
      color: #d8d8d8;
      font-size: 12px;
      font-weight: 700; }
    .footer .footer-bottom a {
      font-size: 14px;
      color: var(--grey); }
      .footer .footer-bottom a:hover {
        color: var(--color-primary) !important; }
  .footer .footer-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%; }
    .footer .footer-wrapper .col-wrapper {
      padding: 0 30px 0 0; }
    .footer .footer-wrapper .title {
      font-weight: bold;
      padding: 10px 0;
      text-transform: uppercase; }
    .footer .footer-wrapper .body {
      font-size: 14px;
      padding-bottom: 10px; }
    .footer .footer-wrapper .about {
      width: 40%;
      padding-right: 20px; }
    .footer .footer-wrapper .links {
      width: 15%; }
    .footer .footer-wrapper .hours {
      width: 25%; }
    .footer .footer-wrapper .contact {
      width: 20%; }

@charset "UTF-8";
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.9;
  color: #757575;
  text-align: left;
  background-color: #ffffff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 600; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #50a1ff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0478ff;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #868e96;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.5;
  color: #323d47; }

h1, .h1 {
  font-size: 1.75rem; }

h2, .h2 {
  font-size: 1.6rem; }

h3, .h3 {
  font-size: 1.45rem; }

h4, .h4 {
  font-size: 1.3rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 5rem;
  font-weight: 300;
  line-height: 1.5; }

.display-2 {
  font-size: 4rem;
  font-weight: 300;
  line-height: 1.5; }

.display-3 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.5; }

.display-4 {
  font-size: 3rem;
  font-weight: 300;
  line-height: 1.5; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 85%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 85%;
  color: #868e96; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #868e96; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.125rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 600; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #757575; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #f1f2f3; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #f1f2f3; }
  .table tbody + tbody {
    border-top: 2px solid #f1f2f3; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #f1f2f3; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #f1f2f3; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fcfdfe; }

.table-hover tbody tr:hover {
  color: #757575;
  background-color: #f9fafb; }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #cee5ff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #a4ceff; }

.table-hover .table-primary:hover {
  background-color: #b5d7ff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #b5d7ff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #f9fafb; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #f4f5f7; }

.table-hover .table-secondary:hover {
  background-color: #eaedf1; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #eaedf1; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c8f3d0; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #9ae9a8; }

.table-hover .table-success:hover {
  background-color: #b3eebe; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b3eebe; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #e0d6f6; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #c6b3ee; }

.table-hover .table-info:hover {
  background-color: #d0c1f1; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #d0c1f1; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffecb8; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdb7a; }

.table-hover .table-warning:hover {
  background-color: #ffe59f; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe59f; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #ffcccf; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ffa0a6; }

.table-hover .table-danger:hover {
  background-color: #ffb3b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #ffb3b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #bfbfbf; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #878787; }

.table-hover .table-dark:hover {
  background-color: #b2b2b2; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b2b2b2; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: #f5f6f7; }

.table-hover .table-active:hover {
  background-color: #e7e9ec; }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: #e7e9ec; }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #323d47;
  background-color: #fafbfb;
  border-color: #f1f2f3; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.2em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2;
  color: #999999;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #eaeff4;
  border-radius: 2px;
  transition: all 0.3s ease-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #999999; }
  .form-control:focus {
    color: #323d47;
    background-color: #fff;
    border-color: #f1f2f3;
    outline: 0;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.045); }
  .form-control::-webkit-input-placeholder {
    color: #c9ccce;
    opacity: 1; }
  .form-control::placeholder {
    color: #c9ccce;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  -webkit-appearance: none;
          appearance: none; }

select.form-control:focus::-ms-value {
  color: #999999;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.2; }

.col-form-label-lg {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  font-size: 1.0625rem;
  line-height: 2.2; }

.col-form-label-sm {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  font-size: 0.9375rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.2;
  color: #757575;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.5rem;
  font-size: 0.9375rem;
  line-height: 1.5;
  border-radius: 2px; }

.form-control-lg {
  height: calc(2.2em + 0.75rem + 2px);
  padding: 0.375rem 1rem;
  font-size: 1.0625rem;
  line-height: 2.2;
  border-radius: 2px; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #868e96; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 85%;
  color: #3cd458; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.9;
  color: #fff;
  background-color: rgba(60, 212, 88, 0.9);
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #3cd458;
  padding-right: calc(1.2em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%233cd458' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.3em + 0.1875rem) center;
  background-size: calc(0.6em + 0.375rem) calc(0.6em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #3cd458;
    box-shadow: 0 0 0 0rem rgba(60, 212, 88, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.2em + 0.75rem);
  background-position: top calc(0.3em + 0.1875rem) right calc(0.3em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #3cd458;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%233cd458' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.6em + 0.375rem) calc(0.6em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #3cd458;
    box-shadow: 0 0 0 0rem rgba(60, 212, 88, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #3cd458; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #3cd458; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #3cd458; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #66dd7c;
  background-color: #66dd7c; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0rem rgba(60, 212, 88, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #3cd458; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #3cd458; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #3cd458;
  box-shadow: 0 0 0 0rem rgba(60, 212, 88, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 85%;
  color: #ff4954; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.9;
  color: #fff;
  background-color: rgba(255, 73, 84, 0.9);
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #ff4954;
  padding-right: calc(1.2em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ff4954' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff4954' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.3em + 0.1875rem) center;
  background-size: calc(0.6em + 0.375rem) calc(0.6em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #ff4954;
    box-shadow: 0 0 0 0rem rgba(255, 73, 84, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.2em + 0.75rem);
  background-position: top calc(0.3em + 0.1875rem) right calc(0.3em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #ff4954;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ff4954' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff4954' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.6em + 0.375rem) calc(0.6em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #ff4954;
    box-shadow: 0 0 0 0rem rgba(255, 73, 84, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #ff4954; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #ff4954; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #ff4954; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #ff7c84;
  background-color: #ff7c84; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0rem rgba(255, 73, 84, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #ff4954; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #ff4954; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #ff4954;
  box-shadow: 0 0 0 0rem rgba(255, 73, 84, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 600;
  color: #757575;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.9;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #757575;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: none; }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #50a1ff;
  border-color: #50a1ff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #3c96ff;
    border-color: #3c96ff;
    box-shadow: 0 1px 10px rgba(80, 161, 255, 0.4); }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    box-shadow: 0 0 0 0rem rgba(80, 161, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    background-color: #50a1ff;
    border-color: #50a1ff; }

.btn-secondary {
  color: #757575;
  background-color: #e9ecf0;
  border-color: #e9ecf0; }
  .btn-secondary:hover {
    color: #757575;
    background-color: #dde2e8;
    border-color: #dde2e8;
    box-shadow: 0 1px 10px rgba(233, 236, 240, 0.4); }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #757575;
    box-shadow: 0 0 0 0rem rgba(233, 236, 240, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    background-color: #e9ecf0;
    border-color: #e9ecf0; }

.btn-success {
  color: #fff;
  background-color: #3cd458;
  border-color: #3cd458; }
  .btn-success:hover {
    color: #fff;
    background-color: #2dce4b;
    border-color: #2dce4b;
    box-shadow: 0 1px 10px rgba(60, 212, 88, 0.4); }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    box-shadow: 0 0 0 0rem rgba(60, 212, 88, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    background-color: #3cd458;
    border-color: #3cd458; }

.btn-info {
  color: #fff;
  background-color: #926dde;
  border-color: #926dde; }
  .btn-info:hover {
    color: #fff;
    background-color: #865cda;
    border-color: #865cda;
    box-shadow: 0 1px 10px rgba(146, 109, 222, 0.4); }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    box-shadow: 0 0 0 0rem rgba(146, 109, 222, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    background-color: #926dde;
    border-color: #926dde; }

.btn-warning {
  color: #fff;
  background-color: #ffba00;
  border-color: #ffba00; }
  .btn-warning:hover {
    color: #fff;
    background-color: #ebab00;
    border-color: #ebab00;
    box-shadow: 0 1px 10px rgba(255, 186, 0, 0.4); }
  .btn-warning:focus, .btn-warning.focus {
    color: #fff;
    box-shadow: 0 0 0 0rem rgba(255, 186, 0, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    background-color: #ffba00;
    border-color: #ffba00; }

.btn-danger {
  color: #fff;
  background-color: #ff4954;
  border-color: #ff4954; }
  .btn-danger:hover {
    color: #fff;
    background-color: #ff3541;
    border-color: #ff3541;
    box-shadow: 0 1px 10px rgba(255, 73, 84, 0.4); }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    box-shadow: 0 0 0 0rem rgba(255, 73, 84, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    background-color: #ff4954;
    border-color: #ff4954; }

.btn-light {
  color: #757575;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #757575;
    background-color: #eceff2;
    border-color: #eceff2;
    box-shadow: 0 1px 10px rgba(248, 249, 250, 0.4); }
  .btn-light:focus, .btn-light.focus {
    color: #757575;
    box-shadow: 0 0 0 0rem rgba(248, 249, 250, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    background-color: #f8f9fa;
    border-color: #f8f9fa; }

.btn-dark {
  color: #fff;
  background-color: #191919;
  border-color: #191919; }
  .btn-dark:hover {
    color: #fff;
    background-color: #0f0f0f;
    border-color: #0f0f0f;
    box-shadow: 0 1px 10px rgba(25, 25, 25, 0.4); }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    box-shadow: 0 0 0 0rem rgba(25, 25, 25, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    background-color: #191919;
    border-color: #191919; }

.btn-outline-primary {
  color: #50a1ff;
  background-color: transparent;
  background-image: none;
  border-color: #50a1ff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    color: #50a1ff;
    box-shadow: 0 0 0 0rem rgba(80, 161, 255, 0.5); }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #50a1ff;
    border-color: #50a1ff;
    box-shadow: 0 1px 10px rgba(80, 161, 255, 0.4); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #50a1ff;
    background-color: transparent; }
  .btn-outline-primary:not([disabled]):not(.disabled):active, .btn-outline-primary:not([disabled]):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #50a1ff;
    border-color: #50a1ff;
    box-shadow: 0 0 0 0rem rgba(80, 161, 255, 0.5); }

.btn-outline-secondary {
  color: #e9ecf0;
  background-color: transparent;
  background-image: none;
  border-color: #e9ecf0; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    color: #e9ecf0;
    box-shadow: 0 0 0 0rem rgba(233, 236, 240, 0.5); }
  .btn-outline-secondary:hover {
    color: #757575;
    background-color: #e9ecf0;
    border-color: #e9ecf0;
    box-shadow: 0 1px 10px rgba(233, 236, 240, 0.4); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #e9ecf0;
    background-color: transparent; }
  .btn-outline-secondary:not([disabled]):not(.disabled):active, .btn-outline-secondary:not([disabled]):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #757575;
    background-color: #e9ecf0;
    border-color: #e9ecf0;
    box-shadow: 0 0 0 0rem rgba(233, 236, 240, 0.5); }

.btn-outline-success {
  color: #3cd458;
  background-color: transparent;
  background-image: none;
  border-color: #3cd458; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    color: #3cd458;
    box-shadow: 0 0 0 0rem rgba(60, 212, 88, 0.5); }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #3cd458;
    border-color: #3cd458;
    box-shadow: 0 1px 10px rgba(60, 212, 88, 0.4); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #3cd458;
    background-color: transparent; }
  .btn-outline-success:not([disabled]):not(.disabled):active, .btn-outline-success:not([disabled]):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #3cd458;
    border-color: #3cd458;
    box-shadow: 0 0 0 0rem rgba(60, 212, 88, 0.5); }

.btn-outline-info {
  color: #926dde;
  background-color: transparent;
  background-image: none;
  border-color: #926dde; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    color: #926dde;
    box-shadow: 0 0 0 0rem rgba(146, 109, 222, 0.5); }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #926dde;
    border-color: #926dde;
    box-shadow: 0 1px 10px rgba(146, 109, 222, 0.4); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #926dde;
    background-color: transparent; }
  .btn-outline-info:not([disabled]):not(.disabled):active, .btn-outline-info:not([disabled]):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #926dde;
    border-color: #926dde;
    box-shadow: 0 0 0 0rem rgba(146, 109, 222, 0.5); }

.btn-outline-warning {
  color: #ffba00;
  background-color: transparent;
  background-image: none;
  border-color: #ffba00; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    color: #ffba00;
    box-shadow: 0 0 0 0rem rgba(255, 186, 0, 0.5); }
  .btn-outline-warning:hover {
    color: #fff;
    background-color: #ffba00;
    border-color: #ffba00;
    box-shadow: 0 1px 10px rgba(255, 186, 0, 0.4); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffba00;
    background-color: transparent; }
  .btn-outline-warning:not([disabled]):not(.disabled):active, .btn-outline-warning:not([disabled]):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #fff;
    background-color: #ffba00;
    border-color: #ffba00;
    box-shadow: 0 0 0 0rem rgba(255, 186, 0, 0.5); }

.btn-outline-danger {
  color: #ff4954;
  background-color: transparent;
  background-image: none;
  border-color: #ff4954; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    color: #ff4954;
    box-shadow: 0 0 0 0rem rgba(255, 73, 84, 0.5); }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #ff4954;
    border-color: #ff4954;
    box-shadow: 0 1px 10px rgba(255, 73, 84, 0.4); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #ff4954;
    background-color: transparent; }
  .btn-outline-danger:not([disabled]):not(.disabled):active, .btn-outline-danger:not([disabled]):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #ff4954;
    border-color: #ff4954;
    box-shadow: 0 0 0 0rem rgba(255, 73, 84, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    color: #f8f9fa;
    box-shadow: 0 0 0 0rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light:hover {
    color: #757575;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
    box-shadow: 0 1px 10px rgba(248, 249, 250, 0.4); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not([disabled]):not(.disabled):active, .btn-outline-light:not([disabled]):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #757575;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
    box-shadow: 0 0 0 0rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #191919;
  background-color: transparent;
  background-image: none;
  border-color: #191919; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    color: #191919;
    box-shadow: 0 0 0 0rem rgba(25, 25, 25, 0.5); }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #191919;
    border-color: #191919;
    box-shadow: 0 1px 10px rgba(25, 25, 25, 0.4); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #191919;
    background-color: transparent; }
  .btn-outline-dark:not([disabled]):not(.disabled):active, .btn-outline-dark:not([disabled]):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #191919;
    border-color: #191919;
    box-shadow: 0 0 0 0rem rgba(25, 25, 25, 0.5); }

.btn-link {
  font-weight: 400;
  color: #50a1ff;
  text-decoration: none; }
  .btn-link:hover {
    color: #0478ff;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #868e96;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn, .input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.375rem 1rem;
  font-size: 1.0625rem;
  line-height: 2.2;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn, .input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.375rem 0.5rem;
  font-size: 0.9375rem;
  line-height: 1.5;
  border-radius: 0.125rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #757575;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #50a1ff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #868e96;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.9375rem;
  color: #868e96;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split, .input-group-sm > .input-group-prepend > .btn + .dropdown-toggle-split, .input-group-sm > .input-group-append > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split, .input-group-lg > .input-group-prepend > .btn + .dropdown-toggle-split, .input-group-lg > .input-group-append > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2;
  color: #999999;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #eaeff4;
  border-radius: 2px; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(2.2em + 0.75rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.375rem 1rem;
  font-size: 1.0625rem;
  line-height: 2.2;
  border-radius: 2px; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.75rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.375rem 0.5rem;
  font-size: 0.9375rem;
  line-height: 1.5;
  border-radius: 2px; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.9rem;
  padding-left: 1.625rem;
  -webkit-print-color-adjust: exact;
          color-adjust: exact; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1.125rem;
  height: 1.5125rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #fafbfb;
    background-color: #fafbfb; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.045); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #f1f2f3; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #fafbfb;
    border-color: #fafbfb; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #868e96; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #fafbfb; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.3875rem;
    left: -1.625rem;
    display: block;
    width: 1.125rem;
    height: 1.125rem;
    pointer-events: none;
    content: "";
    background-color: #fafbfb;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.3875rem;
    left: -1.625rem;
    display: block;
    width: 1.125rem;
    height: 1.125rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #fafbfb;
  background-color: #fafbfb; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: none; }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #fafbfb; }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: #fafbfb; }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none; }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #fafbfb; }

.custom-switch {
  padding-left: 2.46875rem; }
  .custom-switch .custom-control-label::before {
    left: -2.46875rem;
    width: 1.96875rem;
    pointer-events: all;
    border-radius: 0.5625rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.3875rem + 2px);
    left: calc(-2.46875rem + 2px);
    width: calc(1.125rem - 4px);
    height: calc(1.125rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5625rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fafbfb;
    -webkit-transform: translateX(0.84375rem);
            transform: translateX(0.84375rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: #fafbfb; }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.2em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2;
  color: #999999;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #eaeff4;
  border-radius: 0.25rem;
  -webkit-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: #f1f2f3;
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(80, 161, 255, 0.25); }
    .custom-select:focus::-ms-value {
      color: #999999;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #868e96;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #999999; }

.custom-select-sm {
  height: calc(1.5em + 0.75rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.5rem;
  font-size: 0.9375rem; }

.custom-select-lg {
  height: calc(2.2em + 0.75rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 1rem;
  font-size: 1.0625rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.2em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.2em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #f1f2f3;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.045); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.2em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.2;
  color: #999999;
  background-color: #fff;
  border: 1px solid #eaeff4;
  border-radius: 2px; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.2em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.2;
    color: #999999;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 2px 2px 0; }

.custom-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
          appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #ffffff, 0 0 40px rgba(0, 0, 0, 0.045); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #ffffff, 0 0 40px rgba(0, 0, 0, 0.045); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #ffffff, 0 0 40px rgba(0, 0, 0, 0.045); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #50a1ff;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
            appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: white; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #50a1ff;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: white; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0rem;
    margin-left: 0rem;
    background-color: #50a1ff;
    border: 0;
    border-radius: 1rem;
    -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        -ms-transition: none;
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: white; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #a5b3c7;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #f1f2f3; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: transparent transparent #f1f2f3; }
    .nav-tabs .nav-link.disabled {
      color: #a5b3c7;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #000;
    background-color: #ffffff;
    border-color: #50a1ff #f1f2f3 #ffffff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.125rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #757575;
  background-color: #e9ecf0; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 1rem;
  font-size: 1.0625rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.0625rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.65); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(255, 255, 255, 0.65);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.65%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(255, 255, 255, 0.65); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(117, 117, 117, 0.8); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(117, 117, 117, 0.8);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28117, 117, 117, 0.8%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(117, 117, 117, 0.8); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem; }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem; }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 0 solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: 0.25rem 0.25rem 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: transparent;
  border-top: 0 solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 0.25rem 0.25rem; }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: 0.25rem; }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem; }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
            column-count: 3;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: 0; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0.25rem; }

.breadcrumb-item {
  display: flex; }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem; }
    .breadcrumb-item + .breadcrumb-item::before {
      display: inline-block;
      padding-right: 0.5rem;
      color: #bfc5ca;
      content: ""; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none; }
  .breadcrumb-item.active {
    color: #757575; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 32px;
  color: #757575;
  background-color: #fff;
  border: 1px solid #eaeff4; }
  .page-link:hover {
    z-index: 2;
    color: #757575;
    text-decoration: none;
    background-color: #fafbfb;
    border-color: #eaeff4; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: none; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #50a1ff;
  border-color: #50a1ff; }

.page-item.disabled .page-link {
  color: #868e96;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.0625rem;
  line-height: 2.2; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.9375rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.5em;
  font-size: 80%;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.125rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.75em;
  padding-left: 0.75em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #50a1ff; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #1d86ff; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(80, 161, 255, 0.5); }

.badge-secondary {
  color: #757575;
  background-color: #e9ecf0; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #757575;
    background-color: #cbd2db; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(233, 236, 240, 0.5); }

.badge-success {
  color: #fff;
  background-color: #3cd458; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #28b542; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(60, 212, 88, 0.5); }

.badge-info {
  color: #fff;
  background-color: #926dde; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #7343d5; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(146, 109, 222, 0.5); }

.badge-warning {
  color: #fff;
  background-color: #ffba00; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #fff;
    background-color: #cc9500; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(255, 186, 0, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #ff4954; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #ff1624; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(255, 73, 84, 0.5); }

.badge-light {
  color: #757575;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #757575;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #191919; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: black; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(25, 25, 25, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 600; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #2a5485;
  background-color: #dcecff;
  border-color: #cee5ff; }
  .alert-primary hr {
    border-top-color: #b5d7ff; }
  .alert-primary .alert-link {
    color: #1e3c5e; }

.alert-secondary {
  color: #797b7d;
  background-color: #fbfbfc;
  border-color: #f9fafb; }
  .alert-secondary hr {
    border-top-color: #eaedf1; }
  .alert-secondary .alert-link {
    color: #606263; }

.alert-success {
  color: #1f6e2e;
  background-color: #d8f6de;
  border-color: #c8f3d0; }
  .alert-success hr {
    border-top-color: #b3eebe; }
  .alert-success .alert-link {
    color: #14461d; }

.alert-info {
  color: #4c3973;
  background-color: #e9e2f8;
  border-color: #e0d6f6; }
  .alert-info hr {
    border-top-color: #d0c1f1; }
  .alert-info .alert-link {
    color: #352851; }

.alert-warning {
  color: #856100;
  background-color: #fff1cc;
  border-color: #ffecb8; }
  .alert-warning hr {
    border-top-color: #ffe59f; }
  .alert-warning .alert-link {
    color: #523c00; }

.alert-danger {
  color: #85262c;
  background-color: #ffdbdd;
  border-color: #ffcccf; }
  .alert-danger hr {
    border-top-color: #ffb3b7; }
  .alert-danger .alert-link {
    color: #5d1b1f; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #0d0d0d;
  background-color: #d1d1d1;
  border-color: #bfbfbf; }
  .alert-dark hr {
    border-top-color: #b2b2b2; }
  .alert-dark .alert-link {
    color: black; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #f5f6f7;
  border-radius: 0.125rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #50a1ff;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
          animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      -webkit-animation: none;
              animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #757575;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #868e96;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #50a1ff;
    border-color: #50a1ff; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #2a5485;
  background-color: #cee5ff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #2a5485;
    background-color: #b5d7ff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #2a5485;
    border-color: #2a5485; }

.list-group-item-secondary {
  color: #797b7d;
  background-color: #f9fafb; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #797b7d;
    background-color: #eaedf1; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #797b7d;
    border-color: #797b7d; }

.list-group-item-success {
  color: #1f6e2e;
  background-color: #c8f3d0; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #1f6e2e;
    background-color: #b3eebe; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #1f6e2e;
    border-color: #1f6e2e; }

.list-group-item-info {
  color: #4c3973;
  background-color: #e0d6f6; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #4c3973;
    background-color: #d0c1f1; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #4c3973;
    border-color: #4c3973; }

.list-group-item-warning {
  color: #856100;
  background-color: #ffecb8; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856100;
    background-color: #ffe59f; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856100;
    border-color: #856100; }

.list-group-item-danger {
  color: #85262c;
  background-color: #ffcccf; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #85262c;
    background-color: #ffb3b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #85262c;
    border-color: #85262c; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #0d0d0d;
  background-color: #bfbfbf; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #0d0d0d;
    background-color: #b2b2b2; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #0d0d0d;
    border-color: #0d0d0d; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1;
  color: #000;
  text-shadow: none;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
            transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    -webkit-transform: none;
            transform: none; }
  .modal.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
            transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: -webkit-min-content;
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1.75rem;
  border-bottom: 1px solid #f1f2f3;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1.75rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.9; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1.75rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 1.5rem;
  border-top: 1px solid #f1f2f3;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 540px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: -webkit-min-content;
      height: min-content; }
  .modal-sm {
    max-width: 360px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.9;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.9;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #f1f2f3;
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 0.6rem;
    height: 0.3rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.3rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.3rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.3rem 0.3rem 0;
      border-top-color: #f1f2f3; }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.3rem 0.3rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.3rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.3rem - 1px);
    width: 0.3rem;
    height: 0.6rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.3rem 0.3rem 0.3rem 0;
      border-right-color: #f1f2f3; }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.3rem 0.3rem 0.3rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.3rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.3rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.3rem 0.3rem 0.3rem;
      border-bottom-color: #f1f2f3; }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.3rem 0.3rem 0.3rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 0.6rem;
    margin-left: -0.3rem;
    content: "";
    border-bottom: 1px solid #f9fafb; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.3rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.3rem - 1px);
    width: 0.3rem;
    height: 0.6rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.3rem 0 0.3rem 0.3rem;
      border-left-color: #f1f2f3; }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.3rem 0 0.3rem 0.3rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #323d47;
  background-color: #f9fafb;
  border-bottom: 1px solid #eaedf1;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #757575; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #50a1ff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #1d86ff !important; }

.bg-secondary {
  background-color: #e9ecf0 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #cbd2db !important; }

.bg-success {
  background-color: #3cd458 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #28b542 !important; }

.bg-info {
  background-color: #926dde !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #7343d5 !important; }

.bg-warning {
  background-color: #ffba00 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #cc9500 !important; }

.bg-danger {
  background-color: #ff4954 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ff1624 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #191919 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: black !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #f1f2f3 !important; }

.border-top {
  border-top: 1px solid #f1f2f3 !important; }

.border-right {
  border-right: 1px solid #f1f2f3 !important; }

.border-bottom {
  border-bottom: 1px solid #f1f2f3 !important; }

.border-left {
  border-left: 1px solid #f1f2f3 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #50a1ff !important; }

.border-secondary {
  border-color: #e9ecf0 !important; }

.border-success {
  border-color: #3cd458 !important; }

.border-info {
  border-color: #926dde !important; }

.border-warning {
  border-color: #ffba00 !important; }

.border-danger {
  border-color: #ff4954 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #191919 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.125rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center, .nav.nav-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  -webkit-user-select: all !important;
          user-select: all !important; }

.user-select-auto {
  -webkit-user-select: auto !important;
          user-select: auto !important; }

.user-select-none {
  -webkit-user-select: none !important;
          user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.05) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.w-5 {
  width: 5% !important; }

.w-10 {
  width: 10% !important; }

.w-15 {
  width: 15% !important; }

.w-20 {
  width: 20% !important; }

.w-30 {
  width: 30% !important; }

.w-40 {
  width: 40% !important; }

.w-60 {
  width: 60% !important; }

.w-70 {
  width: 70% !important; }

.w-80 {
  width: 80% !important; }

.w-90 {
  width: 90% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.h-5 {
  height: 5% !important; }

.h-10 {
  height: 10% !important; }

.h-15 {
  height: 15% !important; }

.h-20 {
  height: 20% !important; }

.h-30 {
  height: 30% !important; }

.h-40 {
  height: 40% !important; }

.h-60 {
  height: 60% !important; }

.h-70 {
  height: 70% !important; }

.h-80 {
  height: 80% !important; }

.h-90 {
  height: 90% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 0.75rem !important; }

.mt-3,
.my-3 {
  margin-top: 0.75rem !important; }

.mr-3,
.mx-3 {
  margin-right: 0.75rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 0.75rem !important; }

.ml-3,
.mx-3 {
  margin-left: 0.75rem !important; }

.m-4 {
  margin: 1rem !important; }

.mt-4,
.my-4 {
  margin-top: 1rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1rem !important; }

.m-5 {
  margin: 1.5rem !important; }

.mt-5,
.my-5 {
  margin-top: 1.5rem !important; }

.mr-5,
.mx-5 {
  margin-right: 1.5rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 1.5rem !important; }

.ml-5,
.mx-5 {
  margin-left: 1.5rem !important; }

.m-6 {
  margin: 2rem !important; }

.mt-6,
.my-6 {
  margin-top: 2rem !important; }

.mr-6,
.mx-6 {
  margin-right: 2rem !important; }

.mb-6,
.my-6 {
  margin-bottom: 2rem !important; }

.ml-6,
.mx-6 {
  margin-left: 2rem !important; }

.m-7 {
  margin: 3rem !important; }

.mt-7,
.my-7 {
  margin-top: 3rem !important; }

.mr-7,
.mx-7 {
  margin-right: 3rem !important; }

.mb-7,
.my-7 {
  margin-bottom: 3rem !important; }

.ml-7,
.mx-7 {
  margin-left: 3rem !important; }

.m-8 {
  margin: 5rem !important; }

.mt-8,
.my-8 {
  margin-top: 5rem !important; }

.mr-8,
.mx-8 {
  margin-right: 5rem !important; }

.mb-8,
.my-8 {
  margin-bottom: 5rem !important; }

.ml-8,
.mx-8 {
  margin-left: 5rem !important; }

.m-9 {
  margin: 7rem !important; }

.mt-9,
.my-9 {
  margin-top: 7rem !important; }

.mr-9,
.mx-9 {
  margin-right: 7rem !important; }

.mb-9,
.my-9 {
  margin-bottom: 7rem !important; }

.ml-9,
.mx-9 {
  margin-left: 7rem !important; }

.m-10 {
  margin: 9rem !important; }

.mt-10,
.my-10 {
  margin-top: 9rem !important; }

.mr-10,
.mx-10 {
  margin-right: 9rem !important; }

.mb-10,
.my-10 {
  margin-bottom: 9rem !important; }

.ml-10,
.mx-10 {
  margin-left: 9rem !important; }

.m-11 {
  margin: 11rem !important; }

.mt-11,
.my-11 {
  margin-top: 11rem !important; }

.mr-11,
.mx-11 {
  margin-right: 11rem !important; }

.mb-11,
.my-11 {
  margin-bottom: 11rem !important; }

.ml-11,
.mx-11 {
  margin-left: 11rem !important; }

.m-12 {
  margin: 13rem !important; }

.mt-12,
.my-12 {
  margin-top: 13rem !important; }

.mr-12,
.mx-12 {
  margin-right: 13rem !important; }

.mb-12,
.my-12 {
  margin-bottom: 13rem !important; }

.ml-12,
.mx-12 {
  margin-left: 13rem !important; }

.m-13 {
  margin: 16rem !important; }

.mt-13,
.my-13 {
  margin-top: 16rem !important; }

.mr-13,
.mx-13 {
  margin-right: 16rem !important; }

.mb-13,
.my-13 {
  margin-bottom: 16rem !important; }

.ml-13,
.mx-13 {
  margin-left: 16rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 0.75rem !important; }

.pt-3,
.py-3 {
  padding-top: 0.75rem !important; }

.pr-3,
.px-3 {
  padding-right: 0.75rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 0.75rem !important; }

.pl-3,
.px-3 {
  padding-left: 0.75rem !important; }

.p-4 {
  padding: 1rem !important; }

.pt-4,
.py-4 {
  padding-top: 1rem !important; }

.pr-4,
.px-4 {
  padding-right: 1rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1rem !important; }

.pl-4,
.px-4 {
  padding-left: 1rem !important; }

.p-5 {
  padding: 1.5rem !important; }

.pt-5,
.py-5 {
  padding-top: 1.5rem !important; }

.pr-5,
.px-5 {
  padding-right: 1.5rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 1.5rem !important; }

.pl-5,
.px-5 {
  padding-left: 1.5rem !important; }

.p-6 {
  padding: 2rem !important; }

.pt-6,
.py-6 {
  padding-top: 2rem !important; }

.pr-6,
.px-6 {
  padding-right: 2rem !important; }

.pb-6,
.py-6 {
  padding-bottom: 2rem !important; }

.pl-6,
.px-6 {
  padding-left: 2rem !important; }

.p-7 {
  padding: 3rem !important; }

.pt-7,
.py-7 {
  padding-top: 3rem !important; }

.pr-7,
.px-7 {
  padding-right: 3rem !important; }

.pb-7,
.py-7 {
  padding-bottom: 3rem !important; }

.pl-7,
.px-7 {
  padding-left: 3rem !important; }

.p-8 {
  padding: 5rem !important; }

.pt-8,
.py-8 {
  padding-top: 5rem !important; }

.pr-8,
.px-8 {
  padding-right: 5rem !important; }

.pb-8,
.py-8 {
  padding-bottom: 5rem !important; }

.pl-8,
.px-8 {
  padding-left: 5rem !important; }

.p-9 {
  padding: 7rem !important; }

.pt-9,
.py-9 {
  padding-top: 7rem !important; }

.pr-9,
.px-9 {
  padding-right: 7rem !important; }

.pb-9,
.py-9 {
  padding-bottom: 7rem !important; }

.pl-9,
.px-9 {
  padding-left: 7rem !important; }

.p-10 {
  padding: 9rem !important; }

.pt-10,
.py-10 {
  padding-top: 9rem !important; }

.pr-10,
.px-10 {
  padding-right: 9rem !important; }

.pb-10,
.py-10 {
  padding-bottom: 9rem !important; }

.pl-10,
.px-10 {
  padding-left: 9rem !important; }

.p-11 {
  padding: 11rem !important; }

.pt-11,
.py-11 {
  padding-top: 11rem !important; }

.pr-11,
.px-11 {
  padding-right: 11rem !important; }

.pb-11,
.py-11 {
  padding-bottom: 11rem !important; }

.pl-11,
.px-11 {
  padding-left: 11rem !important; }

.p-12 {
  padding: 13rem !important; }

.pt-12,
.py-12 {
  padding-top: 13rem !important; }

.pr-12,
.px-12 {
  padding-right: 13rem !important; }

.pb-12,
.py-12 {
  padding-bottom: 13rem !important; }

.pl-12,
.px-12 {
  padding-left: 13rem !important; }

.p-13 {
  padding: 16rem !important; }

.pt-13,
.py-13 {
  padding-top: 16rem !important; }

.pr-13,
.px-13 {
  padding-right: 16rem !important; }

.pb-13,
.py-13 {
  padding-bottom: 16rem !important; }

.pl-13,
.px-13 {
  padding-left: 16rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -0.75rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -0.75rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -0.75rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -0.75rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -0.75rem !important; }

.m-n4 {
  margin: -1rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1rem !important; }

.m-n5 {
  margin: -1.5rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -1.5rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -1.5rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -1.5rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -1.5rem !important; }

.m-n6 {
  margin: -2rem !important; }

.mt-n6,
.my-n6 {
  margin-top: -2rem !important; }

.mr-n6,
.mx-n6 {
  margin-right: -2rem !important; }

.mb-n6,
.my-n6 {
  margin-bottom: -2rem !important; }

.ml-n6,
.mx-n6 {
  margin-left: -2rem !important; }

.m-n7 {
  margin: -3rem !important; }

.mt-n7,
.my-n7 {
  margin-top: -3rem !important; }

.mr-n7,
.mx-n7 {
  margin-right: -3rem !important; }

.mb-n7,
.my-n7 {
  margin-bottom: -3rem !important; }

.ml-n7,
.mx-n7 {
  margin-left: -3rem !important; }

.m-n8 {
  margin: -5rem !important; }

.mt-n8,
.my-n8 {
  margin-top: -5rem !important; }

.mr-n8,
.mx-n8 {
  margin-right: -5rem !important; }

.mb-n8,
.my-n8 {
  margin-bottom: -5rem !important; }

.ml-n8,
.mx-n8 {
  margin-left: -5rem !important; }

.m-n9 {
  margin: -7rem !important; }

.mt-n9,
.my-n9 {
  margin-top: -7rem !important; }

.mr-n9,
.mx-n9 {
  margin-right: -7rem !important; }

.mb-n9,
.my-n9 {
  margin-bottom: -7rem !important; }

.ml-n9,
.mx-n9 {
  margin-left: -7rem !important; }

.m-n10 {
  margin: -9rem !important; }

.mt-n10,
.my-n10 {
  margin-top: -9rem !important; }

.mr-n10,
.mx-n10 {
  margin-right: -9rem !important; }

.mb-n10,
.my-n10 {
  margin-bottom: -9rem !important; }

.ml-n10,
.mx-n10 {
  margin-left: -9rem !important; }

.m-n11 {
  margin: -11rem !important; }

.mt-n11,
.my-n11 {
  margin-top: -11rem !important; }

.mr-n11,
.mx-n11 {
  margin-right: -11rem !important; }

.mb-n11,
.my-n11 {
  margin-bottom: -11rem !important; }

.ml-n11,
.mx-n11 {
  margin-left: -11rem !important; }

.m-n12 {
  margin: -13rem !important; }

.mt-n12,
.my-n12 {
  margin-top: -13rem !important; }

.mr-n12,
.mx-n12 {
  margin-right: -13rem !important; }

.mb-n12,
.my-n12 {
  margin-bottom: -13rem !important; }

.ml-n12,
.mx-n12 {
  margin-left: -13rem !important; }

.m-n13 {
  margin: -16rem !important; }

.mt-n13,
.my-n13 {
  margin-top: -16rem !important; }

.mr-n13,
.mx-n13 {
  margin-right: -16rem !important; }

.mb-n13,
.my-n13 {
  margin-bottom: -16rem !important; }

.ml-n13,
.mx-n13 {
  margin-left: -16rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 0.75rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 0.75rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 0.75rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 0.75rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 0.75rem !important; }
  .m-sm-4 {
    margin: 1rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1rem !important; }
  .m-sm-5 {
    margin: 1.5rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 1.5rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 1.5rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 1.5rem !important; }
  .m-sm-6 {
    margin: 2rem !important; }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 2rem !important; }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 2rem !important; }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 2rem !important; }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 2rem !important; }
  .m-sm-7 {
    margin: 3rem !important; }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 3rem !important; }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 3rem !important; }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 3rem !important; }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 3rem !important; }
  .m-sm-8 {
    margin: 5rem !important; }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 5rem !important; }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 5rem !important; }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 5rem !important; }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 5rem !important; }
  .m-sm-9 {
    margin: 7rem !important; }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 7rem !important; }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 7rem !important; }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 7rem !important; }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 7rem !important; }
  .m-sm-10 {
    margin: 9rem !important; }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 9rem !important; }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 9rem !important; }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 9rem !important; }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 9rem !important; }
  .m-sm-11 {
    margin: 11rem !important; }
  .mt-sm-11,
  .my-sm-11 {
    margin-top: 11rem !important; }
  .mr-sm-11,
  .mx-sm-11 {
    margin-right: 11rem !important; }
  .mb-sm-11,
  .my-sm-11 {
    margin-bottom: 11rem !important; }
  .ml-sm-11,
  .mx-sm-11 {
    margin-left: 11rem !important; }
  .m-sm-12 {
    margin: 13rem !important; }
  .mt-sm-12,
  .my-sm-12 {
    margin-top: 13rem !important; }
  .mr-sm-12,
  .mx-sm-12 {
    margin-right: 13rem !important; }
  .mb-sm-12,
  .my-sm-12 {
    margin-bottom: 13rem !important; }
  .ml-sm-12,
  .mx-sm-12 {
    margin-left: 13rem !important; }
  .m-sm-13 {
    margin: 16rem !important; }
  .mt-sm-13,
  .my-sm-13 {
    margin-top: 16rem !important; }
  .mr-sm-13,
  .mx-sm-13 {
    margin-right: 16rem !important; }
  .mb-sm-13,
  .my-sm-13 {
    margin-bottom: 16rem !important; }
  .ml-sm-13,
  .mx-sm-13 {
    margin-left: 16rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 0.75rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 0.75rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 0.75rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 0.75rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 0.75rem !important; }
  .p-sm-4 {
    padding: 1rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1rem !important; }
  .p-sm-5 {
    padding: 1.5rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 1.5rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 1.5rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 1.5rem !important; }
  .p-sm-6 {
    padding: 2rem !important; }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 2rem !important; }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 2rem !important; }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 2rem !important; }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 2rem !important; }
  .p-sm-7 {
    padding: 3rem !important; }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 3rem !important; }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 3rem !important; }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 3rem !important; }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 3rem !important; }
  .p-sm-8 {
    padding: 5rem !important; }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 5rem !important; }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 5rem !important; }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 5rem !important; }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 5rem !important; }
  .p-sm-9 {
    padding: 7rem !important; }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 7rem !important; }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 7rem !important; }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 7rem !important; }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 7rem !important; }
  .p-sm-10 {
    padding: 9rem !important; }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 9rem !important; }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 9rem !important; }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 9rem !important; }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 9rem !important; }
  .p-sm-11 {
    padding: 11rem !important; }
  .pt-sm-11,
  .py-sm-11 {
    padding-top: 11rem !important; }
  .pr-sm-11,
  .px-sm-11 {
    padding-right: 11rem !important; }
  .pb-sm-11,
  .py-sm-11 {
    padding-bottom: 11rem !important; }
  .pl-sm-11,
  .px-sm-11 {
    padding-left: 11rem !important; }
  .p-sm-12 {
    padding: 13rem !important; }
  .pt-sm-12,
  .py-sm-12 {
    padding-top: 13rem !important; }
  .pr-sm-12,
  .px-sm-12 {
    padding-right: 13rem !important; }
  .pb-sm-12,
  .py-sm-12 {
    padding-bottom: 13rem !important; }
  .pl-sm-12,
  .px-sm-12 {
    padding-left: 13rem !important; }
  .p-sm-13 {
    padding: 16rem !important; }
  .pt-sm-13,
  .py-sm-13 {
    padding-top: 16rem !important; }
  .pr-sm-13,
  .px-sm-13 {
    padding-right: 16rem !important; }
  .pb-sm-13,
  .py-sm-13 {
    padding-bottom: 16rem !important; }
  .pl-sm-13,
  .px-sm-13 {
    padding-left: 16rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -0.75rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -0.75rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -0.75rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -0.75rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -0.75rem !important; }
  .m-sm-n4 {
    margin: -1rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1rem !important; }
  .m-sm-n5 {
    margin: -1.5rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -1.5rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -1.5rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -1.5rem !important; }
  .m-sm-n6 {
    margin: -2rem !important; }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -2rem !important; }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -2rem !important; }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -2rem !important; }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -2rem !important; }
  .m-sm-n7 {
    margin: -3rem !important; }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -3rem !important; }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -3rem !important; }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -3rem !important; }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -3rem !important; }
  .m-sm-n8 {
    margin: -5rem !important; }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -5rem !important; }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -5rem !important; }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -5rem !important; }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -5rem !important; }
  .m-sm-n9 {
    margin: -7rem !important; }
  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -7rem !important; }
  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -7rem !important; }
  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -7rem !important; }
  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -7rem !important; }
  .m-sm-n10 {
    margin: -9rem !important; }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -9rem !important; }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -9rem !important; }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -9rem !important; }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -9rem !important; }
  .m-sm-n11 {
    margin: -11rem !important; }
  .mt-sm-n11,
  .my-sm-n11 {
    margin-top: -11rem !important; }
  .mr-sm-n11,
  .mx-sm-n11 {
    margin-right: -11rem !important; }
  .mb-sm-n11,
  .my-sm-n11 {
    margin-bottom: -11rem !important; }
  .ml-sm-n11,
  .mx-sm-n11 {
    margin-left: -11rem !important; }
  .m-sm-n12 {
    margin: -13rem !important; }
  .mt-sm-n12,
  .my-sm-n12 {
    margin-top: -13rem !important; }
  .mr-sm-n12,
  .mx-sm-n12 {
    margin-right: -13rem !important; }
  .mb-sm-n12,
  .my-sm-n12 {
    margin-bottom: -13rem !important; }
  .ml-sm-n12,
  .mx-sm-n12 {
    margin-left: -13rem !important; }
  .m-sm-n13 {
    margin: -16rem !important; }
  .mt-sm-n13,
  .my-sm-n13 {
    margin-top: -16rem !important; }
  .mr-sm-n13,
  .mx-sm-n13 {
    margin-right: -16rem !important; }
  .mb-sm-n13,
  .my-sm-n13 {
    margin-bottom: -16rem !important; }
  .ml-sm-n13,
  .mx-sm-n13 {
    margin-left: -16rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 0.75rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 0.75rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 0.75rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 0.75rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 0.75rem !important; }
  .m-md-4 {
    margin: 1rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1rem !important; }
  .m-md-5 {
    margin: 1.5rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 1.5rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 1.5rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 1.5rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 1.5rem !important; }
  .m-md-6 {
    margin: 2rem !important; }
  .mt-md-6,
  .my-md-6 {
    margin-top: 2rem !important; }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 2rem !important; }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 2rem !important; }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 2rem !important; }
  .m-md-7 {
    margin: 3rem !important; }
  .mt-md-7,
  .my-md-7 {
    margin-top: 3rem !important; }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 3rem !important; }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 3rem !important; }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 3rem !important; }
  .m-md-8 {
    margin: 5rem !important; }
  .mt-md-8,
  .my-md-8 {
    margin-top: 5rem !important; }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 5rem !important; }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 5rem !important; }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 5rem !important; }
  .m-md-9 {
    margin: 7rem !important; }
  .mt-md-9,
  .my-md-9 {
    margin-top: 7rem !important; }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 7rem !important; }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 7rem !important; }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 7rem !important; }
  .m-md-10 {
    margin: 9rem !important; }
  .mt-md-10,
  .my-md-10 {
    margin-top: 9rem !important; }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 9rem !important; }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 9rem !important; }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 9rem !important; }
  .m-md-11 {
    margin: 11rem !important; }
  .mt-md-11,
  .my-md-11 {
    margin-top: 11rem !important; }
  .mr-md-11,
  .mx-md-11 {
    margin-right: 11rem !important; }
  .mb-md-11,
  .my-md-11 {
    margin-bottom: 11rem !important; }
  .ml-md-11,
  .mx-md-11 {
    margin-left: 11rem !important; }
  .m-md-12 {
    margin: 13rem !important; }
  .mt-md-12,
  .my-md-12 {
    margin-top: 13rem !important; }
  .mr-md-12,
  .mx-md-12 {
    margin-right: 13rem !important; }
  .mb-md-12,
  .my-md-12 {
    margin-bottom: 13rem !important; }
  .ml-md-12,
  .mx-md-12 {
    margin-left: 13rem !important; }
  .m-md-13 {
    margin: 16rem !important; }
  .mt-md-13,
  .my-md-13 {
    margin-top: 16rem !important; }
  .mr-md-13,
  .mx-md-13 {
    margin-right: 16rem !important; }
  .mb-md-13,
  .my-md-13 {
    margin-bottom: 16rem !important; }
  .ml-md-13,
  .mx-md-13 {
    margin-left: 16rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 0.75rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 0.75rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 0.75rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 0.75rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 0.75rem !important; }
  .p-md-4 {
    padding: 1rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1rem !important; }
  .p-md-5 {
    padding: 1.5rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 1.5rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 1.5rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 1.5rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 1.5rem !important; }
  .p-md-6 {
    padding: 2rem !important; }
  .pt-md-6,
  .py-md-6 {
    padding-top: 2rem !important; }
  .pr-md-6,
  .px-md-6 {
    padding-right: 2rem !important; }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 2rem !important; }
  .pl-md-6,
  .px-md-6 {
    padding-left: 2rem !important; }
  .p-md-7 {
    padding: 3rem !important; }
  .pt-md-7,
  .py-md-7 {
    padding-top: 3rem !important; }
  .pr-md-7,
  .px-md-7 {
    padding-right: 3rem !important; }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 3rem !important; }
  .pl-md-7,
  .px-md-7 {
    padding-left: 3rem !important; }
  .p-md-8 {
    padding: 5rem !important; }
  .pt-md-8,
  .py-md-8 {
    padding-top: 5rem !important; }
  .pr-md-8,
  .px-md-8 {
    padding-right: 5rem !important; }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 5rem !important; }
  .pl-md-8,
  .px-md-8 {
    padding-left: 5rem !important; }
  .p-md-9 {
    padding: 7rem !important; }
  .pt-md-9,
  .py-md-9 {
    padding-top: 7rem !important; }
  .pr-md-9,
  .px-md-9 {
    padding-right: 7rem !important; }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 7rem !important; }
  .pl-md-9,
  .px-md-9 {
    padding-left: 7rem !important; }
  .p-md-10 {
    padding: 9rem !important; }
  .pt-md-10,
  .py-md-10 {
    padding-top: 9rem !important; }
  .pr-md-10,
  .px-md-10 {
    padding-right: 9rem !important; }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 9rem !important; }
  .pl-md-10,
  .px-md-10 {
    padding-left: 9rem !important; }
  .p-md-11 {
    padding: 11rem !important; }
  .pt-md-11,
  .py-md-11 {
    padding-top: 11rem !important; }
  .pr-md-11,
  .px-md-11 {
    padding-right: 11rem !important; }
  .pb-md-11,
  .py-md-11 {
    padding-bottom: 11rem !important; }
  .pl-md-11,
  .px-md-11 {
    padding-left: 11rem !important; }
  .p-md-12 {
    padding: 13rem !important; }
  .pt-md-12,
  .py-md-12 {
    padding-top: 13rem !important; }
  .pr-md-12,
  .px-md-12 {
    padding-right: 13rem !important; }
  .pb-md-12,
  .py-md-12 {
    padding-bottom: 13rem !important; }
  .pl-md-12,
  .px-md-12 {
    padding-left: 13rem !important; }
  .p-md-13 {
    padding: 16rem !important; }
  .pt-md-13,
  .py-md-13 {
    padding-top: 16rem !important; }
  .pr-md-13,
  .px-md-13 {
    padding-right: 16rem !important; }
  .pb-md-13,
  .py-md-13 {
    padding-bottom: 16rem !important; }
  .pl-md-13,
  .px-md-13 {
    padding-left: 16rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -0.75rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -0.75rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -0.75rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -0.75rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -0.75rem !important; }
  .m-md-n4 {
    margin: -1rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1rem !important; }
  .m-md-n5 {
    margin: -1.5rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -1.5rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -1.5rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -1.5rem !important; }
  .m-md-n6 {
    margin: -2rem !important; }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -2rem !important; }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -2rem !important; }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -2rem !important; }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -2rem !important; }
  .m-md-n7 {
    margin: -3rem !important; }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -3rem !important; }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -3rem !important; }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -3rem !important; }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -3rem !important; }
  .m-md-n8 {
    margin: -5rem !important; }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -5rem !important; }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -5rem !important; }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -5rem !important; }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -5rem !important; }
  .m-md-n9 {
    margin: -7rem !important; }
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -7rem !important; }
  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -7rem !important; }
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -7rem !important; }
  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -7rem !important; }
  .m-md-n10 {
    margin: -9rem !important; }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -9rem !important; }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -9rem !important; }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -9rem !important; }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -9rem !important; }
  .m-md-n11 {
    margin: -11rem !important; }
  .mt-md-n11,
  .my-md-n11 {
    margin-top: -11rem !important; }
  .mr-md-n11,
  .mx-md-n11 {
    margin-right: -11rem !important; }
  .mb-md-n11,
  .my-md-n11 {
    margin-bottom: -11rem !important; }
  .ml-md-n11,
  .mx-md-n11 {
    margin-left: -11rem !important; }
  .m-md-n12 {
    margin: -13rem !important; }
  .mt-md-n12,
  .my-md-n12 {
    margin-top: -13rem !important; }
  .mr-md-n12,
  .mx-md-n12 {
    margin-right: -13rem !important; }
  .mb-md-n12,
  .my-md-n12 {
    margin-bottom: -13rem !important; }
  .ml-md-n12,
  .mx-md-n12 {
    margin-left: -13rem !important; }
  .m-md-n13 {
    margin: -16rem !important; }
  .mt-md-n13,
  .my-md-n13 {
    margin-top: -16rem !important; }
  .mr-md-n13,
  .mx-md-n13 {
    margin-right: -16rem !important; }
  .mb-md-n13,
  .my-md-n13 {
    margin-bottom: -16rem !important; }
  .ml-md-n13,
  .mx-md-n13 {
    margin-left: -16rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 0.75rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 0.75rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 0.75rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 0.75rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 0.75rem !important; }
  .m-lg-4 {
    margin: 1rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1rem !important; }
  .m-lg-5 {
    margin: 1.5rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 1.5rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 1.5rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 1.5rem !important; }
  .m-lg-6 {
    margin: 2rem !important; }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 2rem !important; }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 2rem !important; }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 2rem !important; }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 2rem !important; }
  .m-lg-7 {
    margin: 3rem !important; }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 3rem !important; }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 3rem !important; }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 3rem !important; }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 3rem !important; }
  .m-lg-8 {
    margin: 5rem !important; }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 5rem !important; }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 5rem !important; }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 5rem !important; }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 5rem !important; }
  .m-lg-9 {
    margin: 7rem !important; }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 7rem !important; }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 7rem !important; }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 7rem !important; }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 7rem !important; }
  .m-lg-10 {
    margin: 9rem !important; }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 9rem !important; }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 9rem !important; }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 9rem !important; }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 9rem !important; }
  .m-lg-11 {
    margin: 11rem !important; }
  .mt-lg-11,
  .my-lg-11 {
    margin-top: 11rem !important; }
  .mr-lg-11,
  .mx-lg-11 {
    margin-right: 11rem !important; }
  .mb-lg-11,
  .my-lg-11 {
    margin-bottom: 11rem !important; }
  .ml-lg-11,
  .mx-lg-11 {
    margin-left: 11rem !important; }
  .m-lg-12 {
    margin: 13rem !important; }
  .mt-lg-12,
  .my-lg-12 {
    margin-top: 13rem !important; }
  .mr-lg-12,
  .mx-lg-12 {
    margin-right: 13rem !important; }
  .mb-lg-12,
  .my-lg-12 {
    margin-bottom: 13rem !important; }
  .ml-lg-12,
  .mx-lg-12 {
    margin-left: 13rem !important; }
  .m-lg-13 {
    margin: 16rem !important; }
  .mt-lg-13,
  .my-lg-13 {
    margin-top: 16rem !important; }
  .mr-lg-13,
  .mx-lg-13 {
    margin-right: 16rem !important; }
  .mb-lg-13,
  .my-lg-13 {
    margin-bottom: 16rem !important; }
  .ml-lg-13,
  .mx-lg-13 {
    margin-left: 16rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 0.75rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 0.75rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 0.75rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 0.75rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 0.75rem !important; }
  .p-lg-4 {
    padding: 1rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1rem !important; }
  .p-lg-5 {
    padding: 1.5rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 1.5rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 1.5rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 1.5rem !important; }
  .p-lg-6 {
    padding: 2rem !important; }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 2rem !important; }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 2rem !important; }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 2rem !important; }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 2rem !important; }
  .p-lg-7 {
    padding: 3rem !important; }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 3rem !important; }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 3rem !important; }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 3rem !important; }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 3rem !important; }
  .p-lg-8 {
    padding: 5rem !important; }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 5rem !important; }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 5rem !important; }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 5rem !important; }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 5rem !important; }
  .p-lg-9 {
    padding: 7rem !important; }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 7rem !important; }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 7rem !important; }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 7rem !important; }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 7rem !important; }
  .p-lg-10 {
    padding: 9rem !important; }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 9rem !important; }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 9rem !important; }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 9rem !important; }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 9rem !important; }
  .p-lg-11 {
    padding: 11rem !important; }
  .pt-lg-11,
  .py-lg-11 {
    padding-top: 11rem !important; }
  .pr-lg-11,
  .px-lg-11 {
    padding-right: 11rem !important; }
  .pb-lg-11,
  .py-lg-11 {
    padding-bottom: 11rem !important; }
  .pl-lg-11,
  .px-lg-11 {
    padding-left: 11rem !important; }
  .p-lg-12 {
    padding: 13rem !important; }
  .pt-lg-12,
  .py-lg-12 {
    padding-top: 13rem !important; }
  .pr-lg-12,
  .px-lg-12 {
    padding-right: 13rem !important; }
  .pb-lg-12,
  .py-lg-12 {
    padding-bottom: 13rem !important; }
  .pl-lg-12,
  .px-lg-12 {
    padding-left: 13rem !important; }
  .p-lg-13 {
    padding: 16rem !important; }
  .pt-lg-13,
  .py-lg-13 {
    padding-top: 16rem !important; }
  .pr-lg-13,
  .px-lg-13 {
    padding-right: 16rem !important; }
  .pb-lg-13,
  .py-lg-13 {
    padding-bottom: 16rem !important; }
  .pl-lg-13,
  .px-lg-13 {
    padding-left: 16rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -0.75rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -0.75rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -0.75rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -0.75rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -0.75rem !important; }
  .m-lg-n4 {
    margin: -1rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1rem !important; }
  .m-lg-n5 {
    margin: -1.5rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -1.5rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -1.5rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -1.5rem !important; }
  .m-lg-n6 {
    margin: -2rem !important; }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -2rem !important; }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -2rem !important; }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -2rem !important; }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -2rem !important; }
  .m-lg-n7 {
    margin: -3rem !important; }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -3rem !important; }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -3rem !important; }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -3rem !important; }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -3rem !important; }
  .m-lg-n8 {
    margin: -5rem !important; }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -5rem !important; }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -5rem !important; }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -5rem !important; }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -5rem !important; }
  .m-lg-n9 {
    margin: -7rem !important; }
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -7rem !important; }
  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -7rem !important; }
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -7rem !important; }
  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -7rem !important; }
  .m-lg-n10 {
    margin: -9rem !important; }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -9rem !important; }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -9rem !important; }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -9rem !important; }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -9rem !important; }
  .m-lg-n11 {
    margin: -11rem !important; }
  .mt-lg-n11,
  .my-lg-n11 {
    margin-top: -11rem !important; }
  .mr-lg-n11,
  .mx-lg-n11 {
    margin-right: -11rem !important; }
  .mb-lg-n11,
  .my-lg-n11 {
    margin-bottom: -11rem !important; }
  .ml-lg-n11,
  .mx-lg-n11 {
    margin-left: -11rem !important; }
  .m-lg-n12 {
    margin: -13rem !important; }
  .mt-lg-n12,
  .my-lg-n12 {
    margin-top: -13rem !important; }
  .mr-lg-n12,
  .mx-lg-n12 {
    margin-right: -13rem !important; }
  .mb-lg-n12,
  .my-lg-n12 {
    margin-bottom: -13rem !important; }
  .ml-lg-n12,
  .mx-lg-n12 {
    margin-left: -13rem !important; }
  .m-lg-n13 {
    margin: -16rem !important; }
  .mt-lg-n13,
  .my-lg-n13 {
    margin-top: -16rem !important; }
  .mr-lg-n13,
  .mx-lg-n13 {
    margin-right: -16rem !important; }
  .mb-lg-n13,
  .my-lg-n13 {
    margin-bottom: -16rem !important; }
  .ml-lg-n13,
  .mx-lg-n13 {
    margin-left: -16rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 0.75rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 0.75rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 0.75rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 0.75rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 0.75rem !important; }
  .m-xl-4 {
    margin: 1rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1rem !important; }
  .m-xl-5 {
    margin: 1.5rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 1.5rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 1.5rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 1.5rem !important; }
  .m-xl-6 {
    margin: 2rem !important; }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 2rem !important; }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 2rem !important; }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 2rem !important; }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 2rem !important; }
  .m-xl-7 {
    margin: 3rem !important; }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 3rem !important; }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 3rem !important; }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 3rem !important; }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 3rem !important; }
  .m-xl-8 {
    margin: 5rem !important; }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 5rem !important; }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 5rem !important; }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 5rem !important; }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 5rem !important; }
  .m-xl-9 {
    margin: 7rem !important; }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 7rem !important; }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 7rem !important; }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 7rem !important; }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 7rem !important; }
  .m-xl-10 {
    margin: 9rem !important; }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 9rem !important; }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 9rem !important; }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 9rem !important; }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 9rem !important; }
  .m-xl-11 {
    margin: 11rem !important; }
  .mt-xl-11,
  .my-xl-11 {
    margin-top: 11rem !important; }
  .mr-xl-11,
  .mx-xl-11 {
    margin-right: 11rem !important; }
  .mb-xl-11,
  .my-xl-11 {
    margin-bottom: 11rem !important; }
  .ml-xl-11,
  .mx-xl-11 {
    margin-left: 11rem !important; }
  .m-xl-12 {
    margin: 13rem !important; }
  .mt-xl-12,
  .my-xl-12 {
    margin-top: 13rem !important; }
  .mr-xl-12,
  .mx-xl-12 {
    margin-right: 13rem !important; }
  .mb-xl-12,
  .my-xl-12 {
    margin-bottom: 13rem !important; }
  .ml-xl-12,
  .mx-xl-12 {
    margin-left: 13rem !important; }
  .m-xl-13 {
    margin: 16rem !important; }
  .mt-xl-13,
  .my-xl-13 {
    margin-top: 16rem !important; }
  .mr-xl-13,
  .mx-xl-13 {
    margin-right: 16rem !important; }
  .mb-xl-13,
  .my-xl-13 {
    margin-bottom: 16rem !important; }
  .ml-xl-13,
  .mx-xl-13 {
    margin-left: 16rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 0.75rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 0.75rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 0.75rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 0.75rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 0.75rem !important; }
  .p-xl-4 {
    padding: 1rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1rem !important; }
  .p-xl-5 {
    padding: 1.5rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 1.5rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 1.5rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 1.5rem !important; }
  .p-xl-6 {
    padding: 2rem !important; }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 2rem !important; }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 2rem !important; }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 2rem !important; }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 2rem !important; }
  .p-xl-7 {
    padding: 3rem !important; }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 3rem !important; }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 3rem !important; }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 3rem !important; }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 3rem !important; }
  .p-xl-8 {
    padding: 5rem !important; }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 5rem !important; }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 5rem !important; }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 5rem !important; }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 5rem !important; }
  .p-xl-9 {
    padding: 7rem !important; }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 7rem !important; }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 7rem !important; }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 7rem !important; }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 7rem !important; }
  .p-xl-10 {
    padding: 9rem !important; }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 9rem !important; }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 9rem !important; }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 9rem !important; }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 9rem !important; }
  .p-xl-11 {
    padding: 11rem !important; }
  .pt-xl-11,
  .py-xl-11 {
    padding-top: 11rem !important; }
  .pr-xl-11,
  .px-xl-11 {
    padding-right: 11rem !important; }
  .pb-xl-11,
  .py-xl-11 {
    padding-bottom: 11rem !important; }
  .pl-xl-11,
  .px-xl-11 {
    padding-left: 11rem !important; }
  .p-xl-12 {
    padding: 13rem !important; }
  .pt-xl-12,
  .py-xl-12 {
    padding-top: 13rem !important; }
  .pr-xl-12,
  .px-xl-12 {
    padding-right: 13rem !important; }
  .pb-xl-12,
  .py-xl-12 {
    padding-bottom: 13rem !important; }
  .pl-xl-12,
  .px-xl-12 {
    padding-left: 13rem !important; }
  .p-xl-13 {
    padding: 16rem !important; }
  .pt-xl-13,
  .py-xl-13 {
    padding-top: 16rem !important; }
  .pr-xl-13,
  .px-xl-13 {
    padding-right: 16rem !important; }
  .pb-xl-13,
  .py-xl-13 {
    padding-bottom: 16rem !important; }
  .pl-xl-13,
  .px-xl-13 {
    padding-left: 16rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -0.75rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -0.75rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -0.75rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -0.75rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -0.75rem !important; }
  .m-xl-n4 {
    margin: -1rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1rem !important; }
  .m-xl-n5 {
    margin: -1.5rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -1.5rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -1.5rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -1.5rem !important; }
  .m-xl-n6 {
    margin: -2rem !important; }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -2rem !important; }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -2rem !important; }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -2rem !important; }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -2rem !important; }
  .m-xl-n7 {
    margin: -3rem !important; }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -3rem !important; }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -3rem !important; }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -3rem !important; }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -3rem !important; }
  .m-xl-n8 {
    margin: -5rem !important; }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -5rem !important; }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -5rem !important; }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -5rem !important; }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -5rem !important; }
  .m-xl-n9 {
    margin: -7rem !important; }
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -7rem !important; }
  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -7rem !important; }
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -7rem !important; }
  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -7rem !important; }
  .m-xl-n10 {
    margin: -9rem !important; }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -9rem !important; }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -9rem !important; }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -9rem !important; }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -9rem !important; }
  .m-xl-n11 {
    margin: -11rem !important; }
  .mt-xl-n11,
  .my-xl-n11 {
    margin-top: -11rem !important; }
  .mr-xl-n11,
  .mx-xl-n11 {
    margin-right: -11rem !important; }
  .mb-xl-n11,
  .my-xl-n11 {
    margin-bottom: -11rem !important; }
  .ml-xl-n11,
  .mx-xl-n11 {
    margin-left: -11rem !important; }
  .m-xl-n12 {
    margin: -13rem !important; }
  .mt-xl-n12,
  .my-xl-n12 {
    margin-top: -13rem !important; }
  .mr-xl-n12,
  .mx-xl-n12 {
    margin-right: -13rem !important; }
  .mb-xl-n12,
  .my-xl-n12 {
    margin-bottom: -13rem !important; }
  .ml-xl-n12,
  .mx-xl-n12 {
    margin-left: -13rem !important; }
  .m-xl-n13 {
    margin: -16rem !important; }
  .mt-xl-n13,
  .my-xl-n13 {
    margin-top: -16rem !important; }
  .mr-xl-n13,
  .mx-xl-n13 {
    margin-right: -16rem !important; }
  .mb-xl-n13,
  .my-xl-n13 {
    margin-bottom: -16rem !important; }
  .ml-xl-n13,
  .mx-xl-n13 {
    margin-left: -16rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 600 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #50a1ff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0478ff !important; }

.text-secondary {
  color: #e9ecf0 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #bcc5d1 !important; }

.text-success {
  color: #3cd458 !important; }

a.text-success:hover, a.text-success:focus {
  color: #23a03a !important; }

.text-info {
  color: #926dde !important; }

a.text-info:hover, a.text-info:focus {
  color: #642fd0 !important; }

.text-warning {
  color: #ffba00 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #b38200 !important; }

.text-danger {
  color: #ff4954 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #fc000f !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #191919 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: black !important; }

.text-body {
  color: #757575 !important; }

.text-muted {
  color: #868e96 !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #f1f2f3; }
  .table .thead-dark th {
    color: inherit;
    border-color: #f1f2f3; } }

a {
  color: #50a1ff;
  transition: .2s linear; }
  a:hover, a:focus {
    color: #50a1ff;
    text-decoration: none;
    outline: none; }
  a.anchor::before {
    content: '#';
    vertical-align: text-bottom; }

.link-color-default a {
  color: #757575; }
  .link-color-default a:hover, .link-color-default a:focus {
    color: #50a1ff; }

b,
strong {
  font-weight: 600; }

del {
  opacity: .6; }

.semibold {
  font-weight: 400; }

.lead {
  font-size: 1.125rem; }

.label {
  display: block;
  font-weight: 600;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 2px; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: #323d47;
  letter-spacing: -0.45px; }
  h1 small, h2 small, h3 small, h4 small, h5 small, h6 small,
  .h1 small, .h2 small, .h3 small, .h4 small, .h5 small, .h6 small {
    font-size: 65%;
    color: #929daf; }
  h1 strong,
  h1 b, h2 strong,
  h2 b, h3 strong,
  h3 b, h4 strong,
  h4 b, h5 strong,
  h5 b, h6 strong,
  h6 b,
  .h1 strong,
  .h1 b, .h2 strong,
  .h2 b, .h3 strong,
  .h3 b, .h4 strong,
  .h4 b, .h5 strong,
  .h5 b, .h6 strong,
  .h6 b {
    font-weight: 600; }
  h1 a, h2 a, h3 a, h4 a, h5 a, h6 a,
  .h1 a, .h2 a, .h3 a, .h4 a, .h5 a, .h6 a {
    color: #323d47; }
    h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover,
    .h1 a:hover, .h2 a:hover, .h3 a:hover, .h4 a:hover, .h5 a:hover, .h6 a:hover {
      color: #50a1ff; }
  h1 .anchor, h2 .anchor, h3 .anchor, h4 .anchor, h5 .anchor, h6 .anchor,
  .h1 .anchor, .h2 .anchor, .h3 .anchor, .h4 .anchor, .h5 .anchor, .h6 .anchor {
    position: absolute;
    right: 100%;
    opacity: 0;
    color: #50a1ff; }
  h1.anchor-visible .anchor,
  h1:hover .anchor, h2.anchor-visible .anchor,
  h2:hover .anchor, h3.anchor-visible .anchor,
  h3:hover .anchor, h4.anchor-visible .anchor,
  h4:hover .anchor, h5.anchor-visible .anchor,
  h5:hover .anchor, h6.anchor-visible .anchor,
  h6:hover .anchor,
  .h1.anchor-visible .anchor,
  .h1:hover .anchor, .h2.anchor-visible .anchor,
  .h2:hover .anchor, .h3.anchor-visible .anchor,
  .h3:hover .anchor, .h4.anchor-visible .anchor,
  .h4:hover .anchor, .h5.anchor-visible .anchor,
  .h5:hover .anchor, .h6.anchor-visible .anchor,
  .h6:hover .anchor {
    opacity: 0.5; }
    h1.anchor-visible .anchor:hover,
    h1:hover .anchor:hover, h2.anchor-visible .anchor:hover,
    h2:hover .anchor:hover, h3.anchor-visible .anchor:hover,
    h3:hover .anchor:hover, h4.anchor-visible .anchor:hover,
    h4:hover .anchor:hover, h5.anchor-visible .anchor:hover,
    h5:hover .anchor:hover, h6.anchor-visible .anchor:hover,
    h6:hover .anchor:hover,
    .h1.anchor-visible .anchor:hover,
    .h1:hover .anchor:hover, .h2.anchor-visible .anchor:hover,
    .h2:hover .anchor:hover, .h3.anchor-visible .anchor:hover,
    .h3:hover .anchor:hover, .h4.anchor-visible .anchor:hover,
    .h4:hover .anchor:hover, .h5.anchor-visible .anchor:hover,
    .h5:hover .anchor:hover, .h6.anchor-visible .anchor:hover,
    .h6:hover .anchor:hover {
      opacity: 0.8; }

h1, .h1 {
  font-weight: 500; }

h2, .h2 {
  font-weight: 500; }

h3, .h3 {
  font-weight: 500; }

h4, .h4 {
  font-weight: 500; }

h5, .h5 {
  font-weight: 500; }

h6, .h6 {
  font-weight: 500; }

h6, .h6 {
  letter-spacing: 0.75px; }
  h6.bold, .h6.bold {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.75rem;
    letter-spacing: .5px; }
    h6.bold::before, .h6.bold::before {
      content: '\2014 \00A0'; }

.heading-alt {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  letter-spacing: 0; }

.blockquote {
  text-align: center;
  margin-bottom: 2rem; }
  .blockquote .quote-sign::before {
    content: '“';
    display: block;
    font-size: 9rem;
    line-height: .2;
    font-weight: 600;
    padding-top: 4rem;
    opacity: .1; }
  .blockquote .quote-sign-end::before {
    content: '”'; }
  .blockquote p::before,
  .blockquote p::after {
    font-size: 1.5rem;
    line-height: 1;
    opacity: .5; }
  .blockquote p::before {
    content: '“';
    margin-left: -13px;
    padding-right: 4px; }
  .blockquote p::after {
    content: '”';
    margin-right: -13px;
    padding-left: 4px; }
  .blockquote footer {
    color: #757575;
    font-size: 0.875rem;
    margin-top: 1.5rem; }
    .blockquote footer::before {
      content: '\2014';
      margin-right: 0.5rem; }
  @media (max-width: 991.98px) {
    .blockquote {
      padding-left: 2rem;
      padding-right: 2rem;
      margin-bottom: 2rem; }
      .blockquote .quote-sign::before {
        font-size: 5rem; } }

.text-quoted::before, .text-quoted::after {
  opacity: .7; }

.text-quoted::before {
  content: '“';
  padding-right: 2px; }

.text-quoted::after {
  content: '”';
  padding-left: 2px; }

hr {
  margin: 2rem auto; }

.hr-dash {
  border-top-style: dashed; }

.hr-dot {
  border-top-style: dotted; }

.hr-light {
  border-top-color: rgba(#1a1a1a, 0.05);
  border-top-color: rgba(var(--color-text), 0.05); }

.mark-underline {
  padding-bottom: 2px;
  border-bottom: 1px dashed white; }

.divider {
  display: flex;
  align-items: center;
  flex: 0 1;
  color: #929daf;
  font-size: 0.8375rem;
  letter-spacing: .5px;
  margin: 2rem auto;
  width: 100%; }
  .divider::before, .divider::after {
    content: '';
    flex-grow: 1;
    border-top: 1px solid #eaeff4;
    border-top: 1px solid var(--color-divider); }
  .divider::before {
    margin-right: 1rem; }
  .divider::after {
    margin-left: 1rem; }

::selection {
  background: #74b4ff;
  color: #fff; }

::-moz-selection {
  background: #74b4ff;
  color: #fff; }

@media (max-width: 767.98px) {
  html {
    font-size: 0.9375rem; } }

img {
  max-width: 100%;
  height: auto; }

.table {
  border: 1px solid #f1f2f3;
  border: 1px solid var(--color-divider-light); }

.table th {
  border-top: 0;
  font-weight: 400; }

.table thead th {
  border-bottom: 1px solid #eaeff4;
  border-bottom: 1px solid var(--color-divider); }

.table-hover tbody tr {
  transition: background-color 0.2s linear; }

.table-sm th,
.table-sm td {
  padding: .5rem; }

.table-lg th,
.table-lg td {
  padding: 1rem; }

pre {
  background-color: #fcfdfd;
  padding: 12px;
  border: 1px solid #f1f2f3;
  border: 1px solid var(--color-divider-light);
  border-left: 3px solid var(--color-primary);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.02); }

.pre-scrollable {
  max-height: 350px;
  overflow-y: auto; }

.fa {
  font-weight: inherit; }

.iconbox {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  line-height: 38px;
  background-color: #f5f6f7;
  color: #999999;
  border-radius: 10rem; }
  .iconbox.iconbox-xs {
    width: 24px;
    height: 24px;
    line-height: 24px;
    font-size: .6875rem; }
  .iconbox.iconbox-sm {
    width: 32px;
    height: 32px;
    line-height: 32px;
    font-size: .75rem; }
  .iconbox.iconbox-lg, .step-icon .iconbox {
    width: 48px;
    height: 48px;
    line-height: 48px;
    font-size: 1.25rem; }
  .iconbox.iconbox-xl {
    width: 64px;
    height: 64px;
    line-height: 64px;
    font-size: 1.5rem; }
  .iconbox.iconbox-xxl {
    width: 96px;
    height: 96px;
    line-height: 96px;
    font-size: 2rem; }

.iconbox-sq {
  border-radius: 0; }

.iconbox-outline {
  border: 1px solid #f1f2f3;
  border: 1px solid var(--color-divider-light);
  background-color: transparent; }

.badge {
  padding-top: 5px;
  padding-bottom: 5px; }
  .badge:empty {
    display: inline-block; }

.badge-pale.badge-primary {
  color: #4387d6;
  background-color: pale-color(#50a1ff); }

.badge-pale.badge-secondary {
  background-color: #f0f4f7; }

.badge-pale.badge-success {
  color: #32b24a;
  background-color: pale-color(#3cd458); }

.badge-pale.badge-info {
  color: #7b5cba;
  background-color: pale-color(#926dde); }

.badge-pale.badge-warning {
  color: #d69c00;
  background-color: pale-color(#ffba00); }

.badge-pale.badge-danger {
  color: #d63d47;
  background-color: pale-color(#ff4954); }

.badge-pale.badge-light {
  background-color: #ffffff; }

.badge-pale.badge-dark {
  color: #151515;
  background-color: #e5e5e5; }

.badge-glass.badge-primary {
  background-color: rgba(80, 161, 255, 0.55);
  color: #fff; }

.badge-glass.badge-secondary {
  background-color: rgba(233, 236, 240, 0.55);
  color: #fff; }

.badge-glass.badge-success {
  background-color: rgba(60, 212, 88, 0.55);
  color: #fff; }

.badge-glass.badge-info {
  background-color: rgba(146, 109, 222, 0.55);
  color: #fff; }

.badge-glass.badge-warning {
  background-color: rgba(255, 186, 0, 0.55);
  color: #fff; }

.badge-glass.badge-danger {
  background-color: rgba(255, 73, 84, 0.55);
  color: #fff; }

.badge-glass.badge-light {
  background-color: rgba(248, 249, 250, 0.55);
  color: #fff; }

.badge-glass.badge-dark {
  background-color: rgba(25, 25, 25, 0.55);
  color: #fff; }

.badge-dot {
  width: 6px;
  height: 6px;
  padding: 0;
  border-radius: 100%;
  vertical-align: middle; }

.badge-ring {
  position: relative;
  width: 8px;
  height: 8px;
  padding: 0;
  border-radius: 100%;
  vertical-align: middle; }
  .badge-ring::after {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #fff;
    -webkit-transform: scale(1);
            transform: scale(1);
    transition: .3s; }
  .badge-ring.fill::after {
    -webkit-transform: scale(0);
            transform: scale(0); }

.badge-number {
  position: relative;
  top: -10px;
  margin-left: -0.5rem;
  font-size: 0.75rem;
  padding: 2px 4px;
  border-radius: 10rem;
  min-width: 1rem;
  min-height: 1rem; }

.breadcrumb {
  margin-bottom: 0; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0; }
  .breadcrumb-item + .breadcrumb-item::before {
    padding: 0;
    margin: 0 0.75rem;
    width: 4px;
    height: 4px;
    border-right: 1px solid #bfc5ca;
    border-top: 1px solid #bfc5ca;
    vertical-align: middle;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }

.breadcrumb-item a {
  color: #999999; }
  .breadcrumb-item a:hover {
    color: #50a1ff; }

.btn {
  font-size: 11px;
  padding: 8px 26px 6px;
  letter-spacing: 1.7px;
  text-transform: uppercase;
  border-radius: 2px;
  outline: none;
  transition: 0.15s linear; }

button:focus {
  outline: none; }

.btn-link:hover,
.btn-link:focus {
  -webkit-text-decoration: normal;
          text-decoration: normal; }

.btn-outline-secondary {
  color: #929daf; }

.btn-white {
  color: #757575;
  background-color: #fff;
  border-color: #fff; }
  .btn-white:hover {
    color: #757575;
    background-color: whitesmoke;
    border-color: whitesmoke;
    box-shadow: 0 1px 10px rgba(255, 255, 255, 0.4); }
  .btn-white:focus, .btn-white.focus {
    color: #757575;
    box-shadow: 0 0 0 0rem rgba(255, 255, 255, 0.5); }
  .btn-white.disabled, .btn-white:disabled {
    background-color: #fff;
    border-color: #fff; }
  .btn-white:hover {
    background-color: #fff;
    border-color: #fff;
    color: #323d47; }

.btn-facebook {
  color: #fff;
  background-color: #3b5998;
  border-color: #3b5998; }
  .btn-facebook:hover {
    color: #fff;
    background-color: #355089;
    border-color: #355089;
    box-shadow: 0 1px 10px rgba(59, 89, 152, 0.4); }
  .btn-facebook:focus, .btn-facebook.focus {
    color: #fff;
    box-shadow: 0 0 0 0rem rgba(59, 89, 152, 0.5); }
  .btn-facebook.disabled, .btn-facebook:disabled {
    background-color: #3b5998;
    border-color: #3b5998; }

.btn-google {
  color: #fff;
  background-color: #dd4b39;
  border-color: #dd4b39; }
  .btn-google:hover {
    color: #fff;
    background-color: #da3b28;
    border-color: #da3b28;
    box-shadow: 0 1px 10px rgba(221, 75, 57, 0.4); }
  .btn-google:focus, .btn-google.focus {
    color: #fff;
    box-shadow: 0 0 0 0rem rgba(221, 75, 57, 0.5); }
  .btn-google.disabled, .btn-google:disabled {
    background-color: #dd4b39;
    border-color: #dd4b39; }

.btn-twitter {
  color: #fff;
  background-color: #00aced;
  border-color: #00aced; }
  .btn-twitter:hover {
    color: #fff;
    background-color: #009dd9;
    border-color: #009dd9;
    box-shadow: 0 1px 10px rgba(0, 172, 237, 0.4); }
  .btn-twitter:focus, .btn-twitter.focus {
    color: #fff;
    box-shadow: 0 0 0 0rem rgba(0, 172, 237, 0.5); }
  .btn-twitter.disabled, .btn-twitter:disabled {
    background-color: #00aced;
    border-color: #00aced; }

.btn-xs {
  padding: 4px 12px 3px;
  font-size: 10px; }

.btn-sm, .btn-group-sm > .btn, .input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 8px 20px 6px;
  font-size: 11px; }

.btn-lg, .btn-group-lg > .btn, .input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 7px 32px 6px;
  font-size: 12px; }

.btn-xl {
  padding: 10px 38px 10px;
  font-size: 13px; }

.btn-round {
  border-radius: 10rem; }

.btn-circle {
  width: 56px;
  height: 56px;
  padding: 0;
  border-radius: 10rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem; }
  .btn-circle .fa-play {
    margin-left: 4px;
    font-size: 0.875rem; }
  .btn-circle.btn-xs {
    width: 40px;
    height: 40px;
    font-size: 0.875rem; }
  .btn-circle.btn-sm, .btn-group-sm > .btn-circle.btn, .input-group-sm > .input-group-prepend > .btn-circle.btn,
  .input-group-sm > .input-group-append > .btn-circle.btn {
    width: 48px;
    height: 48px;
    font-size: 0.9325rem; }
  .btn-circle.btn-lg, .btn-group-lg > .btn-circle.btn, .input-group-lg > .input-group-prepend > .btn-circle.btn,
  .input-group-lg > .input-group-append > .btn-circle.btn {
    width: 64px;
    height: 64px;
    font-size: 1.25rem; }
  .btn-circle.btn-xl {
    width: 72px;
    height: 72px;
    font-size: 1.5rem; }

.btn-glass.btn-primary {
  background-color: rgba(80, 161, 255, 0.6);
  border-color: transparent;
  color: #fff; }
  .btn-glass.btn-primary:hover {
    background-color: rgba(80, 161, 255, 0.9); }

.btn-glass.btn-secondary {
  background-color: rgba(233, 236, 240, 0.6);
  border-color: transparent;
  color: #fff; }
  .btn-glass.btn-secondary:hover {
    background-color: rgba(233, 236, 240, 0.9); }

.btn-glass.btn-success {
  background-color: rgba(60, 212, 88, 0.6);
  border-color: transparent;
  color: #fff; }
  .btn-glass.btn-success:hover {
    background-color: rgba(60, 212, 88, 0.9); }

.btn-glass.btn-info {
  background-color: rgba(146, 109, 222, 0.6);
  border-color: transparent;
  color: #fff; }
  .btn-glass.btn-info:hover {
    background-color: rgba(146, 109, 222, 0.9); }

.btn-glass.btn-warning {
  background-color: rgba(255, 186, 0, 0.6);
  border-color: transparent;
  color: #fff; }
  .btn-glass.btn-warning:hover {
    background-color: rgba(255, 186, 0, 0.9); }

.btn-glass.btn-danger {
  background-color: rgba(255, 73, 84, 0.6);
  border-color: transparent;
  color: #fff; }
  .btn-glass.btn-danger:hover {
    background-color: rgba(255, 73, 84, 0.9); }

.btn-glass.btn-light {
  background-color: rgba(248, 249, 250, 0.6);
  border-color: transparent;
  color: #fff; }
  .btn-glass.btn-light:hover {
    background-color: rgba(248, 249, 250, 0.9); }

.btn-glass.btn-dark {
  background-color: rgba(25, 25, 25, 0.6);
  border-color: transparent;
  color: #fff; }
  .btn-glass.btn-dark:hover {
    background-color: rgba(25, 25, 25, 0.9); }

.btn-label {
  position: relative;
  padding-left: 52px;
  overflow: hidden; }
  .btn-label label {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 36px;
    line-height: inherit;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
    margin-bottom: 0; }
  .btn-label.btn-xl {
    padding-left: 62px; }
    .btn-label.btn-xl label {
      width: 46px; }
  .btn-label.btn-lg, .btn-group-lg > .btn-label.btn, .input-group-lg > .input-group-prepend > .btn-label.btn,
  .input-group-lg > .input-group-append > .btn-label.btn {
    padding-left: 56px; }
    .btn-label.btn-lg label, .btn-group-lg > .btn-label.btn label, .input-group-lg > .input-group-prepend > .btn-label.btn label, .input-group-lg > .input-group-append > .btn-label.btn label {
      width: 40px; }
  .btn-label.btn-sm, .btn-group-sm > .btn-label.btn, .input-group-sm > .input-group-prepend > .btn-label.btn,
  .input-group-sm > .input-group-append > .btn-label.btn {
    padding-left: 48px; }
    .btn-label.btn-sm label, .btn-group-sm > .btn-label.btn label, .input-group-sm > .input-group-prepend > .btn-label.btn label, .input-group-sm > .input-group-append > .btn-label.btn label {
      width: 32px; }
  .btn-label.btn-xs {
    padding-left: 43px; }
    .btn-label.btn-xs label {
      width: 27px; }

.btn-square {
  padding-left: 5px;
  padding-right: 5px;
  width: 36px;
  height: 36px; }
  .btn-square.btn-xl {
    width: 46px;
    height: 46px; }
  .btn-square.btn-lg, .btn-group-lg > .btn-square.btn, .input-group-lg > .input-group-prepend > .btn-square.btn,
  .input-group-lg > .input-group-append > .btn-square.btn {
    width: 40px;
    height: 40px; }
  .btn-square.btn-sm, .btn-group-sm > .btn-square.btn, .input-group-sm > .input-group-prepend > .btn-square.btn,
  .input-group-sm > .input-group-append > .btn-square.btn {
    width: 32px;
    height: 32px; }
  .btn-square.btn-xs {
    width: 27px;
    height: 27px; }

.dropdown-toggle {
  cursor: pointer; }
  .dropdown-toggle::after,
  .dropup .dropdown-toggle::after {
    content: '';
    width: 4px;
    height: 4px;
    vertical-align: initial;
    margin-left: 0.5rem;
    vertical-align: middle;
    border: none;
    border-right: 1px solid;
    border-bottom: 1px solid; }
  .dropdown-toggle::after {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .dropup .dropdown-toggle::after {
    -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg); }
  .dropdown-toggle.no-caret::after {
    display: none; }

.dropdown-menu {
  padding: 10px 16px;
  font-size: 14px;
  margin-top: 5px;
  border: none;
  box-shadow: 0 2px 25px rgba(0, 0, 0, 0.07); }
  .dropdown-menu *:last-child {
    margin-bottom: 0; }

.dropdown-item {
  padding-left: 0;
  padding-right: 0;
  color: #999999;
  background-color: transparent;
  opacity: .8; }
  .dropdown-item:hover {
    color: #757575;
    opacity: 1; }
  .dropdown-item:active, .dropdown-item:hover, .dropdown-item:focus {
    background-color: transparent; }

.dropdown-header {
  padding-left: 0;
  padding-right: 0;
  letter-spacing: 1px;
  font-size: 0.8325rem;
  font-weight: 500;
  color: #757575;
  text-transform: uppercase; }

.dropdown-divider {
  background-color: #f1f2f3;
  background-color: var(--color-divider-light);
  margin-left: -1rem;
  margin-right: -1rem; }

.open-on-hover:hover .dropdown-menu,
.open-on-hover:focus .dropdown-menu,
.open-on-hover.show .dropdown-menu {
  margin-top: 0; }

.modal .close {
  font-weight: 100; }

.modal-dialog {
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  height: 100%; }

.modal-content {
  margin-top: auto;
  margin-bottom: auto;
  border: none; }

.modal-header,
.modal-footer {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.modal-body > .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem 0.75rem;
  z-index: 1; }

.modal.fade:not(.modal-top):not(.modal-bottom) .modal-dialog {
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0); }

.modal-top .modal-content {
  margin-top: 3rem; }

.modal-bottom.modal.fade .modal-dialog {
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
  overflow: hidden; }
  .modal-bottom.modal.fade .modal-dialog .modal-content {
    margin-bottom: 0;
    -webkit-transform: translate(0, 100%);
            transform: translate(0, 100%);
    transition: 0.3s ease-out; }

.modal-bottom.modal.show .modal-dialog .modal-content {
  margin-bottom: 3rem;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
  transition: 0.3s ease-out; }

.modal-long {
  height: auto;
  margin-top: 1.75rem;
  margin-bottom: 1.75rem; }

.nav.flex-column .nav-link {
  padding: 2px 0; }
  .nav.flex-column .nav-link:first-child {
    padding-top: 0; }

.nav-link {
  color: #757575;
  font-weight: 400; }

.nav-link.active,
.nav-item.show .nav-link {
  color: #50a1ff; }

.nav-divider {
  display: block;
  margin: 0.5rem 1.125rem;
  height: 1px;
  background-color: rgba(127, 127, 127, 0.1); }

.nav-uppercase > .nav-link,
.nav-uppercase > .nav-item > .nav-link {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.85em;
  word-spacing: 2px; }

.nav-bold .nav-item {
  font-weight: 600; }

.nav-bold .nav-link {
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 0.85em;
  word-spacing: 2px; }

.nav-bolder .nav-item {
  font-weight: 700; }

.nav-bolder .nav-link {
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 0.8em;
  word-spacing: 2px; }

.nav-lead .nav-link {
  font-size: 1rem; }

.nav-pills .nav-link, .nav-pills.flex-column .nav-link {
  padding: 3px 1rem; }

.nav-slash .nav-item {
  display: inline-flex;
  align-items: center; }
  .nav-slash .nav-item::after {
    content: '/';
    font-weight: 400;
    opacity: 0.6; }
  .nav-slash .nav-item:last-child::after {
    display: none; }

.nav-dot .nav-item {
  display: inline-flex;
  align-items: center; }
  .nav-dot .nav-item::after {
    content: "\2022";
    font-weight: 100;
    opacity: 0.75; }
  .nav-dot .nav-item:last-child::after {
    display: none; }

.nav-iconic .nav-link {
  display: inline-flex;
  flex-direction: column;
  align-items: center; }
  .nav-iconic .nav-link i {
    margin-bottom: 1rem; }

.nav-sidebar {
  flex-direction: column;
  font-weight: 400; }
  .nav-sidebar .nav-item {
    margin: 4px 0;
    padding-right: 5px;
    overflow: hidden;
    flex-shrink: 0; }
    .nav-sidebar .nav-item > .nav-link {
      font-weight: 500; }
  .nav-sidebar .nav-link {
    display: flex;
    justify-content: space-between;
    padding: 3px 0;
    line-height: 1.5; }
  .nav-sidebar .nav-angle {
    font-family: themify;
    font-style: normal;
    font-size: 8px;
    display: inline-block;
    line-height: 24px;
    transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    transition: transform 0.5s, -webkit-transform 0.5s;
    color: #757575; }
    .nav-sidebar .nav-angle::before {
      content: "\e649"; }
    .nav-sidebar .nav-angle.rotate {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg); }
  .nav-sidebar .nav {
    flex-direction: column;
    font-size: 0.875rem;
    margin-left: 1rem;
    margin-top: 0.5rem;
    flex-wrap: nowrap;
    display: none; }
    .nav-sidebar .nav .nav {
      display: flex;
      font-size: 95%;
      margin-left: 0.75rem;
      margin-top: 0; }
      .nav-sidebar .nav .nav .nav-item {
        margin-top: 1px;
        margin-bottom: 1px; }
    .nav-sidebar .nav .nav-link {
      flex-shrink: 0; }
  .nav-sidebar .show > .nav-link .nav-angle {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }
  .nav-sidebar .show > .nav {
    display: block; }
  .nav-sidebar .nav-link.active {
    color: #50a1ff; }
    .nav-sidebar .nav-link.active + .nav {
      display: block; }
  .nav-sidebar .nav-item > .nav-link.active {
    color: #757575;
    transition: none; }
  .nav-sidebar a {
    color: rgba(0, 0, 0, 0.6); }
    .nav-sidebar a:hover {
      color: rgba(0, 0, 0, 0.8); }
  .nav-sidebar .nav-header {
    color: #323d47;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin-bottom: 0.5rem; }

.nav-sidebar-hero > .nav-item {
  border-bottom: 1px dashed #eaeff4;
  border-bottom: 1px dashed var(--color-divider);
  padding-top: 2px;
  padding-bottom: 6px; }
  .nav-sidebar-hero > .nav-item:last-child {
    border-bottom: none; }
  .nav-sidebar-hero > .nav-item > .nav-link {
    font-size: 1.125rem;
    font-weight: 400;
    padding: 8px 0; }

.nav-sidebar-hero .nav-item > .nav-link {
  font-weight: 400; }

.nav-sidebar-hero .nav {
  font-size: 1rem; }

.nav-sidebar-hero .nav-link.active {
  font-weight: 500; }

.nav-sidebar-pill .nav-item > .nav-link.active {
  color: #50a1ff; }
  .nav-sidebar-pill .nav-item > .nav-link.active > .nav-angle {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }

.nav-sidebar-pill .nav {
  margin-left: 0.5rem; }

.nav-sidebar-pill .nav .nav-link {
  display: table;
  border-radius: 10rem;
  padding: 6px 16px; }
  .nav-sidebar-pill .nav .nav-link.active {
    background-color: rgba(80, 161, 255, 0.1); }

.nav-page {
  display: block;
  position: fixed;
  top: 50%;
  right: 20px;
  width: 34px;
  border-radius: 10rem;
  padding: 7px 0;
  text-align: center;
  background-color: #191919;
  opacity: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  transition: .5s ease-out; }
  .body-scrolled .nav-page {
    opacity: .5; }
  .nav-page:hover {
    opacity: .65; }
  .nav-page.nav-page-left {
    right: auto;
    left: 20px; }
  .nav-page .nav-item {
    position: relative;
    display: block;
    padding: 0; }
  .nav-page .nav-link {
    display: inline-block;
    width: 8px;
    height: 8px;
    padding: 0;
    border-radius: 4px;
    background-color: #fff;
    opacity: .5; }
    .nav-page .nav-link:hover, .nav-page .nav-link.active {
      opacity: 1; }
    .nav-page .nav-link::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }

.nav-dark .nav-link {
  color: rgba(117, 117, 117, 0.65); }

.nav-dark .nav-link:hover,
.nav-dark .nav-link.active,
.nav-dark .nav-item.show .nav-link {
  color: #323d47; }

.nav-dark .nav-link.disabled {
  color: #a5b3c7; }

.nav-light .nav-link {
  color: rgba(255, 255, 255, 0.65); }

.nav-light .nav-link:hover,
.nav-light .nav-link.active,
.nav-light .nav-item.show .nav-link {
  color: #fff; }

.nav-light .nav-link.disabled {
  color: rgba(255, 255, 255, 0.5); }

.nav-light.nav-pills .nav-link.active,
.nav-light.nav-pills .show > .nav-link {
  background-color: rgba(255, 255, 255, 0.25); }

.nav-trim .nav-link:first-child {
  padding-left: 0; }

.nav-trim .nav-link:last-child {
  padding-right: 0; }

.nav-transform-none .nav-link {
  text-transform: none !important; }

[data-overlay],
[data-overlay-light] {
  position: relative; }

[data-overlay]::before,
[data-overlay-light]::before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #191919;
  border-radius: inherit; }

[data-overlay-light]::before {
  background: #fff; }

[data-overlay-primary]::before {
  background: #50a1ff; }

[data-overlay="1"]::before {
  opacity: 0.1; }

[data-overlay="2"]::before {
  opacity: 0.2; }

[data-overlay="3"]::before {
  opacity: 0.3; }

[data-overlay="4"]::before {
  opacity: 0.4; }

[data-overlay="5"]::before {
  opacity: 0.5; }

[data-overlay="6"]::before {
  opacity: 0.6; }

[data-overlay="7"]::before {
  opacity: 0.7; }

[data-overlay="8"]::before {
  opacity: 0.8; }

[data-overlay="9"]::before {
  opacity: 0.9; }

[data-overlay-light="1"]::before {
  opacity: 0.1; }

[data-overlay-light="2"]::before {
  opacity: 0.2; }

[data-overlay-light="3"]::before {
  opacity: 0.3; }

[data-overlay-light="4"]::before {
  opacity: 0.4; }

[data-overlay-light="5"]::before {
  opacity: 0.5; }

[data-overlay-light="6"]::before {
  opacity: 0.6; }

[data-overlay-light="7"]::before {
  opacity: 0.7; }

[data-overlay-light="8"]::before {
  opacity: 0.8; }

[data-overlay-light="9"]::before {
  opacity: 0.9; }

[data-scrim-top],
[data-scrim-bottom] {
  position: relative; }

[data-scrim-top]::before,
[data-scrim-bottom]::before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 20%;
  background: linear-gradient(to bottom, #191919 0%, rgba(0, 0, 0, 0) 100%);
  z-index: 0;
  border-radius: inherit; }

[data-scrim-bottom]::before {
  top: 20%;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #191919 100%); }

[data-scrim-top="1"]::before,
[data-scrim-bottom="1"]::before {
  opacity: 0.1; }

[data-scrim-top="2"]::before,
[data-scrim-bottom="2"]::before {
  opacity: 0.2; }

[data-scrim-top="3"]::before,
[data-scrim-bottom="3"]::before {
  opacity: 0.3; }

[data-scrim-top="4"]::before,
[data-scrim-bottom="4"]::before {
  opacity: 0.4; }

[data-scrim-top="5"]::before,
[data-scrim-bottom="5"]::before {
  opacity: 0.5; }

[data-scrim-top="6"]::before,
[data-scrim-bottom="6"]::before {
  opacity: 0.6; }

[data-scrim-top="7"]::before,
[data-scrim-bottom="7"]::before {
  opacity: 0.7; }

[data-scrim-top="8"]::before,
[data-scrim-bottom="8"]::before {
  opacity: 0.8; }

[data-scrim-top="9"]::before,
[data-scrim-bottom="9"]::before {
  opacity: 0.9; }

[data-scrim-top="0"]::before,
[data-scrim-bottom="0"]::before {
  opacity: 0; }

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  border-radius: inherit; }

.page-link {
  margin: 0 3px;
  padding: 0;
  text-align: center;
  min-width: 32px; }

.page-link,
.page-item:last-child .page-link,
.page-item:first-child .page-link {
  border-radius: 2px; }

.page-item.disabled {
  opacity: 0.6; }

.step {
  list-style: none;
  margin: 0; }

.step-item {
  display: flex;
  padding: 1rem 0; }

.step-icon {
  position: relative;
  width: 7rem;
  flex-shrink: 0;
  text-align: center; }
  .step-icon .iconbox {
    color: #bfc5ca;
    background-color: #eaeff4;
    background-color: var(--color-divider);
    font-size: 1.5rem; }
  .step-icon::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 48px;
    bottom: -2rem;
    margin-left: -1px;
    border-left: 2px dashed #eaeff4;
    border-left: 2px dashed var(--color-divider); }

.step-content {
  margin-left: 1rem; }

.step .step-item:last-child .step-icon::before {
  bottom: 1rem; }

.custom-control {
  padding-left: 0;
  min-height: auto; }

.custom-control-input:checked ~ .custom-control-label::after {
  -webkit-transform: scale(1);
          transform: scale(1); }

.custom-control-input:disabled ~ .custom-control-label {
  opacity: .7; }
  .custom-control-input:disabled ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::after {
    opacity: 0.7; }

.custom-control-label {
  display: flex;
  align-items: center;
  font-weight: 300; }
  .custom-control-label::before {
    position: static;
    top: auto;
    border: 1px solid #eaeff4;
    border: 1px solid var(--color-divider);
    margin-right: 0.5rem; }
  .custom-control-label::after {
    -webkit-transform: scale(0);
            transform: scale(0);
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s; }

.custom-radio {
  cursor: pointer; }
  .custom-radio .custom-control-label::after {
    top: 8px;
    left: 0.3125rem;
    border-radius: 50%;
    width: 0.5rem;
    height: 0.5rem;
    background-color: #50a1ff; }

.custom-checkbox {
  cursor: pointer; }
  .custom-checkbox .custom-control-label::after {
    top: 50%;
    left: 6px;
    margin-top: -6px;
    width: 5px;
    height: 10px;
    border: solid #50a1ff;
    border-width: 0 1px 1px 0;
    -webkit-transform: scale(0) rotate(35deg);
            transform: scale(0) rotate(35deg); }
  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    -webkit-transform: scale(1) rotate(35deg);
            transform: scale(1) rotate(35deg); }

.form-control {
  font-weight: 300; }
  .form-control option {
    font-weight: 300; }

label {
  font-weight: 400;
  font-size: 13px;
  letter-spacing: .5px;
  margin-bottom: 4px; }
  label.require::after {
    content: '*';
    color: #ff4954;
    font-weight: 500;
    margin-left: 8px; }

.form-row .btn-block {
  height: 100%; }

select:invalid {
  color: red; }

.file-group {
  position: relative;
  overflow: hidden; }
  .file-group input[type="file"] {
    position: absolute;
    opacity: 0;
    z-index: -1;
    width: 20px; }
  .file-group .file-value {
    background-color: #fff; }

.file-group-inline {
  display: inline-block; }

[data-form="mailer"] .d-on-success {
  display: none; }

.input-group {
  border: 1px solid #eaeff4;
  border-radius: 2px;
  transition: all 0.3s ease-out; }
  .input-group.focus {
    border-color: #f1f2f3;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.045); }
  .input-group .form-control {
    box-shadow: none;
    border: none;
    border-radius: 0; }

.input-group-prepend,
.input-group-append {
  margin-left: 0;
  margin-right: 0; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    height: calc(100% + 2px);
    margin: -1px; }

/*
.input-group-prepend .input-group-text {
  padding-right: 0;
}
*/
.input-group-text {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0;
  padding-bottom: 0;
  background-color: #fff;
  border: none;
  color: #c9ccce;
  font-weight: 300;
  border-radius: 0;
  height: 100%; }
  .input-group-text .custom-control {
    margin-bottom: 0; }
  .input-group-text + .form-control {
    padding-left: 0; }

.input-group .form-control:not(:last-child),
.input-group-text:not(:last-child) {
  border-right: 0; }

.input-group .form-control:not(:first-child),
.input-group-text:not(:first-child) {
  border-left: 0; }

.input-group-prepend .btn {
  margin-right: 0; }

.input-group-append .btn {
  margin-left: 0; }

.input-group-prepend .input-group-text:last-child {
  padding-right: 0; }

.was-validated .input-group:valid, .input-group.is-valid {
  border-color: #3cd458; }
  .was-validated .input-group:valid .valid-feedback,
  .was-validated .input-group:valid .valid-tooltip,
  .was-validated .input-group:valid ~ .valid-feedback,
  .was-validated .input-group:valid ~ .valid-tooltip, .input-group.is-valid .valid-feedback,
  .input-group.is-valid .valid-tooltip,
  .input-group.is-valid ~ .valid-feedback,
  .input-group.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .input-group:invalid, .input-group.is-invalid {
  border-color: #ff4954; }
  .was-validated .input-group:invalid .invalid-feedback,
  .was-validated .input-group:invalid .invalid-tooltip,
  .was-validated .input-group:invalid ~ .invalid-feedback,
  .was-validated .input-group:invalid ~ .invalid-tooltip, .input-group.is-invalid .invalid-feedback,
  .input-group.is-invalid .invalid-tooltip,
  .input-group.is-invalid ~ .invalid-feedback,
  .input-group.is-invalid ~ .invalid-tooltip {
    display: block; }

.input-line .form-control {
  border-color: transparent;
  padding-left: 0;
  padding-right: 0;
  background-color: transparent;
  background-size: 0 1px, 100% 1px;
  background-repeat: no-repeat;
  background-position: center bottom, center calc(100%);
  background-image: linear-gradient(#96c7ff, #96c7ff), linear-gradient(#eaeff4, #eaeff4);
  background-image: linear-gradient(#96c7ff, #96c7ff), linear-gradient(var(--color-divider), var(--color-divider));
  transition: background 0.3s; }
  .input-line .form-control:focus, .input-line .form-control.focus {
    background-size: 100% 1px, 100% 1px;
    border-color: transparent;
    box-shadow: none; }
  .input-line .form-control:disabled, .input-line .form-control[readonly] {
    background-color: #fff;
    opacity: 0.7; }
  .input-line .form-control.is-valid {
    background-image: linear-gradient(#96c7ff, #96c7ff), linear-gradient(#3cd458, #3cd458); }
  .input-line .form-control.is-invalid {
    background-image: linear-gradient(#96c7ff, #96c7ff), linear-gradient(#ff4954, #ff4954); }

.input-line .input-group {
  border: none; }
  .input-line .input-group.focus {
    box-shadow: none; }

.input-line .input-group-prepend .btn,
.input-line .input-group-append .btn {
  height: 100%;
  margin: 0; }

.input-line .input-group-text {
  background-color: transparent; }

.input-line .input-group-prepend .input-group-text:last-child {
  padding-right: 0.75rem; }

.table-cart {
  border: 1px solid #eaeff4;
  border: 1px solid var(--color-divider);
  border-top: none; }
  .table-cart td {
    vertical-align: middle;
    border-top-color: #eaeff4;
    border-top-color: var(--color-divider);
    padding-left: 12px;
    padding-right: 12px; }
  .table-cart tr td:first-child {
    padding-left: 30px; }
  .table-cart tr td:last-child {
    padding-right: 30px;
    text-align: right; }
  .table-cart h5 {
    font-size: 1rem;
    margin-bottom: 0; }
  .table-cart p {
    font-size: 13px;
    color: #929daf;
    line-height: 1.4;
    margin-bottom: 0; }
  .table-cart img {
    max-height: 80px; }
  .table-cart label {
    font-size: 12px;
    display: inline-block;
    padding-right: 8px; }
  .table-cart input {
    max-width: 60px;
    display: inline-block; }
  .table-cart .item-remove {
    color: #929daf; }
    .table-cart .item-remove:hover {
      color: #ff4954; }
  .table-cart .price {
    font-weight: 100; }

.cart-price {
  border: 1px solid #eaeff4;
  border: 1px solid var(--color-divider);
  border-radius: 3px;
  margin-bottom: 1rem;
  padding: 20px;
  background-color: #fcfdfe; }
  .cart-price .flexbox p {
    margin-bottom: 4px; }
  .cart-price .flexbox strong {
    font-weight: 400;
    font-size: .9em; }

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh; }

.container-xs,
.container-sm {
  width: 100%;
  padding: 0 15px;
  margin: 0 auto; }

.container-xs {
  max-width: 540px; }

.container-sm {
  max-width: 640px; }

.main-container {
  display: flex;
  align-items: stretch;
  position: relative; }
  .main-container.container-fluid, .main-container.container-sm, .main-container.container-md, .main-container.container-lg, .main-container.container-xl {
    padding-left: 30px;
    padding-right: 30px; }

.main-content {
  flex-grow: 1; }

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  cursor: pointer; }

.layout-centered .main-content {
  display: flex;
  align-items: center;
  justify-content: space-around; }
  .layout-centered .main-content .section {
    width: 100%; }

.row.no-gap {
  margin-left: 0;
  margin-right: 0; }
  .row.no-gap > .col,
  .row.no-gap > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.row.gap-1 {
  margin-left: -4px;
  margin-right: -4px; }
  .row.gap-1 > .col,
  .row.gap-1 > [class*="col-"] {
    padding-left: 4px;
    padding-right: 4px; }

.row.gap-2 {
  margin-left: -8px;
  margin-right: -8px; }
  .row.gap-2 > .col,
  .row.gap-2 > [class*="col-"] {
    padding-left: 8px;
    padding-right: 8px; }

.row.gap-3 {
  margin-left: -15px;
  margin-right: -15px; }
  .row.gap-3 > .col,
  .row.gap-3 > [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px; }

.row.gap-4 {
  margin-left: -15px;
  margin-right: -15px; }
  .row.gap-4 > .col,
  .row.gap-4 > [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px; }

.row.gap-5 {
  margin-left: -15px;
  margin-right: -15px; }
  .row.gap-5 > .col,
  .row.gap-5 > [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px; }

.row.gap-y {
  margin-top: -15px;
  margin-bottom: -15px; }
  .row.gap-y > .col,
  .row.gap-y > [class*="col-"] {
    padding-top: 15px;
    padding-bottom: 15px; }
  .row.gap-y.gap-1 {
    margin-top: -4px;
    margin-bottom: -4px; }
    .row.gap-y.gap-1 > .col,
    .row.gap-y.gap-1 > [class*="col-"] {
      padding-top: 4px;
      padding-bottom: 4px; }
  .row.gap-y.gap-2 {
    margin-top: -8px;
    margin-bottom: -8px; }
    .row.gap-y.gap-2 > .col,
    .row.gap-y.gap-2 > [class*="col-"] {
      padding-top: 8px;
      padding-bottom: 8px; }
  .row.gap-y.gap-3 {
    margin-top: -15px;
    margin-bottom: -15px; }
    .row.gap-y.gap-3 > .col,
    .row.gap-y.gap-3 > [class*="col-"] {
      padding-top: 15px;
      padding-bottom: 15px; }
  .row.gap-y.gap-4 {
    margin-top: -15px;
    margin-bottom: -15px; }
    .row.gap-y.gap-4 > .col,
    .row.gap-y.gap-4 > [class*="col-"] {
      padding-top: 15px;
      padding-bottom: 15px; }
  .row.gap-y.gap-5 {
    margin-top: -15px;
    margin-bottom: -15px; }
    .row.gap-y.gap-5 > .col,
    .row.gap-y.gap-5 > [class*="col-"] {
      padding-top: 15px;
      padding-bottom: 15px; }

.flexbox {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .flexbox > * {
    display: inline-block;
    margin-left: 4px;
    margin-right: 4px; }
    .flexbox > *:first-child {
      margin-left: 0; }
    .flexbox > *:last-child {
      margin-right: 0; }
  .flexbox.no-gap > * {
    margin-left: 0;
    margin-right: 0; }

ul.flexbox {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0; }

.flexbox-vertical {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .flexbox-vertical.no-gap > * {
    margin-top: 0;
    margin-bottom: 0; }

.flex-grow-all > *,
.flex-cols-wide > *,
.flex-col-wide,
.flex-grow {
  flex: 1 1; }

.flex-grow-1 {
  flex-grow: 1; }

.flex-grow-2 {
  flex-grow: 2; }

.flex-grow-3 {
  flex-grow: 3; }

.flex-grow-4 {
  flex-grow: 4; }

.flex-grow-5 {
  flex-grow: 5; }

.flex-grow-6 {
  flex-grow: 6; }

.flex-grow-7 {
  flex-grow: 7; }

.flex-grow-8 {
  flex-grow: 8; }

.flex-grow-9 {
  flex-grow: 9; }

.flex-grow-0 {
  flex-grow: 0; }

.masonry-grid {
  -webkit-column-count: 3;
          column-count: 3;
  grid-column-gap: 30px;
  -webkit-column-gap: 30px;
          column-gap: 30px; }
  .masonry-grid.no-gap {
    grid-column-gap: 0;
    -webkit-column-gap: 0;
            column-gap: 0; }
    .masonry-grid.no-gap .masonry-item {
      padding-bottom: 0; }
  .masonry-grid.gap-1 {
    grid-column-gap: 8px;
    -webkit-column-gap: 8px;
            column-gap: 8px; }
    .masonry-grid.gap-1 .masonry-item {
      padding-bottom: 8px; }
  .masonry-grid.gap-2 {
    grid-column-gap: 16px;
    -webkit-column-gap: 16px;
            column-gap: 16px; }
    .masonry-grid.gap-2 .masonry-item {
      padding-bottom: 16px; }
  .masonry-grid.gap-3 {
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px; }
    .masonry-grid.gap-3 .masonry-item {
      padding-bottom: 30px; }
  .masonry-grid.gap-4 {
    grid-column-gap: 48px;
    -webkit-column-gap: 48px;
            column-gap: 48px; }
    .masonry-grid.gap-4 .masonry-item {
      padding-bottom: 48px; }
  .masonry-grid.gap-5 {
    grid-column-gap: 64px;
    -webkit-column-gap: 64px;
            column-gap: 64px; }
    .masonry-grid.gap-5 .masonry-item {
      padding-bottom: 64px; }

.masonry-cols-2 {
  -webkit-column-count: 2;
          column-count: 2; }

.masonry-cols-3 {
  -webkit-column-count: 3;
          column-count: 3; }

.masonry-cols-4 {
  -webkit-column-count: 4;
          column-count: 4; }

.masonry-cols-5 {
  -webkit-column-count: 5;
          column-count: 5; }

.masonry-item {
  display: block;
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
          break-inside: avoid;
  padding-bottom: 30px; }

.topbar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #f1f2f3;
  border-bottom: 1px solid var(--color-divider-light);
  height: 40px;
  z-index: 1001;
  display: none; }
  .topbar > .container,
  .topbar > .container-fluid,
  .topbar > .container-sm,
  .topbar > .container-md,
  .topbar > .container-lg,
  .topbar > .container-xl {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    height: inherit; }
  .topbar.text-white {
    border-bottom-color: rgba(255, 255, 255, 0.05); }

.topbar-divider {
  display: inline-block;
  height: 12px;
  width: 1px;
  background-color: rgba(127, 127, 127, 0.15);
  margin: 0 1rem;
  margin-bottom: 2px;
  vertical-align: middle; }

@media (min-width: 576px) {
  body:not(.body-scrolled) .topbar.d-sm-flex ~ .navbar {
    top: 40px; } }

@media (min-width: 768px) {
  body:not(.body-scrolled) .topbar.d-md-flex ~ .navbar {
    top: 40px; } }

@media (min-width: 992px) {
  body:not(.body-scrolled) .topbar.d-lg-flex ~ .navbar {
    top: 40px; } }

@media (min-width: 1200px) {
  body:not(.body-scrolled) .topbar.d-xl-flex ~ .navbar {
    top: 40px; } }

body:not(.body-scrolled) .topbar.d-flex ~ .navbar {
  top: 40px; }

.navbar {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: background-color .3s ease-out, -webkit-transform .3s ease-out;
  transition: transform .3s ease-out, background-color .3s ease-out;
  transition: transform .3s ease-out, background-color .3s ease-out, -webkit-transform .3s ease-out;
  padding-top: 0;
  padding-bottom: 0;
  min-height: 56px; }
  .modal-open .navbar {
    right: 16px; }
  .navbar .row {
    align-items: center;
    flex-grow: 1; }
  .navbar-scrolled .navbar {
    top: 0; }

.navbar-offset-0 {
  top: 0; }

.navbar-left {
  display: flex;
  align-items: center;
  flex-shrink: 0; }

[data-navbar="static"] {
  position: static;
  border-bottom: 1px solid #eaeff4;
  border-bottom: 1px solid var(--color-divider);
  top: auto; }

[data-navbar="fixed"] {
  position: fixed; }
  .navbar-scrolled [data-navbar="fixed"] {
    background-color: rgba(255, 255, 255, 0.99);
    box-shadow: 0 1px 9px rgba(0, 0, 0, 0.05); }
    .navbar-scrolled [data-navbar="fixed"] .d-stick-none {
      display: none !important; }
    .navbar-scrolled [data-navbar="fixed"] .d-stick-block {
      display: block !important; }
    .navbar-scrolled [data-navbar="fixed"] .d-stick-flex {
      display: flex !important; }
    .navbar-scrolled [data-navbar="fixed"] .d-stick-inline-flex {
      display: inline-flex !important; }
    .navbar-scrolled [data-navbar="fixed"] .d-stick-inline-block {
      display: inline-block !important; }

.body-scrolled [data-navbar="sticky"] {
  -webkit-transform: translate(0, -100%);
          transform: translate(0, -100%); }

.navbar-scrolled [data-navbar="sticky"] {
  position: fixed; }

.header-scrolled [data-navbar="sticky"] {
  top: 0;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
  background-color: rgba(255, 255, 255, 0.99);
  box-shadow: 0 1px 9px rgba(0, 0, 0, 0.05); }
  .header-scrolled [data-navbar="sticky"] .d-stick-none {
    display: none !important; }
  .header-scrolled [data-navbar="sticky"] .d-stick-block {
    display: block !important; }
  .header-scrolled [data-navbar="sticky"] .d-stick-flex {
    display: flex !important; }
  .header-scrolled [data-navbar="sticky"] .d-stick-inline-flex {
    display: inline-flex !important; }
  .header-scrolled [data-navbar="sticky"] .d-stick-inline-block {
    display: inline-block !important; }

.body-scrolled [data-navbar="smart"] {
  -webkit-transform: translate(0, -100%);
          transform: translate(0, -100%); }

.navbar-scrolled [data-navbar="smart"] {
  position: fixed; }

.header-scrolled [data-navbar="smart"] {
  background-color: rgba(255, 255, 255, 0.99); }
  .header-scrolled [data-navbar="smart"] .d-stick-none {
    display: none !important; }
  .header-scrolled [data-navbar="smart"] .d-stick-block {
    display: block !important; }
  .header-scrolled [data-navbar="smart"] .d-stick-flex {
    display: flex !important; }
  .header-scrolled [data-navbar="smart"] .d-stick-inline-flex {
    display: inline-flex !important; }
  .header-scrolled [data-navbar="smart"] .d-stick-inline-block {
    display: inline-block !important; }

[data-navbar="smart"].stick {
  top: 0;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
  box-shadow: 0 1px 9px rgba(0, 0, 0, 0.05); }

.navbar-brand {
  display: inline-flex;
  align-items: center; }
  .navbar-brand img {
    vertical-align: baseline; }

.navbar-divider {
  display: inline-block;
  height: 16px;
  width: 1px;
  background-color: #fff;
  margin: 0 1rem;
  opacity: 0.15; }

.navbar-toggler {
  margin-right: 1rem;
  border: none;
  cursor: pointer;
  font-size: 1.5rem; }

.nav-navbar {
  position: relative; }
  .nav-navbar .nav-item {
    position: relative; }
  .nav-navbar > .nav-item > .nav-link,
  .nav-navbar > .nav-link {
    line-height: 56px;
    padding-top: 0;
    padding-bottom: 0; }
  .nav-navbar .nav-link {
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.87em;
    word-spacing: 2px;
    padding-left: 1.125rem;
    padding-right: 1.125rem; }
  .nav-navbar .arrow {
    display: inline-block;
    width: 0;
    height: 0;
    margin-bottom: 2px;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent; }
  .nav-navbar .nav-item:hover > .nav {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  .nav-navbar .nav-item.nav-mega:hover .nav {
    opacity: 1;
    visibility: visible; }
  .nav-navbar.nav-text-normal .nav-link {
    text-transform: none; }

.nav-navbar .nav {
  position: absolute;
  flex-direction: column;
  min-width: 11rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #fff;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.04);
  border-radius: 3px;
  transition: 0.3s ease-out;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translate(0, -8px);
          transform: translate(0, -8px);
  z-index: 1; }
  .nav-navbar .nav.align-right {
    right: 0; }
  .nav-navbar .nav.columns-2 {
    flex-direction: row;
    min-width: 22rem; }
    .nav-navbar .nav.columns-2 > .nav-link,
    .nav-navbar .nav.columns-2 > .nav-item {
      flex-basis: 50%; }
  .nav-navbar .nav.columns-3 {
    flex-direction: row;
    min-width: 33rem; }
    .nav-navbar .nav.columns-3 > .nav-link,
    .nav-navbar .nav.columns-3 > .nav-item {
      flex-basis: 33.3333%; }
  .nav-navbar .nav .nav-link {
    font-weight: 400;
    text-transform: none;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    letter-spacing: 0.5px;
    color: rgba(117, 117, 117, 0.8); }
  .nav-navbar .nav .arrow {
    position: absolute;
    right: 1.25rem;
    top: 50%;
    margin-top: -2px;
    font-style: normal;
    border: none;
    opacity: 0.7;
    width: 5px;
    height: 5px;
    border-top: 1px solid rgba(117, 117, 117, 0.8);
    border-right: 1px solid rgba(117, 117, 117, 0.8);
    border-top-width: 1px !important;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .nav-navbar .nav .nav-item.show > .nav-link,
  .nav-navbar .nav .nav-item:hover > .nav-link,
  .nav-navbar .nav .nav-link.show,
  .nav-navbar .nav .nav-link:hover {
    color: #555555; }
    .nav-navbar .nav .nav-item.show > .nav-link .arrow,
    .nav-navbar .nav .nav-item:hover > .nav-link .arrow,
    .nav-navbar .nav .nav-link.show .arrow,
    .nav-navbar .nav .nav-link:hover .arrow {
      border-top-color: #555555;
      border-right-color: #555555; }
  .nav-navbar .nav .nav-item.active > .nav-link,
  .nav-navbar .nav .nav-link.active {
    font-weight: 600;
    color: #323d47; }
    .nav-navbar .nav .nav-item.active > .nav-link .arrow,
    .nav-navbar .nav .nav-link.active .arrow {
      border-top-color: #323d47;
      border-right-color: #323d47; }
  .nav-navbar .nav .nav {
    top: 0;
    left: 99%;
    -webkit-transform: translate(-8px, 0);
            transform: translate(-8px, 0); }
  .nav-navbar .nav.align-right .nav {
    left: auto;
    right: 99%;
    -webkit-transform: translate(8px, 0);
            transform: translate(8px, 0); }

.nav-navbar .nav-mega {
  position: static; }
  .nav-navbar .nav-mega .nav {
    left: 0;
    min-width: 100%; }
    .nav-navbar .nav-mega .nav .nav {
      position: static;
      background-color: transparent;
      padding: 0;
      box-shadow: none;
      -webkit-transform: none;
              transform: none; }
  .nav-navbar .nav-mega .row {
    align-items: flex-start; }

.navbar-mobile {
  display: flex;
  align-items: center;
  width: 100%; }

.backdrop-navbar {
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 1001; }

.navbar-open {
  overflow: hidden; }
  .navbar-open .navbar {
    box-shadow: none; }
    .navbar-open .navbar .logo-dark {
      display: inline-block; }
    .navbar-open .navbar .logo-light {
      display: none; }
    .navbar-open .navbar .navbar-toggler {
      color: rgba(117, 117, 117, 0.8); }
      .navbar-open .navbar .navbar-toggler:hover {
        color: #555555; }
    .navbar-open .navbar .navbar-divider {
      background-color: rgba(117, 117, 117, 0.8); }
    .navbar-open .navbar .nav-navbar > .nav-item > .nav-link,
    .navbar-open .navbar .nav-navbar > .nav-link {
      color: rgba(117, 117, 117, 0.8); }
      .navbar-open .navbar .nav-navbar > .nav-item > .nav-link > .arrow,
      .navbar-open .navbar .nav-navbar > .nav-link > .arrow {
        border-top: 3px solid rgba(117, 117, 117, 0.8); }
    .navbar-open .navbar .nav-navbar > .nav-item.show > .nav-link,
    .navbar-open .navbar .nav-navbar > .nav-item:hover > .nav-link,
    .navbar-open .navbar .nav-navbar > .nav-link.show,
    .navbar-open .navbar .nav-navbar > .nav-link:hover {
      color: #555555; }
      .navbar-open .navbar .nav-navbar > .nav-item.show > .nav-link > .arrow,
      .navbar-open .navbar .nav-navbar > .nav-item:hover > .nav-link > .arrow,
      .navbar-open .navbar .nav-navbar > .nav-link.show > .arrow,
      .navbar-open .navbar .nav-navbar > .nav-link:hover > .arrow {
        border-top-color: inherit; }
    .navbar-open .navbar .nav-navbar > .nav-item.active > .nav-link,
    .navbar-open .navbar .nav-navbar > .nav-item > .nav-link.active,
    .navbar-open .navbar .nav-navbar > .nav-link.active {
      color: #323d47; }
      .navbar-open .navbar .nav-navbar > .nav-item.active > .nav-link > .arrow,
      .navbar-open .navbar .nav-navbar > .nav-item > .nav-link.active > .arrow,
      .navbar-open .navbar .nav-navbar > .nav-link.active > .arrow {
        border-top-color: inherit; }
  .navbar-open .navbar-mobile {
    left: 0 !important;
    box-shadow: 2px 0 9px rgba(0, 0, 0, 0.05); }

@media (max-width: 575.98px) {
  .navbar-expand-sm .navbar-mobile {
    flex-direction: column;
    align-items: flex-start;
    width: 300px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: -300px;
    bottom: 0;
    padding: 20px;
    background-color: #fff;
    overflow-y: auto;
    overflow-x: hidden;
    transition: left 0.3s ease;
    z-index: 1002; }
    .navbar-expand-sm .navbar-mobile > * {
      padding-top: 10px;
      padding-bottom: 10px; }
  .navbar-expand-sm .navbar-divider {
    display: block;
    height: 1px;
    width: 100%;
    margin: 1rem 0;
    padding: 0; }
  .navbar-expand-sm .nav-navbar:not(.nav-inline) {
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%; }
    .navbar-expand-sm .nav-navbar:not(.nav-inline) > .nav-item > .nav-link,
    .navbar-expand-sm .nav-navbar:not(.nav-inline) > .nav-link {
      min-height: inherit;
      line-height: inherit;
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      font-size: 0.95rem;
      text-transform: none; }
    .navbar-expand-sm .nav-navbar:not(.nav-inline) .arrow {
      float: right;
      margin-top: 12px;
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg); }
    .navbar-expand-sm .nav-navbar:not(.nav-inline) .nav-divider {
      display: none; }
    .navbar-expand-sm .nav-navbar:not(.nav-inline) > .nav-item > .nav {
      display: none;
      border-left: 1px dashed rgba(0, 0, 0, 0.08); }
      .navbar-expand-sm .nav-navbar:not(.nav-inline) > .nav-item > .nav::before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 9px;
        left: -4px;
        bottom: 15px;
        border-left: 1px dashed rgba(0, 0, 0, 0.08);
        display: none; }
  .navbar-expand-sm .nav-navbar .nav {
    position: static;
    visibility: visible;
    opacity: 1;
    box-shadow: none;
    margin-left: 1.25rem;
    padding-top: 0;
    padding-bottom: 0;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
    .navbar-expand-sm .nav-navbar .nav.columns-2, .navbar-expand-sm .nav-navbar .nav.columns-3 {
      flex-direction: column;
      min-width: 100%; }
      .navbar-expand-sm .nav-navbar .nav.columns-2 > .nav-link,
      .navbar-expand-sm .nav-navbar .nav.columns-2 > .nav-item, .navbar-expand-sm .nav-navbar .nav.columns-3 > .nav-link,
      .navbar-expand-sm .nav-navbar .nav.columns-3 > .nav-item {
        flex-basis: 100%; }
    .navbar-expand-sm .nav-navbar .nav .nav-link {
      padding-top: 0.375rem;
      padding-bottom: 0.375rem;
      font-size: 0.875rem; }
  .navbar-expand-sm .d-mobile-none {
    display: none !important; }
  .navbar-expand-sm .d-mobile-block {
    display: block !important; }
  .navbar-expand-sm .d-mobile-flex {
    display: flex !important; }
  .navbar-expand-sm .d-mobile-inline-flex {
    display: inline-flex !important; }
  .navbar-expand-sm .d-mobile-inline-block {
    display: inline-block !important; } }

@media (min-width: 576px) {
  .navbar-expand-sm .backdrop-navbar {
    display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md .navbar-mobile {
    flex-direction: column;
    align-items: flex-start;
    width: 300px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: -300px;
    bottom: 0;
    padding: 20px;
    background-color: #fff;
    overflow-y: auto;
    overflow-x: hidden;
    transition: left 0.3s ease;
    z-index: 1002; }
    .navbar-expand-md .navbar-mobile > * {
      padding-top: 10px;
      padding-bottom: 10px; }
  .navbar-expand-md .navbar-divider {
    display: block;
    height: 1px;
    width: 100%;
    margin: 1rem 0;
    padding: 0; }
  .navbar-expand-md .nav-navbar:not(.nav-inline) {
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%; }
    .navbar-expand-md .nav-navbar:not(.nav-inline) > .nav-item > .nav-link,
    .navbar-expand-md .nav-navbar:not(.nav-inline) > .nav-link {
      min-height: inherit;
      line-height: inherit;
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      font-size: 0.95rem;
      text-transform: none; }
    .navbar-expand-md .nav-navbar:not(.nav-inline) .arrow {
      float: right;
      margin-top: 12px;
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg); }
    .navbar-expand-md .nav-navbar:not(.nav-inline) .nav-divider {
      display: none; }
    .navbar-expand-md .nav-navbar:not(.nav-inline) > .nav-item > .nav {
      display: none;
      border-left: 1px dashed rgba(0, 0, 0, 0.08); }
      .navbar-expand-md .nav-navbar:not(.nav-inline) > .nav-item > .nav::before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 9px;
        left: -4px;
        bottom: 15px;
        border-left: 1px dashed rgba(0, 0, 0, 0.08);
        display: none; }
  .navbar-expand-md .nav-navbar .nav {
    position: static;
    visibility: visible;
    opacity: 1;
    box-shadow: none;
    margin-left: 1.25rem;
    padding-top: 0;
    padding-bottom: 0;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
    .navbar-expand-md .nav-navbar .nav.columns-2, .navbar-expand-md .nav-navbar .nav.columns-3 {
      flex-direction: column;
      min-width: 100%; }
      .navbar-expand-md .nav-navbar .nav.columns-2 > .nav-link,
      .navbar-expand-md .nav-navbar .nav.columns-2 > .nav-item, .navbar-expand-md .nav-navbar .nav.columns-3 > .nav-link,
      .navbar-expand-md .nav-navbar .nav.columns-3 > .nav-item {
        flex-basis: 100%; }
    .navbar-expand-md .nav-navbar .nav .nav-link {
      padding-top: 0.375rem;
      padding-bottom: 0.375rem;
      font-size: 0.875rem; }
  .navbar-expand-md .d-mobile-none {
    display: none !important; }
  .navbar-expand-md .d-mobile-block {
    display: block !important; }
  .navbar-expand-md .d-mobile-flex {
    display: flex !important; }
  .navbar-expand-md .d-mobile-inline-flex {
    display: inline-flex !important; }
  .navbar-expand-md .d-mobile-inline-block {
    display: inline-block !important; } }

@media (min-width: 768px) {
  .navbar-expand-md .backdrop-navbar {
    display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg .navbar-mobile {
    flex-direction: column;
    align-items: flex-start;
    width: 300px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: -300px;
    bottom: 0;
    padding: 20px;
    background-color: #fff;
    overflow-y: auto;
    overflow-x: hidden;
    transition: left 0.3s ease;
    z-index: 1002; }
    .navbar-expand-lg .navbar-mobile > * {
      padding-top: 10px;
      padding-bottom: 10px; }
  .navbar-expand-lg .navbar-divider {
    display: block;
    height: 1px;
    width: 100%;
    margin: 1rem 0;
    padding: 0; }
  .navbar-expand-lg .nav-navbar:not(.nav-inline) {
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%; }
    .navbar-expand-lg .nav-navbar:not(.nav-inline) > .nav-item > .nav-link,
    .navbar-expand-lg .nav-navbar:not(.nav-inline) > .nav-link {
      min-height: inherit;
      line-height: inherit;
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      font-size: 0.95rem;
      text-transform: none; }
    .navbar-expand-lg .nav-navbar:not(.nav-inline) .arrow {
      float: right;
      margin-top: 12px;
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg); }
    .navbar-expand-lg .nav-navbar:not(.nav-inline) .nav-divider {
      display: none; }
    .navbar-expand-lg .nav-navbar:not(.nav-inline) > .nav-item > .nav {
      display: none;
      border-left: 1px dashed rgba(0, 0, 0, 0.08); }
      .navbar-expand-lg .nav-navbar:not(.nav-inline) > .nav-item > .nav::before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 9px;
        left: -4px;
        bottom: 15px;
        border-left: 1px dashed rgba(0, 0, 0, 0.08);
        display: none; }
  .navbar-expand-lg .nav-navbar .nav {
    position: static;
    visibility: visible;
    opacity: 1;
    box-shadow: none;
    margin-left: 1.25rem;
    padding-top: 0;
    padding-bottom: 0;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
    .navbar-expand-lg .nav-navbar .nav.columns-2, .navbar-expand-lg .nav-navbar .nav.columns-3 {
      flex-direction: column;
      min-width: 100%; }
      .navbar-expand-lg .nav-navbar .nav.columns-2 > .nav-link,
      .navbar-expand-lg .nav-navbar .nav.columns-2 > .nav-item, .navbar-expand-lg .nav-navbar .nav.columns-3 > .nav-link,
      .navbar-expand-lg .nav-navbar .nav.columns-3 > .nav-item {
        flex-basis: 100%; }
    .navbar-expand-lg .nav-navbar .nav .nav-link {
      padding-top: 0.375rem;
      padding-bottom: 0.375rem;
      font-size: 0.875rem; }
  .navbar-expand-lg .d-mobile-none {
    display: none !important; }
  .navbar-expand-lg .d-mobile-block {
    display: block !important; }
  .navbar-expand-lg .d-mobile-flex {
    display: flex !important; }
  .navbar-expand-lg .d-mobile-inline-flex {
    display: inline-flex !important; }
  .navbar-expand-lg .d-mobile-inline-block {
    display: inline-block !important; } }

@media (min-width: 992px) {
  .navbar-expand-lg .backdrop-navbar {
    display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl .navbar-mobile {
    flex-direction: column;
    align-items: flex-start;
    width: 300px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: -300px;
    bottom: 0;
    padding: 20px;
    background-color: #fff;
    overflow-y: auto;
    overflow-x: hidden;
    transition: left 0.3s ease;
    z-index: 1002; }
    .navbar-expand-xl .navbar-mobile > * {
      padding-top: 10px;
      padding-bottom: 10px; }
  .navbar-expand-xl .navbar-divider {
    display: block;
    height: 1px;
    width: 100%;
    margin: 1rem 0;
    padding: 0; }
  .navbar-expand-xl .nav-navbar:not(.nav-inline) {
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%; }
    .navbar-expand-xl .nav-navbar:not(.nav-inline) > .nav-item > .nav-link,
    .navbar-expand-xl .nav-navbar:not(.nav-inline) > .nav-link {
      min-height: inherit;
      line-height: inherit;
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      font-size: 0.95rem;
      text-transform: none; }
    .navbar-expand-xl .nav-navbar:not(.nav-inline) .arrow {
      float: right;
      margin-top: 12px;
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg); }
    .navbar-expand-xl .nav-navbar:not(.nav-inline) .nav-divider {
      display: none; }
    .navbar-expand-xl .nav-navbar:not(.nav-inline) > .nav-item > .nav {
      display: none;
      border-left: 1px dashed rgba(0, 0, 0, 0.08); }
      .navbar-expand-xl .nav-navbar:not(.nav-inline) > .nav-item > .nav::before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 9px;
        left: -4px;
        bottom: 15px;
        border-left: 1px dashed rgba(0, 0, 0, 0.08);
        display: none; }
  .navbar-expand-xl .nav-navbar .nav {
    position: static;
    visibility: visible;
    opacity: 1;
    box-shadow: none;
    margin-left: 1.25rem;
    padding-top: 0;
    padding-bottom: 0;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
    .navbar-expand-xl .nav-navbar .nav.columns-2, .navbar-expand-xl .nav-navbar .nav.columns-3 {
      flex-direction: column;
      min-width: 100%; }
      .navbar-expand-xl .nav-navbar .nav.columns-2 > .nav-link,
      .navbar-expand-xl .nav-navbar .nav.columns-2 > .nav-item, .navbar-expand-xl .nav-navbar .nav.columns-3 > .nav-link,
      .navbar-expand-xl .nav-navbar .nav.columns-3 > .nav-item {
        flex-basis: 100%; }
    .navbar-expand-xl .nav-navbar .nav .nav-link {
      padding-top: 0.375rem;
      padding-bottom: 0.375rem;
      font-size: 0.875rem; }
  .navbar-expand-xl .d-mobile-none {
    display: none !important; }
  .navbar-expand-xl .d-mobile-block {
    display: block !important; }
  .navbar-expand-xl .d-mobile-flex {
    display: flex !important; }
  .navbar-expand-xl .d-mobile-inline-flex {
    display: inline-flex !important; }
  .navbar-expand-xl .d-mobile-inline-block {
    display: inline-block !important; } }

@media (min-width: 1200px) {
  .navbar-expand-xl .backdrop-navbar {
    display: none; } }

.navbar-expand .navbar-mobile {
  flex-direction: column;
  align-items: flex-start;
  width: 300px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: -300px;
  bottom: 0;
  padding: 20px;
  background-color: #fff;
  overflow-y: auto;
  overflow-x: hidden;
  transition: left 0.3s ease;
  z-index: 1002; }
  .navbar-expand .navbar-mobile > * {
    padding-top: 10px;
    padding-bottom: 10px; }

.navbar-expand .navbar-divider {
  display: block;
  height: 1px;
  width: 100%;
  margin: 1rem 0;
  padding: 0; }

.navbar-expand .nav-navbar:not(.nav-inline) {
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%; }
  .navbar-expand .nav-navbar:not(.nav-inline) > .nav-item > .nav-link,
  .navbar-expand .nav-navbar:not(.nav-inline) > .nav-link {
    min-height: inherit;
    line-height: inherit;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 0.95rem;
    text-transform: none; }
  .navbar-expand .nav-navbar:not(.nav-inline) .arrow {
    float: right;
    margin-top: 12px;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }
  .navbar-expand .nav-navbar:not(.nav-inline) .nav-divider {
    display: none; }
  .navbar-expand .nav-navbar:not(.nav-inline) > .nav-item > .nav {
    display: none;
    border-left: 1px dashed rgba(0, 0, 0, 0.08); }
    .navbar-expand .nav-navbar:not(.nav-inline) > .nav-item > .nav::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 9px;
      left: -4px;
      bottom: 15px;
      border-left: 1px dashed rgba(0, 0, 0, 0.08);
      display: none; }

.navbar-expand .nav-navbar .nav {
  position: static;
  visibility: visible;
  opacity: 1;
  box-shadow: none;
  margin-left: 1.25rem;
  padding-top: 0;
  padding-bottom: 0;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0); }
  .navbar-expand .nav-navbar .nav.columns-2, .navbar-expand .nav-navbar .nav.columns-3 {
    flex-direction: column;
    min-width: 100%; }
    .navbar-expand .nav-navbar .nav.columns-2 > .nav-link,
    .navbar-expand .nav-navbar .nav.columns-2 > .nav-item, .navbar-expand .nav-navbar .nav.columns-3 > .nav-link,
    .navbar-expand .nav-navbar .nav.columns-3 > .nav-item {
      flex-basis: 100%; }
  .navbar-expand .nav-navbar .nav .nav-link {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    font-size: 0.875rem; }

.navbar-expand .d-mobile-none {
  display: none !important; }

.navbar-expand .d-mobile-block {
  display: block !important; }

.navbar-expand .d-mobile-flex {
  display: flex !important; }

.navbar-expand .d-mobile-inline-flex {
  display: inline-flex !important; }

.navbar-expand .d-mobile-inline-block {
  display: inline-block !important; }

.navbar-expand .backdrop-navbar {
  display: none; }

.navbar-light .logo-dark,
.navbar-stick-light.stick .logo-dark {
  display: none; }

.navbar-light .logo-light,
.navbar-stick-light.stick .logo-light {
  display: inline-block; }

.navbar-light .navbar-toggler,
.navbar-stick-light.stick .navbar-toggler {
  color: rgba(255, 255, 255, 0.65); }
  .navbar-light .navbar-toggler:hover,
  .navbar-stick-light.stick .navbar-toggler:hover {
    color: #fff; }

.navbar-light .navbar-divider,
.navbar-stick-light.stick .navbar-divider {
  background-color: rgba(255, 255, 255, 0.65); }

.navbar-light .nav-navbar > .nav-item > .nav-link,
.navbar-light .nav-navbar > .nav-link,
.navbar-stick-light.stick .nav-navbar > .nav-item > .nav-link,
.navbar-stick-light.stick .nav-navbar > .nav-link {
  color: rgba(255, 255, 255, 0.65); }
  .navbar-light .nav-navbar > .nav-item > .nav-link > .arrow,
  .navbar-light .nav-navbar > .nav-link > .arrow,
  .navbar-stick-light.stick .nav-navbar > .nav-item > .nav-link > .arrow,
  .navbar-stick-light.stick .nav-navbar > .nav-link > .arrow {
    border-top: 3px solid rgba(255, 255, 255, 0.65); }

.navbar-light .nav-navbar > .nav-item.show > .nav-link,
.navbar-light .nav-navbar > .nav-item:hover > .nav-link,
.navbar-light .nav-navbar > .nav-link.show,
.navbar-light .nav-navbar > .nav-link:hover,
.navbar-stick-light.stick .nav-navbar > .nav-item.show > .nav-link,
.navbar-stick-light.stick .nav-navbar > .nav-item:hover > .nav-link,
.navbar-stick-light.stick .nav-navbar > .nav-link.show,
.navbar-stick-light.stick .nav-navbar > .nav-link:hover {
  color: #fff; }
  .navbar-light .nav-navbar > .nav-item.show > .nav-link > .arrow,
  .navbar-light .nav-navbar > .nav-item:hover > .nav-link > .arrow,
  .navbar-light .nav-navbar > .nav-link.show > .arrow,
  .navbar-light .nav-navbar > .nav-link:hover > .arrow,
  .navbar-stick-light.stick .nav-navbar > .nav-item.show > .nav-link > .arrow,
  .navbar-stick-light.stick .nav-navbar > .nav-item:hover > .nav-link > .arrow,
  .navbar-stick-light.stick .nav-navbar > .nav-link.show > .arrow,
  .navbar-stick-light.stick .nav-navbar > .nav-link:hover > .arrow {
    border-top-color: inherit; }

.navbar-light .nav-navbar > .nav-item.active > .nav-link,
.navbar-light .nav-navbar > .nav-item > .nav-link.active,
.navbar-light .nav-navbar > .nav-link.active,
.navbar-stick-light.stick .nav-navbar > .nav-item.active > .nav-link,
.navbar-stick-light.stick .nav-navbar > .nav-item > .nav-link.active,
.navbar-stick-light.stick .nav-navbar > .nav-link.active {
  color: #fff; }
  .navbar-light .nav-navbar > .nav-item.active > .nav-link > .arrow,
  .navbar-light .nav-navbar > .nav-item > .nav-link.active > .arrow,
  .navbar-light .nav-navbar > .nav-link.active > .arrow,
  .navbar-stick-light.stick .nav-navbar > .nav-item.active > .nav-link > .arrow,
  .navbar-stick-light.stick .nav-navbar > .nav-item > .nav-link.active > .arrow,
  .navbar-stick-light.stick .nav-navbar > .nav-link.active > .arrow {
    border-top-color: inherit; }

.navbar-dark .logo-dark,
.navbar-stick-dark.stick .logo-dark {
  display: inline-block; }

.navbar-dark .logo-light,
.navbar-stick-dark.stick .logo-light {
  display: none; }

.navbar-dark .navbar-toggler,
.navbar-stick-dark.stick .navbar-toggler {
  color: rgba(117, 117, 117, 0.8); }
  .navbar-dark .navbar-toggler:hover,
  .navbar-stick-dark.stick .navbar-toggler:hover {
    color: #555555; }

.navbar-dark .navbar-divider,
.navbar-stick-dark.stick .navbar-divider {
  background-color: rgba(117, 117, 117, 0.8); }

.navbar-dark .nav-navbar > .nav-item > .nav-link,
.navbar-dark .nav-navbar > .nav-link,
.navbar-stick-dark.stick .nav-navbar > .nav-item > .nav-link,
.navbar-stick-dark.stick .nav-navbar > .nav-link {
  color: rgba(117, 117, 117, 0.8); }
  .navbar-dark .nav-navbar > .nav-item > .nav-link > .arrow,
  .navbar-dark .nav-navbar > .nav-link > .arrow,
  .navbar-stick-dark.stick .nav-navbar > .nav-item > .nav-link > .arrow,
  .navbar-stick-dark.stick .nav-navbar > .nav-link > .arrow {
    border-top: 3px solid rgba(117, 117, 117, 0.8); }

.navbar-dark .nav-navbar > .nav-item.show > .nav-link,
.navbar-dark .nav-navbar > .nav-item:hover > .nav-link,
.navbar-dark .nav-navbar > .nav-link.show,
.navbar-dark .nav-navbar > .nav-link:hover,
.navbar-stick-dark.stick .nav-navbar > .nav-item.show > .nav-link,
.navbar-stick-dark.stick .nav-navbar > .nav-item:hover > .nav-link,
.navbar-stick-dark.stick .nav-navbar > .nav-link.show,
.navbar-stick-dark.stick .nav-navbar > .nav-link:hover {
  color: #555555; }
  .navbar-dark .nav-navbar > .nav-item.show > .nav-link > .arrow,
  .navbar-dark .nav-navbar > .nav-item:hover > .nav-link > .arrow,
  .navbar-dark .nav-navbar > .nav-link.show > .arrow,
  .navbar-dark .nav-navbar > .nav-link:hover > .arrow,
  .navbar-stick-dark.stick .nav-navbar > .nav-item.show > .nav-link > .arrow,
  .navbar-stick-dark.stick .nav-navbar > .nav-item:hover > .nav-link > .arrow,
  .navbar-stick-dark.stick .nav-navbar > .nav-link.show > .arrow,
  .navbar-stick-dark.stick .nav-navbar > .nav-link:hover > .arrow {
    border-top-color: inherit; }

.navbar-dark .nav-navbar > .nav-item.active > .nav-link,
.navbar-dark .nav-navbar > .nav-item > .nav-link.active,
.navbar-dark .nav-navbar > .nav-link.active,
.navbar-stick-dark.stick .nav-navbar > .nav-item.active > .nav-link,
.navbar-stick-dark.stick .nav-navbar > .nav-item > .nav-link.active,
.navbar-stick-dark.stick .nav-navbar > .nav-link.active {
  color: #323d47; }
  .navbar-dark .nav-navbar > .nav-item.active > .nav-link > .arrow,
  .navbar-dark .nav-navbar > .nav-item > .nav-link.active > .arrow,
  .navbar-dark .nav-navbar > .nav-link.active > .arrow,
  .navbar-stick-dark.stick .nav-navbar > .nav-item.active > .nav-link > .arrow,
  .navbar-stick-dark.stick .nav-navbar > .nav-item > .nav-link.active > .arrow,
  .navbar-stick-dark.stick .nav-navbar > .nav-link.active > .arrow {
    border-top-color: inherit; }

/*
.navbar {
  min-height: 90px;
}
.nav-navbar > .nav-item > .nav-link {
  line-height: 90px;
}
.nav-navbar > .nav-link {
  line-height: 90px;
}
*/
.offcanvas {
  position: relative;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1.5rem;
  background-color: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
  z-index: 1010;
  visibility: hidden; }
  .offcanvas > .container,
  .offcanvas > .container-fluid,
  .offcanvas > .container-sm,
  .offcanvas > .container-md,
  .offcanvas > .container-lg,
  .offcanvas > .container-xl {
    position: relative;
    height: 100%; }
  .offcanvas[data-animation] {
    transition: 0.4s ease-out; }
  .offcanvas[data-animation="fade"] {
    opacity: 0; }
  .offcanvas[data-animation="slide-up"] {
    opacity: 1;
    -webkit-transform: translate(0, 100%);
            transform: translate(0, 100%); }
  .offcanvas[data-animation="slide-down"] {
    -webkit-transform: translate(0, -100%);
            transform: translate(0, -100%); }
  .offcanvas[data-animation="slide-left"] {
    left: auto;
    right: 0;
    -webkit-transform: translate(100%, 0);
            transform: translate(100%, 0); }
  .offcanvas[data-animation="slide-right"] {
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0); }
  .offcanvas.show {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  .offcanvas .close {
    float: none;
    padding: 0.75rem 1rem;
    font-weight: 100;
    font-size: 2.5rem;
    transition: color 0.3s ease-out; }
  .offcanvas .close:not(.position-static) {
    position: absolute;
    top: 0;
    right: 0; }
  .offcanvas.bg-img {
    position: fixed; }

.backdrop-offcanvas {
  z-index: 1009; }

.popup {
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  padding: 1.5rem;
  background-color: #fff;
  border: 1px solid #f1f2f3;
  border: 1px solid var(--color-divider-light);
  border-radius: 0.25rem;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
  transition: 0.4s ease-out;
  z-index: 1010;
  visibility: hidden;
  opacity: 0; }
  .popup.show {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translate(0, 0) !important;
            transform: translate(0, 0) !important; }
    .popup.show[data-position$="-center"] {
      -webkit-transform: translate(-50%, 0) !important;
              transform: translate(-50%, 0) !important; }
  .popup[data-position="top-left"] {
    right: auto;
    bottom: auto;
    top: 2rem;
    left: 2rem; }
  .popup[data-position="top-right"] {
    bottom: auto;
    top: 2rem; }
  .popup[data-position="bottom-left"] {
    right: auto;
    left: 2rem; }
  .popup[data-position="top-center"] {
    right: auto;
    bottom: auto;
    top: 2rem;
    left: 50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%); }
  .popup[data-position="bottom-center"] {
    right: auto;
    left: 50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%); }
  .popup[data-animation="slide-up"] {
    -webkit-transform: translate(0, 100%);
            transform: translate(0, 100%); }
    .popup[data-animation="slide-up"][data-position$="-center"] {
      -webkit-transform: translate(-50%, 100%);
              transform: translate(-50%, 100%); }
  .popup[data-animation="slide-down"] {
    -webkit-transform: translate(0, -100%);
            transform: translate(0, -100%); }
    .popup[data-animation="slide-down"][data-position$="-center"] {
      -webkit-transform: translate(-50%, -100%);
              transform: translate(-50%, -100%); }
  .popup[data-animation="slide-left"] {
    -webkit-transform: translate(100%, 0);
            transform: translate(100%, 0); }
  .popup[data-animation="slide-right"] {
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0); }
  .popup .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1rem;
    font-weight: 100;
    transition: 0.3s ease-out; }

.header {
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 146px;
  padding-bottom: 90px; }
  .header > .container,
  .header > .container-fluid,
  .header > .container-sm,
  .header > .container-md,
  .header > .container-lg,
  .header > .container-xl {
    position: relative;
    height: 100%; }
  .header.h-fullscreen {
    padding-top: 90px; }

.section {
  position: relative;
  border-bottom: none;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 7rem;
  padding-bottom: 7rem; }
  .section > .container,
  .section > .container-fluid,
  .section > .container-sm,
  .section > .container-md,
  .section > .container-lg,
  .section > .container-xl,
  .section > .container-wide {
    position: relative;
    height: 100%; }
  @media (max-width: 991.98px) {
    .section {
      padding-top: 5rem;
      padding-bottom: 5rem; } }

.section-header {
  text-align: center;
  max-width: 70%;
  margin: 0 auto 70px; }
  .section-header small {
    display: inline-block;
    font-size: 0.6875rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.75px;
    margin-bottom: 1.5rem;
    word-spacing: 2px;
    color: rgba(153, 153, 153, 0.6); }
  .section-header hr {
    width: 50px;
    margin-top: 2.5rem;
    margin-bottom: 1.5rem; }
  .section-header p {
    font-size: 1.0625rem; }
    .section-header p.lead {
      font-size: 1.125rem; }
  @media (max-width: 991.98px) {
    .section-header {
      max-width: 90%; } }

/*
.section[data-parallax] {
  background-color: transparent;
}


.parallax-slider {
  animation: fadein 2s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
*/
.section-video {
  overflow: hidden;
  padding-top: 0;
  padding-bottom: 0; }
  .section-video > .container,
  .section-video > .container-fluid,
  .section-video > .container-sm,
  .section-video > .container-md,
  .section-video > .container-lg,
  .section-video > .container-xl,
  .section-video > .container-wide {
    padding-top: 90px;
    padding-bottom: 90px; }

.section-dialog {
  background-color: #fff;
  padding: 1rem;
  border-radius: 4px; }
  @media (min-width: 768px) {
    .section-dialog {
      padding: 20px 24px; } }
  @media (min-width: 992px) {
    .section-dialog {
      padding: 30px 32px; } }

.sidebar {
  display: flex;
  flex-direction: column;
  padding-top: 7rem;
  padding-bottom: 7rem; }
  .sidebar hr {
    margin-top: 30px;
    margin-bottom: 30px;
    border-top-style: solid; }
  .sidebar .nav-sidebar {
    height: 100%; }
  @media (max-width: 991.98px) {
    .sidebar {
      padding-top: 5rem;
      padding-bottom: 5rem; } }

.sidebar-fixed {
  position: fixed;
  top: 0;
  left: -240px;
  bottom: 0;
  width: 240px;
  background-color: #fff;
  border-right: 1px solid #f1f2f3;
  border-right: 1px solid var(--color-divider-light);
  z-index: 1000;
  transition: left 0.3s ease-out; }
  .sidebar-open .sidebar-fixed {
    left: 0;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.05); }

.sidebar-sticky.stick:not(.is-mobile-wide) {
  position: fixed;
  top: 0; }

.sidebar-sticky.is-mobile-wide {
  padding-bottom: 0 !important; }

.sidebar-header {
  text-align: center;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #f1f2f3;
  border-bottom: 1px solid var(--color-divider-light); }

.sidebar-title {
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: .85em;
  margin-bottom: 1rem; }

.sidebar-body {
  position: relative;
  padding-right: 20px; }

.sidebar-footer {
  text-align: center;
  padding: 20px 0;
  margin-top: 30px;
  border-top: 1px solid #f1f2f3;
  border-top: 1px solid var(--color-divider-light); }

.sidebar-toggler {
  padding: .25rem .75rem;
  font-size: 1.25rem;
  line-height: 1;
  cursor: pointer;
  background: 0 0;
  border: none;
  border-radius: .25rem;
  margin-right: 0.5rem;
  margin-bottom: -1px; }

.backdrop-sidebar {
  z-index: 999; }

@media (min-width: 576px) {
  .sidebar-fixed.sidebar-expand-sm {
    position: fixed;
    left: 0;
    background-color: #f5f6f7;
    box-shadow: 3px 0 5px rgba(0, 0, 0, 0.015); }
    .sidebar-fixed.sidebar-expand-sm ~ .main-content {
      margin-left: 240px; } }

@media (min-width: 768px) {
  .sidebar-fixed.sidebar-expand-md {
    position: fixed;
    left: 0;
    background-color: #f5f6f7;
    box-shadow: 3px 0 5px rgba(0, 0, 0, 0.015); }
    .sidebar-fixed.sidebar-expand-md ~ .main-content {
      margin-left: 240px; } }

@media (min-width: 992px) {
  .sidebar-fixed.sidebar-expand-lg {
    position: fixed;
    left: 0;
    background-color: #f5f6f7;
    box-shadow: 3px 0 5px rgba(0, 0, 0, 0.015); }
    .sidebar-fixed.sidebar-expand-lg ~ .main-content {
      margin-left: 240px; } }

@media (min-width: 1200px) {
  .sidebar-fixed.sidebar-expand-xl {
    position: fixed;
    left: 0;
    background-color: #f5f6f7;
    box-shadow: 3px 0 5px rgba(0, 0, 0, 0.015); }
    .sidebar-fixed.sidebar-expand-xl ~ .main-content {
      margin-left: 240px; } }

.sidebar-fixed.sidebar-expand {
  position: fixed;
  left: 0;
  background-color: #f5f6f7;
  box-shadow: 3px 0 5px rgba(0, 0, 0, 0.015); }
  .sidebar-fixed.sidebar-expand ~ .main-content {
    margin-left: 240px; }

[data-sticky="true"].stick:not(.navbar) {
  position: fixed;
  top: 0; }

.bt-1, .border-top-1 {
  border-top: 1px solid #f1f2f3;
  border-top: 1px solid var(--color-divider-light); }

.bl-1, .border-left-1 {
  border-left: 1px solid #f1f2f3;
  border-left: 1px solid var(--color-divider-light); }

.br-1, .border-right-1 {
  border-right: 1px solid #f1f2f3;
  border-right: 1px solid var(--color-divider-light); }

.bb-1, .border-bottom-1 {
  border-bottom: 1px solid #f1f2f3;
  border-bottom: 1px solid var(--color-divider-light); }

.bt-2, .border-top-2 {
  border-top: 2px solid #f1f2f3;
  border-top: 2px solid var(--color-divider-light); }

.bl-2, .border-left-2 {
  border-left: 2px solid #f1f2f3;
  border-left: 2px solid var(--color-divider-light); }

.br-2, .border-right-2 {
  border-right: 2px solid #f1f2f3;
  border-right: 2px solid var(--color-divider-light); }

.bb-2, .border-bottom-2 {
  border-bottom: 2px solid #f1f2f3;
  border-bottom: 2px solid var(--color-divider-light); }

.bt-3, .border-top-3 {
  border-top: 3px solid #f1f2f3;
  border-top: 3px solid var(--color-divider-light); }

.bl-3, .border-left-3 {
  border-left: 3px solid #f1f2f3;
  border-left: 3px solid var(--color-divider-light); }

.br-3, .border-right-3 {
  border-right: 3px solid #f1f2f3;
  border-right: 3px solid var(--color-divider-light); }

.bb-3, .border-bottom-3 {
  border-bottom: 3px solid #f1f2f3;
  border-bottom: 3px solid var(--color-divider-light); }

.bt-0, .border-top-0 {
  border-top-width: 0; }

.bl-0, .border-left-0 {
  border-left-width: 0; }

.br-0, .border-right-0 {
  border-right-width: 0; }

.bb-0, .border-bottom-0 {
  border-bottom-width: 0; }

.b-1 {
  border: 1px solid #f1f2f3;
  border: 1px solid var(--color-divider-light); }

.b-2 {
  border: 2px solid #f1f2f3;
  border: 2px solid var(--color-divider-light); }

.b-3 {
  border: 3px solid #f1f2f3;
  border: 3px solid var(--color-divider-light); }

.b-0 {
  border-width: 0; }

.rounded-md {
  border-radius: 0.5rem !important; }

.rounded-lg {
  border-radius: 0.75rem !important; }

.rounded-xl {
  border-radius: 1rem !important; }

.border-glass,
.border-glass-1 {
  border-color: rgba(255, 255, 255, 0.35) !important; }

.border-glass-2 {
  border-color: rgba(255, 255, 255, 0.25) !important; }

.border-glass-3 {
  border-color: rgba(255, 255, 255, 0.15) !important; }

.bg-gray {
  background-color: #fafbfb; }

.bg-pale-primary {
  background-color: pale-color(#50a1ff) !important; }

.bg-pale-secondary {
  background-color: #f0f4f7 !important; }

.bg-pale-success {
  background-color: pale-color(#3cd458) !important; }

.bg-pale-danger {
  background-color: pale-color(#ff4954) !important; }

.bg-pale-warning {
  background-color: pale-color(#ffba00) !important; }

.bg-pale-info {
  background-color: pale-color(#926dde) !important; }

.bg-pale-light {
  background-color: #ffffff !important; }

.bg-pale-dark {
  background-color: #e5e5e5 !important; }

.bg-gradient-primary {
  background-image: linear-gradient(45deg, #50a1ff 0%, color-level(#50a1ff, -4) 100%) !important; }

.bg-gradient-secondary {
  background-image: linear-gradient(45deg, #e9ecf0 0%, color-level(#e9ecf0, -4) 100%) !important; }

.bg-gradient-success {
  background-image: linear-gradient(45deg, #3cd458 0%, color-level(#3cd458, -4) 100%) !important; }

.bg-gradient-danger {
  background-image: linear-gradient(45deg, #ff4954 0%, color-level(#ff4954, -4) 100%) !important; }

.bg-gradient-warning {
  background-image: linear-gradient(45deg, #ffba00 0%, color-level(#ffba00, -4) 100%) !important; }

.bg-gradient-info {
  background-image: linear-gradient(45deg, #926dde 0%, color-level(#926dde, -4) 100%) !important; }

.bg-gradient-dark {
  background-image: linear-gradient(45deg, #191919 0%, color-level(#191919, -4) 100%) !important; }

.text-inherit {
  color: inherit; }

.bg-img {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }

.bg-fixed {
  background-attachment: fixed; }

@media (max-width: 640px) {
  .bg-fixed {
    background-attachment: initial; } }

.bg-repeat {
  background-repeat: repeat;
  background-size: auto; }

.bg-img-left {
  background-position: left center; }

.bg-img-right {
  background-position: right center; }

.bg-img-top {
  background-position: center top; }

.bg-img-bottom {
  background-position: center bottom; }

.bg-size-contain {
  background-size: contain; }

.opacity-0 {
  opacity: 0; }

.opacity-5 {
  opacity: 0.05 !important; }

.opacity-10 {
  opacity: 0.1 !important; }

.opacity-15 {
  opacity: 0.15 !important; }

.opacity-20 {
  opacity: 0.2 !important; }

.opacity-25 {
  opacity: 0.25 !important; }

.opacity-30 {
  opacity: 0.3 !important; }

.opacity-35 {
  opacity: 0.35 !important; }

.opacity-40 {
  opacity: 0.4 !important; }

.opacity-45 {
  opacity: 0.45 !important; }

.opacity-50 {
  opacity: 0.5 !important; }

.opacity-55 {
  opacity: 0.55 !important; }

.opacity-60 {
  opacity: 0.6 !important; }

.opacity-65 {
  opacity: 0.65 !important; }

.opacity-70 {
  opacity: 0.7 !important; }

.opacity-75 {
  opacity: 0.75 !important; }

.opacity-80 {
  opacity: 0.8 !important; }

.opacity-85 {
  opacity: 0.85 !important; }

.opacity-90 {
  opacity: 0.9 !important; }

.opacity-95 {
  opacity: 0.95 !important; }

.opacity-100 {
  opacity: 1 !important; }

.shadow-0, .hover-shadow-0:hover {
  box-shadow: none; }

.shadow-1, .hover-shadow-1:hover {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.06); }

.shadow-2, .hover-shadow-2:hover {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.06); }

.shadow-3, .hover-shadow-3:hover {
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.06); }

.shadow-4, .hover-shadow-4:hover {
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.06); }

.shadow-5, .hover-shadow-5:hover {
  box-shadow: 0 0 26px rgba(0, 0, 0, 0.06); }

.shadow-6, .hover-shadow-6:hover {
  box-shadow: 0 0 36px rgba(0, 0, 0, 0.06); }

.shadow-7, .hover-shadow-7:hover {
  box-shadow: 0 0 48px rgba(0, 0, 0, 0.06); }

.shadow-8, .hover-shadow-8:hover {
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.06); }

.shadow-9, .hover-shadow-9:hover {
  box-shadow: 0 0 74px rgba(0, 0, 0, 0.06); }

.overflow-hidden {
  overflow: hidden; }

.transition {
  transition: 0.3s ease-out; }

.cursor-pointer {
  cursor: pointer; }

.hover-move-up {
  display: block;
  transition: 0.2s ease-out; }
  .hover-move-up:hover {
    -webkit-transform: translateY(-0.5rem);
            transform: translateY(-0.5rem); }

.fit-cover {
  object-fit: cover; }

.fill-parent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%; }

.text-hue-rotate {
  color: #f35626;
  background-image: -webkit-linear-gradient(92deg, #f35626, #feab3a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-animation: hue-rotate 60s infinite linear; }

.bg-hue-rotate {
  -webkit-animation: hue-rotate 30s linear infinite; }

@-webkit-keyframes hue {
  from {
    -webkit-filter: hue-rotate(0deg); }
  to {
    -webkit-filter: hue-rotate(-360deg); } }

.flex-grow-0 {
  flex-grow: 0; }

.w-125 {
  width: 125px !important; }

.h-125 {
  height: 125px !important; }

.mw-125 {
  min-width: 125px !important; }

.mh-125 {
  min-height: 125px !important; }

.w-150 {
  width: 150px !important; }

.h-150 {
  height: 150px !important; }

.mw-150 {
  min-width: 150px !important; }

.mh-150 {
  min-height: 150px !important; }

.w-175 {
  width: 175px !important; }

.h-175 {
  height: 175px !important; }

.mw-175 {
  min-width: 175px !important; }

.mh-175 {
  min-height: 175px !important; }

.w-200 {
  width: 200px !important; }

.h-200 {
  height: 200px !important; }

.mw-200 {
  min-width: 200px !important; }

.mh-200 {
  min-height: 200px !important; }

.w-250 {
  width: 250px !important; }

.h-250 {
  height: 250px !important; }

.mw-250 {
  min-width: 250px !important; }

.mh-250 {
  min-height: 250px !important; }

.w-300 {
  width: 300px !important; }

.h-300 {
  height: 300px !important; }

.mw-300 {
  min-width: 300px !important; }

.mh-300 {
  min-height: 300px !important; }

.w-350 {
  width: 350px !important; }

.h-350 {
  height: 350px !important; }

.mw-350 {
  min-width: 350px !important; }

.mh-350 {
  min-height: 350px !important; }

.w-400 {
  width: 400px !important; }

.h-400 {
  height: 400px !important; }

.mw-400 {
  min-width: 400px !important; }

.mh-400 {
  min-height: 400px !important; }

.w-500 {
  width: 500px !important; }

.h-500 {
  height: 500px !important; }

.mw-500 {
  min-width: 500px !important; }

.mh-500 {
  min-height: 500px !important; }

.w-600 {
  width: 600px !important; }

.h-600 {
  height: 600px !important; }

.mw-600 {
  min-width: 600px !important; }

.mh-600 {
  min-height: 600px !important; }

.w-700 {
  width: 700px !important; }

.h-700 {
  height: 700px !important; }

.mw-700 {
  min-width: 700px !important; }

.mh-700 {
  min-height: 700px !important; }

.w-800 {
  width: 800px !important; }

.h-800 {
  height: 800px !important; }

.mw-800 {
  min-width: 800px !important; }

.mh-800 {
  min-height: 800px !important; }

.w-900 {
  width: 900px !important; }

.h-900 {
  height: 900px !important; }

.mw-900 {
  min-width: 900px !important; }

.mh-900 {
  min-height: 900px !important; }

.w-5px {
  width: 5px !important; }

.h-5px {
  height: 5px !important; }

.w-10px {
  width: 10px !important; }

.h-10px {
  height: 10px !important; }

.w-15px {
  width: 15px !important; }

.h-15px {
  height: 15px !important; }

.w-20px {
  width: 20px !important; }

.h-20px {
  height: 20px !important; }

.w-25px {
  width: 25px !important; }

.h-25px {
  height: 25px !important; }

.w-30px {
  width: 30px !important; }

.h-30px {
  height: 30px !important; }

.w-35px {
  width: 35px !important; }

.h-35px {
  height: 35px !important; }

.w-40px {
  width: 40px !important; }

.h-40px {
  height: 40px !important; }

.w-45px {
  width: 45px !important; }

.h-45px {
  height: 45px !important; }

.w-50px {
  width: 50px !important; }

.h-50px {
  height: 50px !important; }

.w-55px {
  width: 55px !important; }

.h-55px {
  height: 55px !important; }

.w-60px {
  width: 60px !important; }

.h-60px {
  height: 60px !important; }

.w-65px {
  width: 65px !important; }

.h-65px {
  height: 65px !important; }

.w-70px {
  width: 70px !important; }

.h-70px {
  height: 70px !important; }

.w-75px {
  width: 75px !important; }

.h-75px {
  height: 75px !important; }

.w-80px {
  width: 80px !important; }

.h-80px {
  height: 80px !important; }

.w-85px {
  width: 85px !important; }

.h-85px {
  height: 85px !important; }

.w-90px {
  width: 90px !important; }

.h-90px {
  height: 90px !important; }

.w-95px {
  width: 95px !important; }

.h-95px {
  height: 95px !important; }

.w-100px {
  width: 100px !important; }

.h-100px {
  height: 100px !important; }

.h-auto {
  height: auto; }

.w-auto {
  width: auto; }

.w-fullscreen, .w-100vw {
  width: 100vw !important; }

.h-fullscreen, .h-100vh {
  height: 100vh !important; }

.mw-fullscreen, .mw-100vw {
  min-width: 100vw !important; }

.mh-fullscreen, .mh-100vh {
  min-height: 100vh !important; }

@media (max-height: 640px) {
  .h-fullscreen {
    height: auto !important; } }

.gap-x-1 > * {
  display: inline-block;
  margin-left: 2px;
  margin-right: 2px; }
  .gap-x-1 > *:first-child {
    margin-left: 0; }
  .gap-x-1 > *:last-child {
    margin-right: 0; }

.gap-x-2 > * {
  display: inline-block;
  margin-left: 4px;
  margin-right: 4px; }
  .gap-x-2 > *:first-child {
    margin-left: 0; }
  .gap-x-2 > *:last-child {
    margin-right: 0; }

.gap-x > *,
.gap-x-3 > * {
  display: inline-block;
  margin-left: 8px;
  margin-right: 8px; }
  .gap-x > *:first-child,
  .gap-x-3 > *:first-child {
    margin-left: 0; }
  .gap-x > *:last-child,
  .gap-x-3 > *:last-child {
    margin-right: 0; }

.gap-x-4 > * {
  display: inline-block;
  margin-left: 12px;
  margin-right: 12px; }
  .gap-x-4 > *:first-child {
    margin-left: 0; }
  .gap-x-4 > *:last-child {
    margin-right: 0; }

.gap-x-5 > * {
  display: inline-block;
  margin-left: 16px;
  margin-right: 16px; }
  .gap-x-5 > *:first-child {
    margin-left: 0; }
  .gap-x-5 > *:last-child {
    margin-right: 0; }

.gap-x-6 > * {
  display: inline-block;
  margin-left: 24px;
  margin-right: 24px; }
  .gap-x-6 > *:first-child {
    margin-left: 0; }
  .gap-x-6 > *:last-child {
    margin-right: 0; }

.gap-x-7 > * {
  display: inline-block;
  margin-left: 32px;
  margin-right: 32px; }
  .gap-x-7 > *:first-child {
    margin-left: 0; }
  .gap-x-7 > *:last-child {
    margin-right: 0; }

.gap-xy-1 {
  margin: -2px; }
  .gap-xy-1 > * {
    display: inline-block;
    margin: 2px; }

.gap-xy-2 {
  margin: -4px; }
  .gap-xy-2 > * {
    display: inline-block;
    margin: 4px; }

.gap-xy,
.gap-xy-3 {
  margin: -8px; }
  .gap-xy > *,
  .gap-xy-3 > * {
    display: inline-block;
    margin: 8px; }

.gap-xy-4 {
  margin: -12px; }
  .gap-xy-4 > * {
    display: inline-block;
    margin: 12px; }

.gap-xy-5 {
  margin: -16px; }
  .gap-xy-5 > * {
    display: inline-block;
    margin: 16px; }

.gap-xy-6 {
  margin: -24px; }
  .gap-xy-6 > * {
    display: inline-block;
    margin: 24px; }

.gap-xy-7 {
  margin: -32px; }
  .gap-xy-7 > * {
    display: inline-block;
    margin: 32px; }

.fs-6,
.font-size-6 {
  font-size: 6px !important; }

.fs-7,
.font-size-7 {
  font-size: 7px !important; }

.fs-8,
.font-size-8 {
  font-size: 8px !important; }

.fs-9,
.font-size-9 {
  font-size: 9px !important; }

.fs-10,
.font-size-10 {
  font-size: 10px !important; }

.fs-11,
.font-size-11 {
  font-size: 11px !important; }

.fs-12,
.font-size-12 {
  font-size: 12px !important; }

.fs-13,
.font-size-13 {
  font-size: 13px !important; }

.fs-14,
.font-size-14 {
  font-size: 14px !important; }

.fs-15,
.font-size-15 {
  font-size: 15px !important; }

.fs-16,
.font-size-16 {
  font-size: 16px !important; }

.fs-17,
.font-size-17 {
  font-size: 17px !important; }

.fs-18,
.font-size-18 {
  font-size: 18px !important; }

.fs-19,
.font-size-19 {
  font-size: 19px !important; }

.fs-20,
.font-size-20 {
  font-size: 20px !important; }

.fw-100,
.font-weight-100 {
  font-weight: 100 !important; }

.fw-200,
.font-weight-200 {
  font-weight: 200 !important; }

.fw-300,
.font-weight-300 {
  font-weight: 300 !important; }

.fw-400,
.font-weight-400 {
  font-weight: 400 !important; }

.fw-500,
.font-weight-500 {
  font-weight: 500 !important; }

.fw-600,
.font-weight-600 {
  font-weight: 600 !important; }

.fw-700,
.font-weight-700 {
  font-weight: 700 !important; }

.fw-800,
.font-weight-800 {
  font-weight: 800 !important; }

.fw-900,
.font-weight-900 {
  font-weight: 900 !important; }

.card-outline {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.3); }

.card-hover-inverse:hover {
  background-color: #191919;
  border-color: #191919; }

.text-white {
  color: rgba(255, 255, 255, 0.85) !important; }
  .text-white h1, .text-white h2, .text-white h3, .text-white h4, .text-white h5, .text-white h6,
  .text-white .h1, .text-white .h2, .text-white .h3, .text-white .h4, .text-white .h5, .text-white .h6 {
    color: #fff; }
  .text-white a:not(.btn):not(.dropdown-item) {
    color: rgba(255, 255, 255, 0.8); }
  .text-white hr {
    border-top-color: rgba(255, 255, 255, 0.15); }
  .text-white p {
    color: rgba(255, 255, 255, 0.85); }
  .text-white code {
    background-color: rgba(255, 255, 255, 0.1);
    color: #eee; }
  .text-white small,
  .text-white .small {
    color: rgba(255, 255, 255, 0.8); }
  .text-white .divider {
    color: rgba(255, 255, 255, 0.5); }
    .text-white .divider::before, .text-white .divider::after {
      border-top-color: rgba(255, 255, 255, 0.15); }
  .text-white .section-header small {
    color: rgba(255, 255, 255, 0.6); }
  .text-white .rating label.empty {
    color: rgba(255, 255, 255, 0.3); }
  .text-white .card-inverse {
    background-color: rgba(255, 255, 255, 0.1); }
  .text-white .btn-outline-light {
    color: rgba(255, 255, 255, 0.6);
    border-color: rgba(255, 255, 255, 0.3); }
    .text-white .btn-outline-light:hover {
      color: #757575; }
  .text-white .close {
    color: rgba(255, 255, 255, 0.85); }

.square {
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  padding-bottom: 100%; }
  .square .lazy {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center; }

.favicon-check-circle {
  color: #3cd458 !important;
  color: var(--green) !important;
  font-size: 50px;
  margin-right: 5px; }

.order-table img {
  max-height: 80px; }

.border-none .product-border {
  border: none !important; }

.discount-icon {
  height: 24px !important;
  width: auto;
  margin-top: -11px;
  margin-right: 3px !important; }

.discount-icon-container {
  position: absolute;
  bottom: -16px;
  left: -16px; }

.filter-box {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  margin-bottom: 30px;
  padding: 10px;
  border: 1px solid #bebebe;
  border-radius: 5px;
  background-color: #fff; }
  .filter-box .card-body {
    padding: 0; }
  .filter-box.accordion-arrow-right .card-title a {
    padding-left: 0;
    padding-right: 0; }
    .filter-box.accordion-arrow-right .card-title a::before {
      float: right;
      margin-left: 0;
      margin-right: 3px;
      margin-top: 10px; }
  .filter-box.accordion .card-title {
    padding: 10px; }
  .filter-box.accordion .card {
    margin-bottom: 0;
    background-color: #fff;
    border: none !important; }
    .filter-box.accordion .card:last-child {
      margin-bottom: 0; }
  .filter-box.accordion .card-title a {
    display: block;
    padding: 0;
    color: var(--color-secondary); }
    .filter-box.accordion .card-title a::before {
      display: none; }
    .filter-box.accordion .card-title a.collapsed::before {
      display: none; }
    .filter-box.accordion .card-title a::after {
      content: "+";
      color: #979797;
      margin-top: 4.5px;
      font-size: 1.5em;
      font-weight: 300;
      line-height: 0.5em;
      float: right;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
      transition: -webkit-transform 0.3s ease-in-out;
      transition: transform 0.3s ease-in-out;
      transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out; }
    .filter-box.accordion .card-title a.collapsed::after {
      -webkit-transform: rotate(-180deg);
              transform: rotate(-180deg); }
  .filter-box.accordion .card-item-form {
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;
    background-color: inherit; }
  .filter-box.accordion .card-item {
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;
    background-color: inherit; }
    .filter-box.accordion .card-item.no-active {
      background-color: inherit !important; }
      .filter-box.accordion .card-item.no-active::after {
        content: none !important; }
    .filter-box.accordion .card-item.active {
      background-color: #ebebeb; }
      .filter-box.accordion .card-item.active::after {
        content: '';
        float: right;
        display: inline-block;
        width: 6px;
        height: 9px;
        margin-top: 3px;
        margin-right: 5px;
        border-right: 1px solid #a5a5a5;
        border-bottom: 1px solid #a5a5a5;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg); }
  .filter-box .searchString .icon-close {
    cursor: pointer;
    font-size: 24px;
    font-weight: 300;
    font-family: serif; }
  .filter-box .filter-title {
    color: #1a1a1a;
    color: var(--color-dark);
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.3px;
    font-family: 'Visby', sans-serif;
    font-family: var(--font-family-title);
    line-height: 22px; }
  .filter-box .filter-content {
    line-height: 19px;
    color: #3e3e3e;
    font-family: 'Visby', sans-serif;
    font-family: var(--font-family-title);
    font-size: 16px; }
  .filter-box .filter-category-number {
    color: #3e3e3e; }
  .filter-box .custom-radio .custom-control-label {
    line-height: 24px; }
    .filter-box .custom-radio .custom-control-label::after {
      background-color: var(--color-secondary); }
  .filter-box .custom-checkbox .custom-control-label::after {
    top: 50%;
    left: 7px;
    margin-top: -6px;
    width: 6px;
    height: 10px;
    border: solid var(--color-secondary);
    border-width: 0 1.4px 1.4px 0;
    -webkit-transform: scale(0) rotate(35deg);
    transform: scale(0) rotate(35deg); }
  .filter-box .custom-checkbox .custom-control-label::before {
    height: 20px;
    width: 20px;
    border: 1px solid #979797;
    border-radius: 3px;
    background-color: #ffffff; }

.bg-jumbotron {
  background-color: #bfbfbf !important; }

.transportation .charges {
  border: 2px solid #919191;
  border: 2px solid var(--grey);
  padding: 15px 20px;
  cursor: pointer;
  margin-bottom: 30px; }
  .transportation .charges:hover {
    border: 2px solid #1c97d3;
    border: 2px solid var(--blue); }
  .transportation .charges.selected-delivery {
    border: 2px solid #1c97d3 !important;
    border: 2px solid var(--blue) !important; }
  .transportation .charges .deli-charges {
    color: #222222;
    font-size: 14px;
    font-weight: 600;
    float: right;
    width: auto;
    border-left: 1px solid #e5e5e5;
    padding-left: 3px; }
  .transportation .charges h6 {
    margin: 0 5px 0 0;
    font-weight: 600;
    display: inline-block;
    font-size: 16px;
    color: #555555;
    cursor: pointer; }
  .transportation .charges span {
    cursor: pointer;
    color: #888888; }
  .transportation .charges label {
    cursor: pointer; }

.payment-product-box {
  padding: 10px 10px; }
  .payment-product-box .description {
    line-height: 14px; }
  .payment-product-box .price {
    padding-left: 10px;
    font-weight: 600;
    border-left: 1px solid var(--color-secondary); }

.payment-product-adjustments {
  font-size: 20px;
  margin-top: 10px; }

.stripe-form {
  width: 100% !important; }

.card-errors, .info-errors {
  color: #fa6e6e;
  color: var(--red); }

.StripeElement {
  height: 45px;
  padding: 12.5px 12px;
  background-color: #ffffff !important;
  background-color: var(--color-bg-body) !important;
  border: 1px solid #c5c5c5 !important;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  transition: box-shadow 150ms ease; }

.StripeElement--focus {
  border: 1px solid var(--color-primary) !important;
  box-shadow: 0 1px 3px 0 #cfd7df; }

.StripeElement--invalid {
  border-color: #fa755a; }

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important; }

.table-cart.table-cart-dropdown {
  border: none; }

.table-cart-dropdown {
  border-top: none; }

.cart-dropdown-scale {
  width: 450px;
  z-index: 1060;
  z-index: var(--zindex-select-dropdown); }
  @media (max-width: 991.98px) {
    .cart-dropdown-scale {
      width: 290px; } }

.cart-dropdown-empty {
  width: 180px; }

.table-cart.table-cart-dropdown tr td {
  padding: 10px; }

.nav-widget {
  width: 100%; }

.padding-r-0 {
  padding-right: 0; }

.fa-shopping-cart {
  pointer-events: none; }

.search-bar {
  -webkit-transform: scale(0.7) !important;
          transform: scale(0.7) !important; }

.btn-search {
  background-color: #ffffff;
  background-color: var(--white); }

.product-detail-brand {
  font-size: 20px;
  font-weight: 600; }

.product-detail-brand-link {
  max-height: 36px;
  margin-left: 30px; }

.saleInformation {
  margin-bottom: 20px;
  width: 100%;
  color: #919191;
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-base);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.26px;
  line-height: 19px;
  text-align: left;
  transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out; }
  .saleInformation span {
    margin-left: 5px; }

.product-detail {
  text-align: left; }
  .product-detail h1 {
    font-size: 1.875rem;
    opacity: .9;
    letter-spacing: -0.6px; }
  .product-detail h2 {
    font-size: 1rem;
    font-family: 'Open Sans', sans-serif;
    font-family: var(--font-family-base);
    opacity: .9;
    letter-spacing: -0.34px;
    line-height: 22px; }
    .product-detail h2.value {
      color: #a5a5a5 !important;
      color: var(--color-light-grey) !important; }
  .product-detail .tag-links a {
    font-size: .75rem;
    opacity: .9;
    line-height: 17px;
    letter-spacing: -.26px;
    color: #a5a5a5;
    color: var(--color-light-grey); }
  .product-detail-description {
    font-size: 1.125rem;
    opacity: .9;
    letter-spacing: -.39px;
    line-height: 24px; }

.product-terms-conditions {
  font-size: 0.875rem;
  color: #a5a5a5;
  color: var(--color-light-grey);
  letter-spacing: -0.3px;
  line-height: 19px;
  opacity: 0.9; }

#add-to-cart-button {
  min-width: 166px; }

.variant-panel .btn {
  padding: 8px 15px;
  font-size: 14px;
  letter-spacing: 1.75px;
  line-height: 20px;
  text-transform: none;
  color: #ffffff;
  color: var(--white);
  font-family: brandon-grotesque, sans-serif;
  font-family: var(--font-family-special); }
  .variant-panel .btn-round {
    border-radius: 6px !important;
    box-shadow: 0 2px 0 0 #d7d7d7 !important; }

.variant-panel .btn-outline-secondary {
  border-color: #4a4a4a !important;
  color: #4a4a4a; }
  .variant-panel .btn-outline-secondary:hover {
    color: #fff; }

.variant-panel .active {
  background-color: #4a4a4a !important;
  color: #fff !important; }

.variant-panel .quantity {
  font-size: 1rem;
  letter-spacing: -0.34px;
  line-height: 22px;
  font-weight: 400; }
  .variant-panel .quantity input {
    display: inline;
    width: 100px;
    height: 45px;
    border-radius: 4px;
    margin: 0;
    padding-left: 20px;
    border: 1px solid #eeeeee;
    background-color: #eeeeee;
    box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.07); }
  .variant-panel .quantity input[type=number]::-webkit-inner-spin-button,
  .variant-panel .quantity input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    /*opacity: 1;*/ }
  .variant-panel .quantity input[type=number] {
    -moz-appearance: textfield; }
  .variant-panel .quantity input:focus {
    outline: 0; }
  .variant-panel .quantity .quantity-nav {
    display: inline;
    position: absolute;
    margin-top: 3px;
    margin-left: -24px;
    height: 38px; }
  .variant-panel .quantity .quantity-button {
    position: relative;
    cursor: pointer;
    border-left: 1px solid #eeeeee;
    width: 20px;
    text-align: center;
    font-size: 9px;
    font-family: "Trebuchet MS", Helvetica, sans-serif !important;
    line-height: 6px;
    padding-top: 6px;
    padding-bottom: 6px;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
    background: grey;
    color: white; }
    .variant-panel .quantity .quantity-button.quantity-down {
      position: absolute;
      bottom: -1px;
      height: 50%;
      border-radius: 0 0 4px 4px;
      border: 1px solid rgba(237, 237, 237, 0.15); }
    .variant-panel .quantity .quantity-button.quantity-up {
      position: absolute;
      height: 50%;
      top: 0;
      border-radius: 4px 4px 0 0;
      border: 1px solid rgba(237, 237, 237, 0.15); }

.variant-panel .price strike {
  font-size: 14px;
  color: grey; }

.variant-panel .price-lg {
  color: var(--color-primary);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-base);
  font-size: 2.625rem;
  font-weight: 600;
  letter-spacing: -0.9px;
  line-height: 57px; }

.variant-panel .price-crossed {
  color: #919191;
  color: var(--grey);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-base);
  font-size: 1.5rem !important;
  font-weight: 600;
  letter-spacing: -0.77px;
  line-height: 33px;
  opacity: 1;
  margin-left: 15px !important;
  text-decoration: line-through; }

.purchase-action-block .btn {
  padding: 10px 32.65px; }

.email-to-us i {
  margin-right: 5px;
  color: #1c97d3;
  color: var(--blue); }

.email-to-us a {
  color: #1c97d3;
  color: var(--blue); }

.w-250 {
  width: 250px; }

.sku {
  opacity: 0.9;
  color: #a5a5a5;
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-base);
  font-size: 12px;
  letter-spacing: -0.26px;
  line-height: 17px; }

.shop-loading-icon {
  position: absolute;
  z-index: 1029;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10rem; }
  .shop-loading-icon img {
    z-index: 1030;
    height: 65px;
    width: auto;
    box-shadow: 0 0 30rem 10rem rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    position: fixed;
    bottom: 35%; }

.list-view.recommend-item {
  height: auto; }
  .list-view.recommend-item .list-view-image .square {
    width: 120px;
    padding-bottom: 120px; }
    .list-view.recommend-item .list-view-image .square img {
      margin: auto;
      max-height: 120px;
      width: auto; }
  @media (max-width: 576px) {
    .list-view.recommend-item {
      padding: 5px 5px 0 0; } }
  .list-view.recommend-item .price {
    min-width: auto; }
  .list-view.recommend-item .badge-danger {
    z-index: 1029;
    top: -13px;
    left: -13px; }
    @media (max-width: 576px) {
      .list-view.recommend-item .badge-danger {
        top: -5px;
        left: 0; } }
  .list-view.recommend-item .recommend-item-title {
    line-height: 30px;
    padding-top: 4px; }

#discount-offer {
  margin-top: 10px;
  width: 100%;
  color: #050505;
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-base);
  font-size: 16px;
  letter-spacing: -0.34px;
  line-height: 24px;
  flex-wrap: wrap;
  max-width: 100%; }
  #discount-offer span {
    cursor: pointer;
    line-height: inherit;
    font-size: 24px;
    font-weight: bold;
    color: var(--color-primary); }

.spinner-purchasable {
  color: #ffffff;
  color: var(--white); }

.disabled-overlay {
  background-color: rgba(255, 255, 255, 0.2);
  width: 100%;
  position: absolute;
  cursor: wait; }

.product-detail .slider-dots-line .slick-dots {
  flex-wrap: wrap; }
  .product-detail .slider-dots-line .slick-dots .slick-active button {
    width: 30px;
    background-color: var(--color-primary); }
  .product-detail .slider-dots-line .slick-dots li button {
    background-color: #979797;
    background-color: var(--color-grey);
    width: 30px;
    height: 5px; }

.order-table td {
  vertical-align: middle; }

.table-cart thead {
  border-top: 1px solid #f1f2f3; }
  .table-cart thead th {
    border-right: 1px solid #f1f2f3; }

.table-cart .price {
  min-width: 120px !important; }

.table-cart .product-title {
  min-width: 250px; }
  .table-cart .product-title a {
    font-size: 14px;
    letter-spacing: 0; }

.table-cart img {
  min-width: 50px; }

.table-cart .input-number {
  min-width: 50px; }

.error-message {
  position: absolute;
  font-weight: 600;
  color: #af0016 !important; }

.error-message-coupon {
  color: #af0016; }

.stripe-charge-form {
  width: 90% !important; }
  @media (max-width: 576px) {
    .stripe-charge-form {
      width: 100% !important; } }

.pagination .page-item {
  text-align: center;
  max-width: 40px; }
  .pagination .page-item p {
    width: 32px;
    line-height: 32px;
    vertical-align: center;
    text-align: center; }
    .pagination .page-item p.page-link {
      cursor: default; }
  .pagination .page-item input {
    height: 32px;
    background-image: linear-gradient(var(--color-primary), var(--color-primary)), linear-gradient(#888888, #888888); }

.filter-no-result-container {
  max-height: 100vh;
  height: 100%;
  display: flex; }
  .filter-no-result-container p {
    font-size: 20px; }

.noUi-connect {
  background: var(--color-primary); }

.longTextHide {
  color: inherit; }
  .longTextHide .special-overlay {
    width: 100%;
    height: 50%;
    position: absolute;
    bottom: 0;
    left: 0; }
  .longTextHide .rich-text {
    color: inherit;
    transition: all 2s ease-in-out;
    position: relative; }
    .longTextHide .rich-text.hide-some {
      max-height: 150px;
      overflow-y: hidden;
      transition: all 2s ease-in-out; }
      .longTextHide .rich-text.hide-some:before {
        transition: all 2s ease-in-out;
        top: 20%;
        bottom: 0;
        opacity: .9;
        background: #fff;
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        border-radius: inherit;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #f1f1f1 100%);
        box-sizing: border-box;
        margin: 0;
        padding: 0; }
  .longTextHide .hide-some-button {
    font-size: 14px;
    z-index: 900;
    cursor: pointer;
    color: var(--color-primary);
    text-align: right;
    transition: 2s;
    -webkit-transition: 2s; }
    .longTextHide .hide-some-button i {
      color: var(--color-primary); }
  .longTextHide .show-more-button {
    font-size: 14px;
    z-index: 900;
    text-align: right;
    cursor: pointer;
    color: var(--color-primary);
    transition: 2s;
    -webkit-transition: 2s; }
    .longTextHide .show-more-button i {
      color: var(--color-primary); }

.recommend-item {
  padding: 13px;
  width: 100% !important;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border: 1px solid #bebebe;
  border-radius: 5px; }
  .recommend-item .divider-dashed {
    height: 2px;
    width: 100%;
    display: block; }
    .recommend-item .divider-dashed:after {
      content: "";
      position: absolute;
      top: 75px;
      left: 0px;
      width: 100%;
      height: 1px;
      background: none;
      border-top: 0.5px solid var(--color-primary); }
  .recommend-item .badge-danger {
    position: absolute;
    top: -13px;
    left: -13px;
    margin: 0;
    font-family: brandon-grotesque, sans-serif;
    font-family: var(--font-family-special);
    font-weight: 900;
    letter-spacing: -0.23px;
    line-height: 18px; }
  .recommend-item-title {
    color: #050505;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.34px;
    line-height: 18px; }
  .recommend-item-sub-title {
    min-height: 36.36px;
    color: #000000;
    font-family: 'Open Sans', sans-serif;
    font-family: var(--font-family-base);
    font-size: 1rem;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    line-height: 19px; }
  .recommend-item .image {
    margin: 20px auto auto auto;
    width: 50%;
    min-width: 100px;
    float: left;
    height: auto;
    min-height: 100px; }
  .recommend-item .price {
    min-height: 100px;
    min-width: 106px;
    width: 50%;
    float: right; }
    .recommend-item .price p {
      margin-bottom: 6px;
      color: #919191;
      font-size: 12px;
      letter-spacing: -0.23px;
      line-height: 12px; }
    .recommend-item .price .price-lg {
      color: #1a1a1a;
      color: var(--color-dark);
      font-weight: bold;
      letter-spacing: -0.6px;
      font-size: 1.5rem;
      line-height: 1.5rem; }
      @media (max-width: 1050px) and (min-width: 992px) {
        .recommend-item .price .price-lg {
          font-size: 1.4rem;
          line-height: 1.4rem; } }
      @media (max-width: 850px) and (min-width: 768px) {
        .recommend-item .price .price-lg {
          font-size: 1.4rem;
          line-height: 1.4rem; } }
    .recommend-item .price .price-md {
      color: #979797;
      color: var(--color-grey);
      color: #000000;
      font-size: 1rem;
      font-weight: 600;
      letter-spacing: -0.3px;
      line-height: 1rem; }
      @media (max-width: 1050px) and (min-width: 992px) {
        .recommend-item .price .price-md {
          font-size: 0.8rem;
          line-height: 0.8rem; } }
      @media (max-width: 850px) and (min-width: 768px) {
        .recommend-item .price .price-md {
          font-size: 0.8rem;
          line-height: 0.8rem; } }
      .recommend-item .price .price-md:hover {
        color: #1a1a1a;
        color: var(--color-dark);
        -webkit-transition: all 200ms ease-in;
        -webkit-transform: scale(1.5) translate(8px);
        -ms-transition: all 200ms ease-in;
        -ms-transform: scale(1.5) translate(8px);
        -moz-transition: all 200ms ease-in;
        -moz-transform: scale(1.5) translate(8px);
        transition: all 200ms ease-in;
        transform: scale(1.5) translate(8px); }
    .recommend-item .price .price-lowest p {
      cursor: pointer;
      font-size: 0.8rem;
      font-weight: 600;
      letter-spacing: -0.3px;
      line-height: 0.8rem;
      margin-bottom: 2px;
      margin-right: -30px; }
    .recommend-item .price .price-lowest span {
      cursor: pointer;
      font-weight: 600;
      letter-spacing: -0.3px;
      margin-right: -20px;
      line-height: 1.3rem;
      font-size: 1.3rem; }
    .recommend-item .price .price-lowest i {
      color: #fa6e6e;
      color: var(--color-danger);
      cursor: pointer; }
    @media (max-width: 1050px) and (min-width: 992px) {
      .recommend-item .price .price-lowest span {
        font-size: 1rem;
        line-height: 1.3rem; } }
    @media (max-width: 850px) and (min-width: 768px) {
      .recommend-item .price .price-lowest span {
        font-size: 1rem;
        line-height: 1.3rem; } }
  .recommend-item .label-description {
    font-size: 12px !important; }
  .recommend-item .price span {
    font-size: 1rem !important; }
  .recommend-item .details .add-to-cart {
    line-height: 28px;
    font-weight: bold;
    letter-spacing: 1.5px;
    margin-top: 22px;
    text-align: center;
    position: relative;
    z-index: 2;
    cursor: pointer; }
  .recommend-item .details .error-message {
    position: absolute;
    font-weight: 700;
    color: #af0016;
    z-index: 3;
    margin-top: -19px;
    width: 100%;
    left: 0; }
  .recommend-item .add-to-cart-list i {
    font-size: 1rem;
    letter-spacing: 5px;
    line-height: 21px; }
  .recommend-item .add-to-cart-list .fa-ban {
    color: #999999;
    color: var(--color-text-light); }
  .recommend-item .add-to-cart-list .fa-chevron-up {
    color: var(--color-primary); }
  .recommend-item .add-to-cart-list span {
    font-weight: 600;
    color: var(--color-primary);
    font-size: 1rem; }
  .recommend-item .wish-list-icon {
    font-size: 1.56rem;
    color: #fa6e6e;
    color: var(--color-danger); }
    .recommend-item .wish-list-icon.fa-heart-o {
      color: #333333; }
  .recommend-item .price-description {
    white-space: nowrap; }
  .recommend-item .title-section {
    min-height: 77px; }

.products-slider .title {
  font-family: brandon-grotesque, sans-serif;
  font-family: var(--font-family-special);
  font-size: 28px;
  font-weight: 600;
  color: #1a1a1a;
  color: var(--black);
  letter-spacing: 6.5px;
  position: relative; }
  @media (max-width: 991.98px) {
    .products-slider .title {
      font-size: 20px;
      letter-spacing: 3px; } }
  .products-slider .title:before {
    content: '';
    position: absolute;
    top: 20px;
    left: 130px;
    background: var(--color-primary);
    width: 250px;
    height: 4px;
    border-radius: 2px; }
    @media (max-width: 1199.98px) {
      .products-slider .title:before {
        content: none; } }
  .products-slider .title:after {
    content: '';
    position: absolute;
    top: 20px;
    right: 130px;
    background: var(--color-primary);
    width: 250px;
    height: 4px;
    border-radius: 2px; }
    @media (max-width: 1199.98px) {
      .products-slider .title:after {
        content: none; } }

.color-dark {
  color: #1a1a1a !important;
  color: var(--color-dark) !important; }

.color-white {
  color: #ffffff !important;
  color: var(--white) !important; }

.color-primary {
  color: var(--color-primary) !important; }

.color-secondary {
  color: var(--color-secondary) !important; }

.modal-dialog {
  max-width: 90%;
  margin: auto; }
  .modal-dialog .close {
    position: absolute;
    right: 10px;
    top: 10px; }
    .modal-dialog .close span {
      font-size: 26px;
      font-weight: bold; }

.modal-steves {
  max-width: 650px;
  margin: auto;
  border-radius: 40px; }
  .modal-steves h1, .modal-steves h2, .modal-steves h3, .modal-steves h4, .modal-steves h5, .modal-steves h6 {
    color: var(--color-secondary); }
  .modal-steves h4 {
    font-weight: bold; }
  .modal-steves select[name="store-selected"],
  .modal-steves .modal-body input {
    height: 45px;
    border-radius: 4px;
    background-color: #eeeeee;
    box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.1); }
  .modal-steves input[type="text"]:focus {
    cursor: text !important; }
  .modal-steves .error-message {
    bottom: -30px;
    left: auto;
    right: 0;
    width: 100%;
    text-align: center; }

.table-steves {
  margin: 0px auto;
  border: 6px solid var(--color-primary); }
  .table-steves tr {
    padding: 0; }
  .table-steves td {
    height: 40px; }
  .table-steves .steves {
    font-weight: 200;
    width: 152px;
    color: var(--color-primary);
    font-size: 41px;
    letter-spacing: -0.77px;
    line-height: 34px;
    text-align: center; }
  .table-steves .liquor {
    width: 152px;
    color: var(--color-primary);
    font-size: 41px;
    font-weight: 900;
    letter-spacing: -0.77px;
    line-height: 34px; }

.modal .custom-radio .custom-control-label::after {
  top: 6.5px !important;
  z-index: 100; }

.modal .custom-control-label::before {
  border: #adb5bd solid 1px !important; }

.checkbox-big .form-group {
  display: block;
  margin-bottom: 15px; }

.checkbox-big .form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer; }

.checkbox-big .form-group input[type="checkbox"] + label {
  position: relative;
  cursor: pointer; }

.checkbox-big .form-group input[type="checkbox"] + label:before {
  content: '';
  -webkit-appearance: none;
  border: 1px solid #919191;
  border: 1px solid var(--grey);
  border-radius: 4px;
  padding: 7px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px; }

.checkbox-big .form-group input[type="checkbox"]:checked + label:before {
  border-color: var(--color-primary) !important;
  background-color: var(--color-primary); }

.checkbox-big .form-group input[type="checkbox"]:checked + label:after {
  font-family: "FontAwesome";
  content: '\f00c';
  color: white;
  display: block;
  position: absolute;
  top: 1px;
  left: 1.5px;
  height: 14px; }

.checkbox-big .border-right-secondary {
  border-right: 1px solid var(--color-secondary); }

.btn-google {
  background-color: #db3236;
  border-radius: 4px !important; }

.btn-facebook {
  background-color: #225b99;
  border-radius: 4px !important; }

.error-message {
  color: #fa6e6e;
  color: var(--red); }

.custom-radio .custom-control-label::after {
  top: 5px !important; }

.fa-times, .fa-pencil {
  font-size: 18px;
  color: #ffffff !important;
  color: var(--white) !important; }

.fa-times {
  color: #fa6e6e !important;
  color: var(--red) !important; }

#couponCode,
#customer-info-form input,
#customer-info-form select,
#customer-login-form input,
#paymentForm input {
  height: 45px; }
  #couponCode:focus,
  #customer-info-form input:focus,
  #customer-info-form select:focus,
  #customer-login-form input:focus,
  #paymentForm input:focus {
    border-color: var(--color-primary); }

.btn-outline-dark {
  border-color: #a5a5a5 !important;
  border-color: var(--color-info) !important; }
  .btn-outline-dark:hover {
    background-color: #a5a5a5 !important;
    background-color: var(--color-info) !important; }

.btn-outline-dark.active {
  background-color: #a5a5a5 !important;
  background-color: var(--color-info) !important;
  border-color: #a5a5a5 !important;
  border-color: var(--color-info) !important;
  color: white !important; }

#customer-info-form .input-group.focus {
  border-color: var(--color-primary) !important; }

.cart-info:before {
  content: "";
  position: absolute;
  top: 0px;
  left: -1px;
  width: 1px;
  height: 100%; }
  @media (max-width: 767.98px) {
    .cart-info:before {
      content: none; } }

.badge-number-right {
  position: relative;
  font-size: 0.8rem;
  padding: 10px 4px;
  border-radius: 10rem;
  min-width: 2rem;
  min-height: 2rem; }

.cart-right-img img {
  border-radius: 3px; }

.collectBox {
  flex: 50% 1;
  max-width: 100%;
  line-height: 20px;
  cursor: pointer; }
  @media (max-width: 400px) {
    .collectBox {
      flex: 100% 1 !important;
      max-width: 100% !important;
      margin-top: 5px; } }

.jumbotron {
  background-color: #39535F !important;
  color: #ffffff !important;
  color: var(--white) !important; }

.place-2-column {
  margin-left: 1px; }
  .place-2-column .place-2-column-box {
    border: 1px solid #DDE3E8; }
  .place-2-column p {
    color: #788995; }

.shipping-method-column {
  margin-left: 2%;
  max-width: 45%;
  border: 1px solid #DDE3E8;
  border-bottom: 3px solid #DDE3E8; }
  @media (max-width: 767px) {
    .shipping-method-column {
      max-width: 96%; } }
  .shipping-method-column p {
    color: #788995; }

.ml-1px {
  margin-left: 1px; }

.mx-1px {
  margin-left: 1px;
  margin-right: 1px; }

.table-summary {
  border: none !important; }

.payment-box {
  border: 1px solid #DDE3E8; }
  .payment-box p {
    color: #788995; }

.stripe-form {
  width: 100% !important; }
  @media (max-width: 992px) {
    .stripe-form {
      width: 100% !important; } }

.card-errors, .info-errors {
  color: #fa6e6e;
  color: var(--red); }

.StripeElement {
  width: 100%;
  height: 45px;
  padding: 0.8rem .75rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #eaeff4;
  border-radius: 2px;
  transition: all 0.3s ease-out; }
  .StripeElement:focus {
    border-color: var(--color-primary); }

.InputElement::-webkit-input-placeholder {
  color: #fa6e6e !important;
  color: var(--red) !important; }

.InputElement::placeholder {
  color: #fa6e6e !important;
  color: var(--red) !important; }

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 var(--color-primary);
  border-color: var(--color-primary); }

.StripeElement--invalid {
  border-color: var(--color-red); }

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important; }

.commerce-progress-bar {
  border-bottom: 1px solid #919191;
  border-bottom: 1px solid var(--grey);
  padding-bottom: 2px; }
  .commerce-progress-bar .fa {
    font-size: 25px;
    margin-right: 5px; }
  .commerce-progress-bar .media-body p {
    font-weight: 600;
    color: #1a1a1a;
    color: var(--black); }
  .commerce-progress-bar .media-body a {
    font-family: 'Visby', sans-serif;
    font-family: var(--font-family-title);
    font-size: 18px;
    font-weight: 800;
    cursor: pointer;
    color: var(--color-secondary); }
  .commerce-progress-bar .current i {
    color: var(--color-primary); }
  .commerce-progress-bar .current .media-body a {
    color: var(--color-primary);
    border-bottom: 5px solid var(--color-primary); }
  .commerce-progress-bar .link-disable .media-body a {
    cursor: default !important;
    color: #919191;
    color: var(--grey); }
  .commerce-progress-bar .link-disable .media-body p, .commerce-progress-bar .link-disable .media-body i {
    color: #919191;
    color: var(--grey); }
  @media (max-width: 500px) {
    .commerce-progress-bar {
      display: none !important; } }

.address-box p {
  margin-bottom: 0;
  color: var(--gray); }
  .address-box p span {
    color: var(--gray); }
  .address-box p a {
    text-decoration: underline;
    margin-left: 5px;
    color: var(--gray); }

.dropdown-menu {
  width: 375px;
  max-width: calc(100vw - 10px); }
  .dropdown-menu .dropdown-header {
    font-size: 1rem; }
  .dropdown-menu #cart-dropdown-item {
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: calc(80vh - 115px);
    cursor: auto; }
    .dropdown-menu #cart-dropdown-item .cart-dropdown-ptitle:hover {
      text-decoration: underline; }
    .dropdown-menu #cart-dropdown-item .remove-line-item {
      font-size: 20px;
      position: absolute;
      top: 50%;
      right: 5%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      z-index: 100; }
      .dropdown-menu #cart-dropdown-item .remove-line-item:hover {
        color: red; }
    .dropdown-menu #cart-dropdown-item .remove-loader {
      font-size: 20px;
      position: absolute;
      top: 40%;
      right: 5%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      z-index: 100; }
    .dropdown-menu #cart-dropdown-item .fa-spin {
      -webkit-transform-origin: 50% calc(50% - .5px);
      transform-origin: 50% calc(50% - .5px); }
  .dropdown-menu p {
    color: #000 !important; }

.cart-dropdown:after {
  bottom: 100%;
  left: 85%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: white;
  border-width: 8px;
  margin-left: -8px; }

.badge-number-dropdown {
  position: relative;
  font-size: 0.7rem;
  border-radius: 10rem;
  min-width: 1.5rem;
  min-height: 1rem; }

.row-line-items {
  margin-left: 0;
  margin-right: 0;
  max-width: calc(100% - 3px); }
  .row-line-items:hover {
    background-color: #e8f8fe; }

.discount-text {
  color: grey;
  font-size: 12px;
  letter-spacing: 0; }

.cart-popup {
  right: 0;
  bottom: 0;
  height: 100%;
  border-radius: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border: none;
  background-color: #f8f9fa;
  background-color: var(--color-light); }

.cart-pop-title {
  background-color: #191919;
  background-color: var(--color-bg-dark);
  height: 77px; }

.cart-pop-title p {
  font-size: 20px;
  font-weight: bold;
  color: var(--color-primary);
  margin: auto 15px; }

.cart-prod-title {
  white-space: pre-wrap;
  color: #1a1a1a;
  color: var(--color-dark); }

.cart-pop-img {
  border: 1px solid #919191;
  border: 1px solid var(--grey);
  width: 100%; }

.cart-pop-price {
  color: var(--color-primary); }

.cart-pop-view {
  border: 2px solid #000;
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-base);
  font-size: 13px;
  font-weight: bold;
  height: 39px; }

.cart-pop-checkout, .cart-pop-view:hover {
  border: 2px solid var(--color-primary);
  background-color: var(--color-primary);
  color: #ffffff;
  color: var(--white);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-base);
  font-size: 13px;
  font-weight: bold;
  height: 39px; }

.cart-pop-close {
  color: #ffffff;
  color: var(--white);
  font-size: 150%; }

.cart-pop-variant {
  font-size: 13px;
  color: #929daf;
  line-height: 1.4;
  margin-bottom: 0; }

.cart-qty .spinner {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 3; }
  .cart-qty .spinner .fa.fa-spinner.fa-spin {
    color: #000 !important;
    font-size: 50px;
    -webkit-animation-duration: 1s;
            animation-duration: 1s; }

.cart-qty .form-quantity {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2; }

.cart-qty .value-button {
  display: inline-block;
  width: 25px;
  height: 30px;
  text-align: center;
  vertical-align: middle;
  padding: 8px 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none; }
  .cart-qty .value-button i {
    font-size: 14px;
    line-height: 10px; }

.cart-qty .value-button:hover {
  cursor: pointer; }

.cart-qty .input-number {
  text-align: center;
  border: 1px solid #ddd;
  margin: 5px 0;
  width: 50px;
  height: 30px; }
  .cart-qty .input-number:focus {
    outline-color: transparent !important;
    outline-style: none !important; }

.cart-qty input[type=number]::-webkit-inner-spin-button,
.cart-qty input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin-top: 0; }

.cart-qty .variant-label {
  color: #000000;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 1rem; }
  .cart-qty .variant-label .original {
    display: block; }
  .cart-qty .variant-label .shorten {
    display: none; }

.cart-qty .fa {
  color: #fff; }

.list-view-overlay {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  left: 0; }

.quantity-overlay-show {
  background-color: white;
  overflow-y: auto;
  bottom: 0;
  height: 100%; }

.fa-chevron-up {
  color: #fff;
  margin-left: 5px; }
  .fa-chevron-up.add-to-cart-list {
    color: #50a1ff; }

.rotate180 {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg); }

@media (max-width: 576px) {
  .list-view.recommend-item .variant-label .original {
    display: none; }
  .list-view.recommend-item .variant-label .shorten {
    display: block; } }

.mobile-menu-backdrop {
  z-index: 1031; }

.nav-fix-top-mobile-placeholder {
  display: block;
  height: 62px; }

.header-mobile {
  display: block;
  z-index: 1043; }
  .header-mobile:after {
    content: "";
    position: absolute;
    top: 62px;
    left: 0;
    width: 100%;
    height: 1px; }
  .header-mobile .searchPhrase {
    height: 38px; }
  .header-mobile #search-btn-mobile img {
    height: 20px; }
  .header-mobile .cart-icon span {
    color: #ffffff;
    font-family: 'Roboto';
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    display: block;
    position: absolute;
    top: -7px;
    right: -10px;
    border-radius: 50%;
    height: 18px;
    width: 18px;
    background-color: #fa6e6e; }
  .header-mobile .title {
    height: 17px;
    color: #1a1a1a;
    color: var(--color-dark);
    font-family: 'Open Sans', sans-serif;
    font-family: var(--font-family-base);
    font-size: 12px;
    font-weight: bold;
    line-height: 17px;
    text-shadow: 0.5px 1px 0 0 #ffffff;
    text-shadow: 0.5px 1px 0 0 var(--white); }
  .header-mobile .mobile-title {
    letter-spacing: -1px;
    font-size: 10px; }
  .header-mobile .mobile-title-menu {
    margin-top: -8px;
    font-size: 10px;
    margin-bottom: 0; }

.mobile-menu-container {
  margin-top: 64px; }
  .mobile-menu-container .locationIcon {
    height: 48px; }
    .mobile-menu-container .locationIcon img {
      padding-left: 15px; }
  .mobile-menu-container .search {
    max-width: calc(100vw - 1rem);
    margin-right: auto;
    margin-left: auto; }
    .mobile-menu-container .search input {
      height: 48px;
      border-radius: 4px;
      background-color: #e6e6e6; }
      .mobile-menu-container .search input::-webkit-input-placeholder {
        height: 19px;
        width: 253px;
        color: #263b46;
        font-family: 'Open Sans', sans-serif;
        font-family: var(--font-family-base);
        font-size: 14px;
        line-height: 19px; }
      .mobile-menu-container .search input::placeholder {
        height: 19px;
        width: 253px;
        color: #263b46;
        font-family: 'Open Sans', sans-serif;
        font-family: var(--font-family-base);
        font-size: 14px;
        line-height: 19px; }
    .mobile-menu-container .search .input-group-text {
      background-color: #e6e6e6; }
      .mobile-menu-container .search .input-group-text img {
        height: 30px; }
  .mobile-menu-container .user-section:after {
    content: "";
    position: absolute;
    top: 190px;
    left: 0;
    width: 100%;
    height: 1px; }
  .mobile-menu-container .user-section img {
    width: 36px;
    height: 36px;
    margin-right: auto;
    margin-left: auto; }
  .mobile-menu-container .user-section p {
    height: 19px;
    color: #263b46;
    font-family: 'Open Sans', sans-serif;
    font-family: var(--font-family-base);
    font-size: 14px;
    font-weight: bold;
    line-height: 19px;
    text-shadow: 0.5px 1px 0 0 #ffffff;
    margin-bottom: 0; }
    .mobile-menu-container .user-section p a {
      color: inherit; }
  .mobile-menu-container .user-section a {
    height: 19px;
    width: 46px;
    color: var(--color-primary);
    font-family: 'Open Sans', sans-serif;
    font-family: var(--font-family-base);
    font-size: 14px;
    line-height: 19px;
    text-shadow: 0.5px 1px 0 0 #ffffff; }
  .mobile-menu-container .mega-menu ul {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0; }
    .mobile-menu-container .mega-menu ul li {
      display: flex;
      justify-content: space-between;
      padding: 0 24px;
      outline: none;
      font-size: 20px;
      line-height: 48px;
      font-weight: 100;
      text-shadow: 0 0 1px rgba(255, 255, 255, 0.1);
      color: #6b6f83;
      transition: background 0.3s, box-shadow 0.3s;
      text-decoration: none;
      position: relative; }
      .mobile-menu-container .mega-menu ul li a {
        color: inherit; }
      .mobile-menu-container .mega-menu ul li i {
        float: right;
        padding: 3px 15px 3px 20px; }
        .mobile-menu-container .mega-menu ul li i::before {
          line-height: 48px; }
      .mobile-menu-container .mega-menu ul li:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px; }
  .mobile-menu-container .mega-menu .sub-menu {
    position: absolute;
    top: 194px;
    left: -100%;
    width: 100%;
    height: 100%;
    padding: 0;
    transition: 0.5s ease-in-out;
    background-color: #f8f9fa;
    z-index: 1042; }
    .mobile-menu-container .mega-menu .sub-menu.open {
      left: 0; }
    .mobile-menu-container .mega-menu .sub-menu.level-2 {
      top: 0;
      z-index: 1043; }
    .mobile-menu-container .mega-menu .sub-menu .sub-menu-back:nth-child(1) span {
      font-weight: 600; }

body {
  background: #f1f1f1; }

.body-cover {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  background-color: var(--white);
  z-index: 10000;
  display: flex; }
  .body-cover img {
    justify-content: center;
    align-items: center;
    height: 65px;
    width: auto;
    box-shadow: 0 0 30rem 10rem rgba(0, 0, 0, 0.1);
    border-radius: 50%; }
  .body-cover p {
    font-size: 16px; }

.text-green {
  color: #1bb24b !important; }

.back-to-top-button {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 50px;
  background-color: transparent;
  width: 50px;
  height: auto; }

.container {
  max-width: 1280px; }

.max-w-1 {
  max-width: 1em !important; }

.max-h-1 {
  max-height: 1em !important; }

.min-w-1 {
  min-width: 1em !important; }

.min-h-1 {
  min-height: 1em !important; }

.max-w-2 {
  max-width: 2em !important; }

.max-h-2 {
  max-height: 2em !important; }

.min-w-2 {
  min-width: 2em !important; }

.min-h-2 {
  min-height: 2em !important; }

.max-w-3 {
  max-width: 3em !important; }

.max-h-3 {
  max-height: 3em !important; }

.min-w-3 {
  min-width: 3em !important; }

.min-h-3 {
  min-height: 3em !important; }

.max-w-4 {
  max-width: 4em !important; }

.max-h-4 {
  max-height: 4em !important; }

.min-w-4 {
  min-width: 4em !important; }

.min-h-4 {
  min-height: 4em !important; }

.max-w-5 {
  max-width: 5em !important; }

.max-h-5 {
  max-height: 5em !important; }

.min-w-5 {
  min-width: 5em !important; }

.min-h-5 {
  min-height: 5em !important; }

.max-w-6 {
  max-width: 6em !important; }

.max-h-6 {
  max-height: 6em !important; }

.min-w-6 {
  min-width: 6em !important; }

.min-h-6 {
  min-height: 6em !important; }

.max-w-7 {
  max-width: 7em !important; }

.max-h-7 {
  max-height: 7em !important; }

.min-w-7 {
  min-width: 7em !important; }

.min-h-7 {
  min-height: 7em !important; }

.max-w-8 {
  max-width: 8em !important; }

.max-h-8 {
  max-height: 8em !important; }

.min-w-8 {
  min-width: 8em !important; }

.min-h-8 {
  min-height: 8em !important; }

.max-w-9 {
  max-width: 9em !important; }

.max-h-9 {
  max-height: 9em !important; }

.min-w-9 {
  min-width: 9em !important; }

.min-h-9 {
  min-height: 9em !important; }

.max-w-10 {
  max-width: 10em !important; }

.max-h-10 {
  max-height: 10em !important; }

.min-w-10 {
  min-width: 10em !important; }

.min-h-10 {
  min-height: 10em !important; }

.max-w-11 {
  max-width: 11em !important; }

.max-h-11 {
  max-height: 11em !important; }

.min-w-11 {
  min-width: 11em !important; }

.min-h-11 {
  min-height: 11em !important; }

.max-w-12 {
  max-width: 12em !important; }

.max-h-12 {
  max-height: 12em !important; }

.min-w-12 {
  min-width: 12em !important; }

.min-h-12 {
  min-height: 12em !important; }

.max-w-13 {
  max-width: 13em !important; }

.max-h-13 {
  max-height: 13em !important; }

.min-w-13 {
  min-width: 13em !important; }

.min-h-13 {
  min-height: 13em !important; }

.max-w-14 {
  max-width: 14em !important; }

.max-h-14 {
  max-height: 14em !important; }

.min-w-14 {
  min-width: 14em !important; }

.min-h-14 {
  min-height: 14em !important; }

.max-w-15 {
  max-width: 15em !important; }

.max-h-15 {
  max-height: 15em !important; }

.min-w-15 {
  min-width: 15em !important; }

.min-h-15 {
  min-height: 15em !important; }

.max-w-16 {
  max-width: 16em !important; }

.max-h-16 {
  max-height: 16em !important; }

.min-w-16 {
  min-width: 16em !important; }

.min-h-16 {
  min-height: 16em !important; }

.max-w-17 {
  max-width: 17em !important; }

.max-h-17 {
  max-height: 17em !important; }

.min-w-17 {
  min-width: 17em !important; }

.min-h-17 {
  min-height: 17em !important; }

.max-w-18 {
  max-width: 18em !important; }

.max-h-18 {
  max-height: 18em !important; }

.min-w-18 {
  min-width: 18em !important; }

.min-h-18 {
  min-height: 18em !important; }

.max-w-19 {
  max-width: 19em !important; }

.max-h-19 {
  max-height: 19em !important; }

.min-w-19 {
  min-width: 19em !important; }

.min-h-19 {
  min-height: 19em !important; }

.max-w-20 {
  max-width: 20em !important; }

.max-h-20 {
  max-height: 20em !important; }

.min-w-20 {
  min-width: 20em !important; }

.min-h-20 {
  min-height: 20em !important; }

.max-w-21 {
  max-width: 21em !important; }

.max-h-21 {
  max-height: 21em !important; }

.min-w-21 {
  min-width: 21em !important; }

.min-h-21 {
  min-height: 21em !important; }

.max-w-22 {
  max-width: 22em !important; }

.max-h-22 {
  max-height: 22em !important; }

.min-w-22 {
  min-width: 22em !important; }

.min-h-22 {
  min-height: 22em !important; }

.max-w-23 {
  max-width: 23em !important; }

.max-h-23 {
  max-height: 23em !important; }

.min-w-23 {
  min-width: 23em !important; }

.min-h-23 {
  min-height: 23em !important; }

.max-w-24 {
  max-width: 24em !important; }

.max-h-24 {
  max-height: 24em !important; }

.min-w-24 {
  min-width: 24em !important; }

.min-h-24 {
  min-height: 24em !important; }

.max-w-25 {
  max-width: 25em !important; }

.max-h-25 {
  max-height: 25em !important; }

.min-w-25 {
  min-width: 25em !important; }

.min-h-25 {
  min-height: 25em !important; }

.max-w-26 {
  max-width: 26em !important; }

.max-h-26 {
  max-height: 26em !important; }

.min-w-26 {
  min-width: 26em !important; }

.min-h-26 {
  min-height: 26em !important; }

.max-w-27 {
  max-width: 27em !important; }

.max-h-27 {
  max-height: 27em !important; }

.min-w-27 {
  min-width: 27em !important; }

.min-h-27 {
  min-height: 27em !important; }

.max-w-28 {
  max-width: 28em !important; }

.max-h-28 {
  max-height: 28em !important; }

.min-w-28 {
  min-width: 28em !important; }

.min-h-28 {
  min-height: 28em !important; }

.max-w-29 {
  max-width: 29em !important; }

.max-h-29 {
  max-height: 29em !important; }

.min-w-29 {
  min-width: 29em !important; }

.min-h-29 {
  min-height: 29em !important; }

.max-w-30 {
  max-width: 30em !important; }

.max-h-30 {
  max-height: 30em !important; }

.min-w-30 {
  min-width: 30em !important; }

.min-h-30 {
  min-height: 30em !important; }

.max-w-31 {
  max-width: 31em !important; }

.max-h-31 {
  max-height: 31em !important; }

.min-w-31 {
  min-width: 31em !important; }

.min-h-31 {
  min-height: 31em !important; }

.max-w-32 {
  max-width: 32em !important; }

.max-h-32 {
  max-height: 32em !important; }

.min-w-32 {
  min-width: 32em !important; }

.min-h-32 {
  min-height: 32em !important; }

.max-w-33 {
  max-width: 33em !important; }

.max-h-33 {
  max-height: 33em !important; }

.min-w-33 {
  min-width: 33em !important; }

.min-h-33 {
  min-height: 33em !important; }

.max-w-34 {
  max-width: 34em !important; }

.max-h-34 {
  max-height: 34em !important; }

.min-w-34 {
  min-width: 34em !important; }

.min-h-34 {
  min-height: 34em !important; }

.max-w-35 {
  max-width: 35em !important; }

.max-h-35 {
  max-height: 35em !important; }

.min-w-35 {
  min-width: 35em !important; }

.min-h-35 {
  min-height: 35em !important; }

.max-w-36 {
  max-width: 36em !important; }

.max-h-36 {
  max-height: 36em !important; }

.min-w-36 {
  min-width: 36em !important; }

.min-h-36 {
  min-height: 36em !important; }

.max-w-37 {
  max-width: 37em !important; }

.max-h-37 {
  max-height: 37em !important; }

.min-w-37 {
  min-width: 37em !important; }

.min-h-37 {
  min-height: 37em !important; }

.max-w-38 {
  max-width: 38em !important; }

.max-h-38 {
  max-height: 38em !important; }

.min-w-38 {
  min-width: 38em !important; }

.min-h-38 {
  min-height: 38em !important; }

.max-w-39 {
  max-width: 39em !important; }

.max-h-39 {
  max-height: 39em !important; }

.min-w-39 {
  min-width: 39em !important; }

.min-h-39 {
  min-height: 39em !important; }

.max-w-40 {
  max-width: 40em !important; }

.max-h-40 {
  max-height: 40em !important; }

.min-w-40 {
  min-width: 40em !important; }

.min-h-40 {
  min-height: 40em !important; }

.max-w-41 {
  max-width: 41em !important; }

.max-h-41 {
  max-height: 41em !important; }

.min-w-41 {
  min-width: 41em !important; }

.min-h-41 {
  min-height: 41em !important; }

.max-w-42 {
  max-width: 42em !important; }

.max-h-42 {
  max-height: 42em !important; }

.min-w-42 {
  min-width: 42em !important; }

.min-h-42 {
  min-height: 42em !important; }

.max-w-43 {
  max-width: 43em !important; }

.max-h-43 {
  max-height: 43em !important; }

.min-w-43 {
  min-width: 43em !important; }

.min-h-43 {
  min-height: 43em !important; }

.max-w-44 {
  max-width: 44em !important; }

.max-h-44 {
  max-height: 44em !important; }

.min-w-44 {
  min-width: 44em !important; }

.min-h-44 {
  min-height: 44em !important; }

.max-w-45 {
  max-width: 45em !important; }

.max-h-45 {
  max-height: 45em !important; }

.min-w-45 {
  min-width: 45em !important; }

.min-h-45 {
  min-height: 45em !important; }

.max-w-46 {
  max-width: 46em !important; }

.max-h-46 {
  max-height: 46em !important; }

.min-w-46 {
  min-width: 46em !important; }

.min-h-46 {
  min-height: 46em !important; }

.max-w-47 {
  max-width: 47em !important; }

.max-h-47 {
  max-height: 47em !important; }

.min-w-47 {
  min-width: 47em !important; }

.min-h-47 {
  min-height: 47em !important; }

.max-w-48 {
  max-width: 48em !important; }

.max-h-48 {
  max-height: 48em !important; }

.min-w-48 {
  min-width: 48em !important; }

.min-h-48 {
  min-height: 48em !important; }

.max-w-49 {
  max-width: 49em !important; }

.max-h-49 {
  max-height: 49em !important; }

.min-w-49 {
  min-width: 49em !important; }

.min-h-49 {
  min-height: 49em !important; }

.max-w-50 {
  max-width: 50em !important; }

.max-h-50 {
  max-height: 50em !important; }

.min-w-50 {
  min-width: 50em !important; }

.min-h-50 {
  min-height: 50em !important; }

.max-w-51 {
  max-width: 51em !important; }

.max-h-51 {
  max-height: 51em !important; }

.min-w-51 {
  min-width: 51em !important; }

.min-h-51 {
  min-height: 51em !important; }

.max-w-52 {
  max-width: 52em !important; }

.max-h-52 {
  max-height: 52em !important; }

.min-w-52 {
  min-width: 52em !important; }

.min-h-52 {
  min-height: 52em !important; }

.max-w-53 {
  max-width: 53em !important; }

.max-h-53 {
  max-height: 53em !important; }

.min-w-53 {
  min-width: 53em !important; }

.min-h-53 {
  min-height: 53em !important; }

.max-w-54 {
  max-width: 54em !important; }

.max-h-54 {
  max-height: 54em !important; }

.min-w-54 {
  min-width: 54em !important; }

.min-h-54 {
  min-height: 54em !important; }

.max-w-55 {
  max-width: 55em !important; }

.max-h-55 {
  max-height: 55em !important; }

.min-w-55 {
  min-width: 55em !important; }

.min-h-55 {
  min-height: 55em !important; }

.max-w-56 {
  max-width: 56em !important; }

.max-h-56 {
  max-height: 56em !important; }

.min-w-56 {
  min-width: 56em !important; }

.min-h-56 {
  min-height: 56em !important; }

.max-w-57 {
  max-width: 57em !important; }

.max-h-57 {
  max-height: 57em !important; }

.min-w-57 {
  min-width: 57em !important; }

.min-h-57 {
  min-height: 57em !important; }

.max-w-58 {
  max-width: 58em !important; }

.max-h-58 {
  max-height: 58em !important; }

.min-w-58 {
  min-width: 58em !important; }

.min-h-58 {
  min-height: 58em !important; }

.max-w-59 {
  max-width: 59em !important; }

.max-h-59 {
  max-height: 59em !important; }

.min-w-59 {
  min-width: 59em !important; }

.min-h-59 {
  min-height: 59em !important; }

.max-w-60 {
  max-width: 60em !important; }

.max-h-60 {
  max-height: 60em !important; }

.min-w-60 {
  min-width: 60em !important; }

.min-h-60 {
  min-height: 60em !important; }

.max-w-61 {
  max-width: 61em !important; }

.max-h-61 {
  max-height: 61em !important; }

.min-w-61 {
  min-width: 61em !important; }

.min-h-61 {
  min-height: 61em !important; }

.max-w-62 {
  max-width: 62em !important; }

.max-h-62 {
  max-height: 62em !important; }

.min-w-62 {
  min-width: 62em !important; }

.min-h-62 {
  min-height: 62em !important; }

.max-w-63 {
  max-width: 63em !important; }

.max-h-63 {
  max-height: 63em !important; }

.min-w-63 {
  min-width: 63em !important; }

.min-h-63 {
  min-height: 63em !important; }

.max-w-64 {
  max-width: 64em !important; }

.max-h-64 {
  max-height: 64em !important; }

.min-w-64 {
  min-width: 64em !important; }

.min-h-64 {
  min-height: 64em !important; }

.max-w-65 {
  max-width: 65em !important; }

.max-h-65 {
  max-height: 65em !important; }

.min-w-65 {
  min-width: 65em !important; }

.min-h-65 {
  min-height: 65em !important; }

.max-w-66 {
  max-width: 66em !important; }

.max-h-66 {
  max-height: 66em !important; }

.min-w-66 {
  min-width: 66em !important; }

.min-h-66 {
  min-height: 66em !important; }

.max-w-67 {
  max-width: 67em !important; }

.max-h-67 {
  max-height: 67em !important; }

.min-w-67 {
  min-width: 67em !important; }

.min-h-67 {
  min-height: 67em !important; }

.max-w-68 {
  max-width: 68em !important; }

.max-h-68 {
  max-height: 68em !important; }

.min-w-68 {
  min-width: 68em !important; }

.min-h-68 {
  min-height: 68em !important; }

.max-w-69 {
  max-width: 69em !important; }

.max-h-69 {
  max-height: 69em !important; }

.min-w-69 {
  min-width: 69em !important; }

.min-h-69 {
  min-height: 69em !important; }

.max-w-70 {
  max-width: 70em !important; }

.max-h-70 {
  max-height: 70em !important; }

.min-w-70 {
  min-width: 70em !important; }

.min-h-70 {
  min-height: 70em !important; }

.max-w-71 {
  max-width: 71em !important; }

.max-h-71 {
  max-height: 71em !important; }

.min-w-71 {
  min-width: 71em !important; }

.min-h-71 {
  min-height: 71em !important; }

.max-w-72 {
  max-width: 72em !important; }

.max-h-72 {
  max-height: 72em !important; }

.min-w-72 {
  min-width: 72em !important; }

.min-h-72 {
  min-height: 72em !important; }

.max-w-73 {
  max-width: 73em !important; }

.max-h-73 {
  max-height: 73em !important; }

.min-w-73 {
  min-width: 73em !important; }

.min-h-73 {
  min-height: 73em !important; }

.max-w-74 {
  max-width: 74em !important; }

.max-h-74 {
  max-height: 74em !important; }

.min-w-74 {
  min-width: 74em !important; }

.min-h-74 {
  min-height: 74em !important; }

.max-w-75 {
  max-width: 75em !important; }

.max-h-75 {
  max-height: 75em !important; }

.min-w-75 {
  min-width: 75em !important; }

.min-h-75 {
  min-height: 75em !important; }

.max-w-76 {
  max-width: 76em !important; }

.max-h-76 {
  max-height: 76em !important; }

.min-w-76 {
  min-width: 76em !important; }

.min-h-76 {
  min-height: 76em !important; }

.max-w-77 {
  max-width: 77em !important; }

.max-h-77 {
  max-height: 77em !important; }

.min-w-77 {
  min-width: 77em !important; }

.min-h-77 {
  min-height: 77em !important; }

.max-w-78 {
  max-width: 78em !important; }

.max-h-78 {
  max-height: 78em !important; }

.min-w-78 {
  min-width: 78em !important; }

.min-h-78 {
  min-height: 78em !important; }

.max-w-79 {
  max-width: 79em !important; }

.max-h-79 {
  max-height: 79em !important; }

.min-w-79 {
  min-width: 79em !important; }

.min-h-79 {
  min-height: 79em !important; }

.max-w-80 {
  max-width: 80em !important; }

.max-h-80 {
  max-height: 80em !important; }

.min-w-80 {
  min-width: 80em !important; }

.min-h-80 {
  min-height: 80em !important; }

.max-w-81 {
  max-width: 81em !important; }

.max-h-81 {
  max-height: 81em !important; }

.min-w-81 {
  min-width: 81em !important; }

.min-h-81 {
  min-height: 81em !important; }

.max-w-82 {
  max-width: 82em !important; }

.max-h-82 {
  max-height: 82em !important; }

.min-w-82 {
  min-width: 82em !important; }

.min-h-82 {
  min-height: 82em !important; }

.max-w-83 {
  max-width: 83em !important; }

.max-h-83 {
  max-height: 83em !important; }

.min-w-83 {
  min-width: 83em !important; }

.min-h-83 {
  min-height: 83em !important; }

.max-w-84 {
  max-width: 84em !important; }

.max-h-84 {
  max-height: 84em !important; }

.min-w-84 {
  min-width: 84em !important; }

.min-h-84 {
  min-height: 84em !important; }

.max-w-85 {
  max-width: 85em !important; }

.max-h-85 {
  max-height: 85em !important; }

.min-w-85 {
  min-width: 85em !important; }

.min-h-85 {
  min-height: 85em !important; }

.max-w-86 {
  max-width: 86em !important; }

.max-h-86 {
  max-height: 86em !important; }

.min-w-86 {
  min-width: 86em !important; }

.min-h-86 {
  min-height: 86em !important; }

.max-w-87 {
  max-width: 87em !important; }

.max-h-87 {
  max-height: 87em !important; }

.min-w-87 {
  min-width: 87em !important; }

.min-h-87 {
  min-height: 87em !important; }

.max-w-88 {
  max-width: 88em !important; }

.max-h-88 {
  max-height: 88em !important; }

.min-w-88 {
  min-width: 88em !important; }

.min-h-88 {
  min-height: 88em !important; }

.max-w-89 {
  max-width: 89em !important; }

.max-h-89 {
  max-height: 89em !important; }

.min-w-89 {
  min-width: 89em !important; }

.min-h-89 {
  min-height: 89em !important; }

.max-w-90 {
  max-width: 90em !important; }

.max-h-90 {
  max-height: 90em !important; }

.min-w-90 {
  min-width: 90em !important; }

.min-h-90 {
  min-height: 90em !important; }

.text-white {
  color: #ffffff !important;
  color: var(--white) !important; }

.nav-navbar .arrow {
  display: inline-block;
  width: 0;
  height: 0;
  margin-bottom: 2px;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent; }

.dropdown-toggle::after {
  content: none !important; }

.page-header {
  text-align: center;
  margin-top: 0; }
  .page-header .title-header {
    text-transform: uppercase;
    text-align: center;
    color: var(--color-primary);
    font-family: brandon-grotesque, sans-serif;
    font-family: var(--font-family-special);
    font-size: 36px;
    font-weight: 900;
    letter-spacing: -0.68px;
    line-height: 52px; }
  .page-header .short-description {
    white-space: pre-line;
    margin: auto;
    color: #070707;
    font-family: 'Open Sans', sans-serif;
    font-family: var(--font-family-base);
    font-size: 16px;
    letter-spacing: -0.3px;
    line-height: 22px;
    text-align: center; }

.breadcrumb {
  margin: auto;
  text-align: center;
  display: flex;
  justify-content: center; }
  .breadcrumb .link {
    display: inherit;
    height: 18px; }
    .breadcrumb .link a {
      text-transform: uppercase;
      color: #070707;
      font-family: brandon-grotesque, sans-serif;
      font-family: var(--font-family-special);
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 1.2px;
      line-height: 18px;
      text-align: center; }
  .breadcrumb .label {
    text-transform: uppercase;
    height: 18px;
    color: var(--color-primary);
    font-family: brandon-grotesque, sans-serif;
    font-family: var(--font-family-special);
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1.2px;
    line-height: 18px;
    text-align: center; }
  .breadcrumb .arrow-right-simple-line {
    margin: 0 10px;
    height: 10px;
    color: #070707;
    font-size: 12px;
    letter-spacing: -0.19px;
    line-height: 18px;
    text-align: center; }

.theme-logo {
  max-height: 120px;
  width: 398px;
  height: 125px; }
  .theme-logo-fix {
    width: 180px;
    height: auto; }

.theme-logo-fix {
  -webkit-transform: scale(1.4);
          transform: scale(1.4); }

.slick-slider {
  display: none; }
  .slick-slider.slick-initialized {
    display: block; }

.slick-slide {
  display: none; }
  .slick-slide img {
    height: 0; }

.slick-initialized .slick-slide img {
  height: auto; }

.foot-notes {
  color: #bbbbbb;
  font-size: 11px;
  letter-spacing: 0;
  margin-top: 50px; }

.tooltip {
  z-index: 1042; }

.text-underline {
  text-decoration: underline !important; }

#toTop {
  cursor: pointer;
  position: fixed;
  z-index: 1041;
  height: 50px;
  right: 20px;
  bottom: 20px;
  box-shadow: 0 0 15px 0 rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  border: none;
  display: none; }

.loading .card-item {
  cursor: wait !important; }

.custom-control-label::before {
  border: 1px solid #555555; }

.mb--50 {
  margin-bottom: -50px !important; }

.nav-fix-top {
  display: block;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1043;
  background-color: #f1f1f1; }

.nav-fix-top-desktop-placeholder {
  display: block;
  height: 137px; }
  @media (max-width: 1020px) and (min-width: 650px) {
    .nav-fix-top-desktop-placeholder {
      height: 187px; } }
  @media (max-width: 649px) {
    .nav-fix-top-desktop-placeholder {
      height: 205px; } }

.menu-wrapper {
  margin-top: 10px;
  position: relative;
  display: block;
  width: 30px;
  height: 30px;
  cursor: pointer; }

.main-menu-mobile {
  overflow-y: scroll;
  position: fixed;
  left: -150%;
  z-index: 1042;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid #1a1a1a;
  border-bottom: 1px solid var(--color-dark);
  transition: 1.35s cubic-bezier(0.1, 0.55, 0.29, 1.44);
  transition: 1.35s cubic-bezier(var(--bouncey));
  background: #f8f9fa;
  background: var(--color-light);
  box-shadow: 4px 6px 8px -4px rgba(0, 0, 0, 0.75); }
  .main-menu-mobile.active {
    left: 0%;
    transition: 0.35s cubic-bezier(0.52, 0.01, 0.16, 1);
    transition: 0.35s cubic-bezier(var(--sweepinginout)); }
  .main-menu-mobile .nav-link {
    color: var(--color-primary);
    font-weight: 400; }
    .main-menu-mobile .nav-link:hover:after {
      background: #919191;
      background: var(--grey);
      width: 30px;
      height: 1px;
      content: "";
      position: absolute;
      bottom: 10px;
      left: 49%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%); }
  .main-menu-mobile .nav-item .nav {
    border: 1px solid #919191;
    border: 1px solid var(--grey);
    border-radius: 0;
    background: #1a1a1a;
    background: var(--color-dark);
    position: absolute;
    padding: 40px 0;
    left: -55px; }
    .main-menu-mobile .nav-item .nav:before {
      position: absolute;
      top: 0;
      left: 50%;
      height: 20px;
      width: 1px;
      content: "";
      background: #919191;
      background: var(--grey); }
    .main-menu-mobile .nav-item .nav:after {
      position: absolute;
      bottom: 0;
      left: 50%;
      height: 20px;
      width: 1px;
      content: "";
      background: #919191;
      background: var(--grey); }
    .main-menu-mobile .nav-item .nav .nav-link {
      font-weight: 400;
      color: #1a1a1a;
      color: var(--color-dark);
      opacity: 1; }
      .main-menu-mobile .nav-item .nav .nav-link:hover {
        text-decoration: underline !important; }
        .main-menu-mobile .nav-item .nav .nav-link:hover:after {
          content: none !important; }

.btn {
  font-family: brandon-grotesque, sans-serif;
  font-family: var(--font-family-special);
  font-weight: 400;
  letter-spacing: 2px;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 0;
  cursor: pointer; }
  @media (max-width: 991.98px) {
    .btn {
      letter-spacing: 1px;
      font-size: 10px;
      padding: 6px 20px 5px; } }

.btn-link {
  font-family: brandon-grotesque, sans-serif;
  font-family: var(--font-family-special);
  font-size: 14px;
  text-transform: uppercase;
  color: #1a1a1a;
  color: var(--color-dark);
  transition: all 0.5s ease-out !important; }
  .btn-link:hover {
    color: #1a1a1a;
    color: var(--color-dark);
    text-decoration: none;
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
    transition: all 0.5s ease-out !important; }

.button-unstyled {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-progress-appearance: inherit;
  font-size: 100%;
  font-family: inherit;
  border: 0;
  padding: 0; }

.latest-news .title {
  font-family: brandon-grotesque, sans-serif;
  font-family: var(--font-family-special);
  font-size: 28px;
  font-weight: 900;
  text-transform: uppercase;
  color: #1a1a1a;
  color: var(--color-dark);
  letter-spacing: 6.5px;
  position: relative; }
  .latest-news .title:before {
    content: "";
    position: absolute;
    top: 20px;
    left: 3%;
    width: 215px;
    height: 1px; }
    @media (max-width: 1199.98px) {
      .latest-news .title:before {
        content: none; } }
  .latest-news .title:after {
    content: "";
    position: absolute;
    top: 20px;
    right: 3%;
    width: 215px;
    height: 1px; }
    @media (max-width: 1199.98px) {
      .latest-news .title:after {
        content: none; } }

.latest-news .item {
  text-align: center; }
  .latest-news .item small {
    font-size: 12px;
    font-family: brandon-grotesque, sans-serif;
    font-family: var(--font-family-special); }
  .latest-news .item .title {
    font-size: 18px;
    text-transform: none;
    letter-spacing: 1px; }
    .latest-news .item .title:before, .latest-news .item .title:after {
      content: none !important; }
  .latest-news .item .image {
    width: 100%;
    height: 246px;
    display: inline-block; }

.features .item .title {
  font-family: brandon-grotesque, sans-serif;
  font-family: var(--font-family-special);
  color: #1a1a1a;
  color: var(--color-dark); }

.ff-errors {
  color: #8e4646;
  background-color: #fee2e2;
  border-color: #fed6d6; }

.ff-form-success {
  color: #2c7b3a;
  background-color: #d8f6de;
  border-color: #c8f3d0; }

.contact-left-panel {
  min-height: 487px; }

.hero-slider .slick-dots .slick-active button {
  width: 80px;
  background-color: var(--color-primary); }

.hero-slider .slick-dots li button {
  background-color: #a5a5a5;
  background-color: var(--color-info);
  width: 80px;
  height: 5px; }

@font-face {
  font-family: 'Visby';
  font-style: normal;
  font-weight: normal;
  src: local("VisbyCF-Medium"), url(/static/media/VisbyCF-Medium.71dda8f7.otf) format("opentype"); }

@font-face {
  font-family: 'Visby';
  font-style: normal;
  font-weight: bold;
  src: local("VisbyCF-Bold"), url(/static/media/VisbyCF-Bold.3c0894fe.otf) format("opentype"); }

:root {
  --blue: #1c97d3;
  --indigo: #6610f2;
  --purple: #926dde;
  --pink: #e83e8c;
  --red: #fa6e6e;
  --orange: #ffbe00;
  --yellow: #ffba00;
  --green: #3cd458;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #ffffff;
  --grey: #919191;
  --black: #1a1a1a;
  --light-grey: #a5a5a5;
  --color-success: var(--green);
  --color-info: var(--light-grey);
  --color-warning: var(--yellow);
  --color-danger: var(--red);
  --color-light: #f8f9fa;
  --color-dark: var(--black);
  --color-grey: #979797;
  --color-light-grey: var(--light-grey);
  --color-body-background: #f9f9f9;
  --color-third: var(--color-primary);
  --color-icon: var(--color-third);
  --color-bg-lightest: #fcfdfe;
  --color-bg-lighter: #f9fafb;
  --color-bg-light: #f5f6f7;
  --color-bg-body: #ffffff;
  --color-bg-dark: #191919;
  --color-bg-gray: #fafbfb;
  --color-dark-gray: #e6e6e6;
  --color-error: #fa6e6e;
  --color-text-darker: #323d47;
  --color-text-dark: #555555;
  --color-text: var(--black);
  --color-text-light: #999999;
  --color-text-lighter: #bfc5ca;
  --color-text-lightest: #d3d3d3;
  --color-text-secondary: #929daf;
  --color-text-disable: #a5b3c7;
  --color-text-placeholder: #c9ccce;
  --color-title: var(--black);
  --color-subtitle: var(--color-text-light);
  --color-divider: #eaeff4;
  --color-divider-light: #f1f2f3;
  --color-divider-dark: #e8e8e8;
  --font-family-base: 'Open Sans', sans-serif;
  --font-family-title: 'Visby', sans-serif;
  --font-family-special: brandon-grotesque, sans-serif;
  --font-family-number: 'Roboto', sans-serif;
  --font-size-base: 0.9375rem;
  --font-weight-base: 400;
  --line-height-base: 1.2;
  --h1-font-size: --font-size-base * 1.75;
  --h2-font-size: --font-size-base * 1.6;
  --h3-font-size: --font-size-base * 1.45;
  --h4-font-size: --font-size-base * 1.3;
  --h5-font-size: --font-size-base * 1.15;
  --h6-font-size: --font-size-base * 1;
  --h1-font-weight: 500;
  --h2-font-weight: 500;
  --h3-font-weight: 500;
  --h4-font-weight: 500;
  --h5-font-weight: 500;
  --h6-font-weight: 500;
  --display1-size: 5rem;
  --display2-size: 4rem;
  --display3-size: 3.5rem;
  --display4-size: 3rem;
  --display1-weight: 200;
  --display2-weight: 200;
  --display3-weight: 200;
  --display4-weight: 200;
  --navbar-min-height: 56px;
  --navbar-offset-top: 10px;
  --bar-width: 30px;
  --bar-height: 1px;
  --bar-spacing: 10px;
  --fluid: 0.7, 0.3, 0, 1;
  --fluidless: 0.5, 0.5, 0, 1;
  --smootheaseout: 0.62, 0.02, 0.34, 1;
  --intenseeaseout: 1, 0, 0, 1;
  --lessintenseperfect: 0.55, 0, 0.45, 1;
  --suckitin: 0.38, -0.32, 0.89, 0.34;
  --smoothswing: 0.4, 0.3, 0.1, 0.4;
  --gentleeasein: 0.5, 0, 0.57, 1;
  --lineareaseout: 0.45, 0.65, 0.64, 1;
  --sweepinginout: 0.52, 0.01, 0.16, 1;
  --lineareasein: 0.52, 0.7, 0.41, 1;
  --gentleout: 0.11, 0.62, 0.3, 0.98;
  --sweetout: 0.19, 1, 0.22, 1;
  --bouncey: 0.1, 0.55, 0.29, 1.44;
  --color-red-error: rgb(185, 74, 72);
  --color-green-success: #28a745;
  --color-grey-arrow: rgba(204, 204, 204, 0.2);
  --width-default: 220px;
  --zindex-select-dropdown: 1060;
  --input-color-placeholder: #999999;
  --input-alt-color-placeholder: rgba(255, 255, 255, 0.5);
  --input-padding-y-sm: 0.25rem;
  --input-padding-x-sm: 0.5rem;
  --input-padding-y-lg: 0.5rem;
  --input-padding-x-lg: 1rem;
  --custom-control-indicator-border-color: #555555; }

a {
  color: var(--color-primary); }

a:not([href]), a:not([href]):hover {
  color: inherit !important;
  text-decoration: none; }

.container {
  max-width: 1280px; }

.btn {
  border-radius: 6px; }

.btn-primary,
.btn-primary:disabled,
.btn-primary:active,
.btn-primary:focus {
  background-color: var(--color-primary);
  border: var(--color-primary);
  color: white; }

.btn-secondary,
.btn-secondary:disabled,
.btn-secondary:active,
.btn-secondary:focus {
  background-color: var(--color-secondary);
  border: var(--color-secondary);
  color: white; }

.btn-primary:hover,
.btn-secondary:hover {
  background-color: black;
  border: none !important; }

.btn-primary:active,
.btn-secondary:active {
  border: none !important; }

.color-primary {
  color: var(--color-primary) !important; }

.color-secondary {
  color: var(--color-secondary) !important; }

.color-third {
  color: var(--color-primary) !important;
  color: var(--color-third) !important; }

.badge-danger {
  background-color: var(--color-primary);
  background-color: var(--color-third);
  border-bottom-right-radius: 15px; }

.main-menu .nav-mega .dropdown-menu .nav-link:hover {
  color: var(--color-primary) !important; }

.recommend-item .wish-list-icon {
  color: var(--color-primary); }

.recommend-item .price .price-lowest span {
  color: var(--color-primary);
  color: var(--color-third); }

.variant-panel .price-lg {
  color: var(--color-primary); }

.cart-pop-price {
  color: var(--color-primary); }

#couponCode:focus,
#customer-info-form input:focus,
#customer-info-form select:focus,
#customer-login-form input:focus,
#paymentForm input:focus {
  border-color: var(--color-primary); }

.badge-primary {
  background-color: var(--color-primary); }

.commerce-progress-bar .current i {
  color: var(--color-primary); }

.footer {
  border-top: 8px solid var(--color-primary); }

.page-header .title-header {
  color: var(--color-primary); }

.breadcrumb .label {
  color: var(--color-primary); }

.bg-primary {
  background-color: var(--color-primary) !important; }

.bg-secondary {
  background-color: var(--color-secondary) !important; }

.list-group-item.active {
  background-color: var(--color-primary); }

.form-control:active,
.phone-number:active {
  border: 1px solid #757575; }

.modal-backdrop {
  z-index: 1043; }

html {
  overflow-y: scroll;
  overflow-x: hidden;
  overscroll-behavior: none; }

body {
  overscroll-behavior: none;
  background-color: #f9f9f9;
  background-color: var(--color-body-background); }

a:hover, a:focus {
  color: var(--color-primary) !important; }

::selection {
  color: #ffffff;
  color: var(--white);
  background: var(--color-primary); }

* {
  font-family: 'Visby', sans-serif;
  letter-spacing: 0.5px;
  font-weight: normal;
  font-size: 1rem; }

.fz-15 {
  font-size: 15px; }

.nav-fix-top {
  background-color: #f9f9f9;
  background-color: var(--color-body-background); }

.nav-fix-top-mobile-placeholder {
  height: 165px; }

.fade-in {
  opacity: 1; }

.text-bold {
  font-weight: bold; }

.text-error {
  font-weight: 600;
  color: #af0016; }

.nav-fix-top-desktop-placeholder {
  height: 130px; }

.main-menu {
  min-height: 56px; }

.nav-item.nav-mega {
  display: flex;
  align-items: center; }

.nav-mega-list li {
  margin-right: 25px; }

.nav-mega-link-row {
  height: 310px !important; }

.main-menu .nav-mega .dropdown-menu .nav-mega-footer-row {
  position: absolute;
  margin-bottom: 0px !important;
  bottom: 0; }

.col-sec-list {
  height: 100%;
  border-right: 1px solid rgba(0, 0, 0, 0.09); }

.nav-sec-list {
  padding: 5px 5px; }
  .nav-sec-list:hover,
  .nav-sec-list .active {
    color: var(--color-primary); }
  .nav-sec-list a {
    color: black; }
    .nav-sec-list a:hover {
      color: var(--color-primary); }
  .nav-sec-list .fa-caret-right {
    display: none;
    margin-left: 3px; }

.custom-nav-bar-mega.nav-link {
  cursor: pointer; }

.dropdown--active .custom-nav-bar-mega.nav-link {
  color: var(--color-primary); }

.cart-dropdown-img {
  display: flex;
  justify-content: center;
  max-height: 80px; }

.__react_component_tooltip {
  text-align: center;
  font-size: 15px !important;
  padding: 20px 21px !important;
  width: 250px; }

.slider-pagination {
  background-color: black;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  transition: 0.3s ease;
  margin: 20px 12px; }
  .slider-pagination:hover {
    cursor: pointer;
    -webkit-transform: scale(1.2);
            transform: scale(1.2); }

.slider-pagination.active {
  background-color: var(--color-primary) !important;
  width: 45px;
  height: 20px;
  border-radius: 20px;
  transition: 0.3s ease-out; }

.rec-carousel-wrapper {
  width: calc(100% + 3rem) !important;
  margin-left: -1.5rem; }

.rec-carousel {
  height: 386px !important; }

.shop-side-bar .filter-title {
  padding-left: 0; }

.shop-side-bar .list-group-item {
  padding: 0.75rem 0 0.75rem 0.75rem;
  border: none;
  cursor: pointer;
  border-radius: 5px; }
  .shop-side-bar .list-group-item:hover:not(.active) {
    background-color: #ebebeb; }

.shop-side-bar .list-group-item.active::after {
  content: '';
  float: right;
  width: 6px;
  height: 9px;
  margin-top: 3px;
  margin-right: 10px;
  border-right: 3px solid #fff;
  border-bottom: 3px solid #fff;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

.shop-side-bar .btn-link {
  text-align: left; }

.shop-side-bar .btn-link:hover {
  -webkit-transform: none;
          transform: none; }

.shop-side-bar .btn-link::after {
  content: '+';
  color: #979797;
  margin-top: 12.5px;
  font-size: 1.5em;
  font-weight: 300;
  line-height: 0.5em;
  top: 9px;
  right: 9px;
  position: absolute;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out; }

.btn-link.rotated::after {
  -webkit-transform: rotate(-225deg);
          transform: rotate(-225deg); }

.modal-open {
  padding-right: 0 !important; }

.modal-steves {
  max-width: 100%;
  z-index: 2010; }
  .modal-steves .modal-dialog {
    max-width: 100%; }
  .modal-steves .modal-content {
    max-width: 650px;
    margin: auto;
    border-radius: 40px;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    outline: 0; }

.react-toast-notifications__container {
  z-index: 2050 !important; }

.noUi-connect {
  background-color: var(--color-primary) !important; }

.hover-underline:hover {
  text-decoration: underline;
  -webkit-text-decoration-color: black;
          text-decoration-color: black; }

.mobile-menu-container .user-section:after {
  top: 130px; }

.react-toast-notifications__toast {
  width: 100% !important;
  min-width: 360px !important; }

.btn .fa.fa-long-arrow-right {
  color: white;
  font-size: 14px; }

.mobile-menu-container .mega-menu ul li:after {
  background: none;
  border-top: 0.5px dashed grey; }

.mobile-menu-container .mega-menu .sub-menu {
  top: 170px; }

.scrollTop {
  cursor: pointer;
  position: fixed;
  z-index: 1041;
  width: 50px;
  height: 50px;
  right: 20px;
  bottom: 20px;
  box-shadow: 0 0 15px 0 rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  border: none; }

.main-menu-top .item:before {
  color: var(--color-primary);
  background-color: var(--color-primary); }

.no-vertical-line::before {
  background: transparent !important; }

@media (max-width: 600px) {
  .mb-sm-7 {
    margin-bottom: 3rem; }
  .awssld__bullets {
    display: none; }
  .m-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

.pull-left {
  float: left; }

.pull-right {
  float: right; }

:root {
  --color-primary: #fe6c1c!important;
  --color-secondary: #000000!important;
  --color-third: #6e702e!important;
  --color-icon: var(--color-third); }

.static-page h1,
.static-page h2,
.static-page h3,
.static-page h4,
.static-page h5,
.static-page h6 {
  font-family: 'Libre Baskerville', serif !important; }

.btn-secondary,
.btn-secondary:disabled {
  background-color: #6e702e !important;
  background-color: var(--color-third) !important;
  color: white !important;
  border: #6e702e !important;
  border: var(--color-third) !important; }

.btn-secondary:hover {
  background-color: #000000 !important;
  background-color: var(--color-secondary) !important;
  color: white !important;
  border: #000000 !important;
  border: var(--color-secondary) !important; }

.logo-wrapper {
  margin-bottom: -30px; }
  .logo-wrapper .theme-logo {
    height: 60px; }

.awssld__bullets--active {
  width: 45px !important; }

.m-logo {
  width: 150px;
  height: 49px; }

.body-cover {
  z-index: 1042; }

.shop-loading-icon img {
  position: fixed;
  bottom: auto; }

.shop-loading-icon::before {
  background: #fff;
  opacity: 0.8 !important;
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit; }

#product-container .shop-loading-icon img {
  top: calc(50vh + 32.5px); }

.desktop-popup-wrapper {
  width: 500px;
  min-height: 300px;
  z-index: 1400;
  position: fixed;
  background-color: white;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border: 1px solid #bebebe;
  left: auto;
  right: 24px;
  bottom: 24px; }
  .desktop-popup-wrapper .btn-close {
    width: 100%;
    position: absolute;
    padding: 10px;
    display: flex;
    justify-content: flex-end; }
  .desktop-popup-wrapper .img-wrapper {
    padding: 15% 10% 0; }
    .desktop-popup-wrapper .img-wrapper img {
      width: 100%;
      max-width: 300px;
      min-height: 100px; }
  .desktop-popup-wrapper .text1 {
    display: flex;
    justify-content: center;
    padding: 15px 30px; }
  .desktop-popup-wrapper .text2 {
    display: flex;
    justify-content: center;
    font-weight: bold;
    padding: 0 30px 15px 30px; }
  .desktop-popup-wrapper .btn-bookmark {
    width: calc(100% - 30px);
    margin: 0 15px 15px 15px;
    min-height: 45px; }

.ios-popup-wrapper {
  width: 230px;
  z-index: 2100;
  position: fixed;
  background-color: var(--color-dark-gray);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  border: 1px solid var(--light-grey);
  left: calc(50% - 115px);
  right: calc(50% - 115px);
  bottom: 30px;
  text-align: center;
  padding: 5px 0; }
  .ios-popup-wrapper .btn-close {
    position: absolute;
    background-color: var(--light-grey);
    right: 0;
    top: 0;
    height: 23px;
    color: white; }
  .ios-popup-wrapper .img-wrapper {
    padding-top: 5%;
    overflow: hidden; }
    .ios-popup-wrapper .img-wrapper img {
      border-radius: 14px;
      width: 80px;
      height: 80px; }
  .ios-popup-wrapper:after {
    content: '';
    width: 50px;
    height: 50px;
    position: absolute;
    bottom: -25px;
    background-color: inherit;
    z-index: -1;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    border: 1px solid var(--light-grey);
    border-top: none;
    border-left: none; }

.android-popup-wrapper {
  width: 230px;
  z-index: 2100;
  position: fixed;
  background-color: var(--color-dark-gray);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  border: 1px solid var(--light-grey);
  left: calc(50% - 115px);
  right: calc(50% - 115px);
  bottom: 30px;
  text-align: center;
  padding: 5px 0; }
  .android-popup-wrapper .btn-close {
    position: absolute;
    background-color: var(--light-grey);
    right: 0;
    top: 0;
    height: 23px;
    color: white; }
  .android-popup-wrapper .img-wrapper {
    padding-top: 5%;
    overflow: hidden; }
    .android-popup-wrapper .img-wrapper img {
      border-radius: 14px;
      width: 80px;
      height: 80px; }
  .android-popup-wrapper p.small {
    border-top: 1px solid var(--color-third);
    padding-top: 5px;
    font-size: 8px; }

