.main-menu {

    min-height: 56px;
    position: relative;
    border-top: 1px black solid;
    border-bottom: 1px black solid;
    // &:before {
    //   content:    "";
    //   position:   absolute;
    //   top:        0;
    //   left:       0;
    //   //background: url('../img/dotted-line-horizontal.svg') repeat-x;
    //   // background: var(--color-info);
    //   background: var(--color-primary);
    //   width:      100%;
    //   height:     1px;
    // }
  
    // &:after {
    //   content:    "";
    //   position:   absolute;
    //   bottom:     0;
    //   left:       0;
    //   //background: url('../img/dotted-line-horizontal.svg') repeat-x;
    //   //   background: var(--color-info);
    //     background: var(--color-primary);
    //     width:      100%;
    //   height:     1px;
    // }
  
    .nav-link {
        font-size: 16px;
        padding-left:1rem;
        color: black;
        padding-right:1rem;
        @media (max-width: 1250px) {
            font-size: 14px;
            letter-spacing: 0;
        }
    }
  
    .fa-caret-down {
      font-size: 12px;
    }
  
    .nav-navbar {
      width: 65%;
      @media (max-width: 1250px) and (min-width: 992px) {
        width: 70%;
      }
    }
  
    .search {
      width:         35%;
      @media (max-width: 1250px) and (min-width: 992px) {
        width: 30%;
      }
  
      background:    #e6e6e6;
      border-radius: 5px;
  
      .search-text {
        background: none !important;
        border:     none !important;
        color: var(--color-gray);
        &:focus{
          color: #263b46;
        }
          padding-right: 0;
          @media (max-width: 1100px) {
              font-size: 14px;
              letter-spacing: 0;
          }
          @media (max-width: 991px) {
              font-size: 12px;
              letter-spacing: 0;
          }
  
        &::placeholder {
          color: var(--color-dark);
        }
      }
  
      .input-group-text {
        background: none !important;
      }
    }
  
    .nav-mega {
      .dropdown-menu {
        background:    #e6e6e6 !important;
        padding:       0;
        min-width:     900px;
        width:         calc(1280px / 12 * 10 - 30px);
        border-radius: 30px;
        overflow:      hidden;
        height: 	 	 408px;
        .title {
          font-family: var(--font-family-title);
          font-weight: 700;
          font-size:   30px;
        }
  
          .nav-mega-link-row{
              height: 274px;
          }
  
          .nav-mega-footer-row{
              margin-bottom: -50px!important;
              a{
                  font-size: 16px;
              }
          }
  
        .nav-link {
          font-size:      14px;
          font-weight:    400;
          color:          black;
          text-transform: none;
          padding:        0;
          &:hover{
              color: var(--color-primary);
          }
        }
  
        .col-md-4 {
          &:after {
            padding:    0 40px 0 0;
            content:    "";
            position:   absolute;
            top:        0;
            right:      0;
            // background: url(../img/dotted-line-vertical.svg) repeat-y;
            width:      1px;
            height:     100%;
            opacity:    0.2;
          }
  
          &:last-child {
            &:after {
              content: none;
            }
          }
        }
  
        .product-item {
          text-align: center;
  
          .title {
            font-size:   16px;
            font-weight: 700;
            color:       var(--color-dark);
            margin:      0;
          }
  
          .subtitle {
            font-weight: 400;
            color:       var(--color-dark);
            margin:      0;
          }
  
          .price {
            color:  var(--color-dark);
            margin: 0;
          }
  
          p {
            margin-bottom: 0;
          }
        }
  
        .slick-slider {
          img {
            height: 200px;
          }
        }
  
        .slider-dots-line {
          .slick-dots {
            .slick-active {
              button {
                width:            30px;
                background-color: var(--color-info);//#000000;
              }
            }
  
            li {
              button {
                background-color: var(--white);
                width:            30px;
                height:           5px;
              }
            }
          }
        }
  
        .nav-mega-list {
          height: 200px;
  
          li {
            height: 20px;
              a:hover{
                  color:var(--color-primary);
                  font-weight: 600;
              }
          }
        }
      }
  
      .dropdown-toggle[aria-hidden="false"] {
        display: block !important;
        height:  auto;
      }
  
      .dropdown-toggle[aria-hidden="true"] {
        display:  block !important;
        height:   0;
        overflow: hidden;
      }
    }
  }

.main-menu-top {
    position: relative;
    .item {
      padding-left: 10px;
      position:     relative;
      width:        250px;
  
      &:first-child {
        width:       300px;
        margin-left: auto;
      }
  
      &:last-child {
        width: 150px;
      }
  
      &:before {
        content:    "";
        position:   absolute;
        top:        0;
        left:       -3px;
        //background: url('../img/dotted-line-vertical.svg') repeat-y;
        background: var(--color-primary);
        width:      1px;
        height:     100%;
      }
  
      .icon {
        height: 100%;
  
        img {
          vertical-align: middle;
        }
      }
  
      .details {
        font-size: 1rem;
        .title {
          margin-bottom: 0;
          line-height:   1;
          color:         var(--color-dark);
          font-family:    var(--font-family-number);
          // font-family:   var(--font-family-base);
        }
  
        small {
          display:    block;
          margin-top: 1px;
        }
      }
    }
  
    .cart-icon {
      min-width: 25px;
  
      span {
        color:            #ffffff;
        font-family:      var(--font-family-title);
        font-size:        12px;
        line-height:      18px;
        text-align:       center;
        display:          block;
        position:         absolute;
        top:              -8px;
        right:            85px;
        border-radius:    50%;
        height:           18px;
        width:            18px;
        background-color: #fa6e6e;
      }
    }
  }


.ilg-logo {
    margin-left: auto;
    margin-right: auto;
    &:before {
        content: none !important;
    }
}

.nav-link-block {
    color: var(--color-third);

    &:hover {
        color: var(--color-primary);
    }
}

@media (max-width: 1250px) {
    .main-menu .nav-link {
        font-size: 14px;
        letter-spacing: 0;
    }
}
