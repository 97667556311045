.mobile-menu-backdrop {
    z-index: 1031;
  }
  
  .nav-fix-top-mobile-placeholder {
    display: block;
    //@media(min-height: 678px)and(min-width: 992px) {
    //  display: none;
    //}
    height:62px;
  }
  
  .header-mobile {
    display: block;
    //@media(min-height: 678px)and(min-width: 992px) {
    //  display: none;
    //}
    &:after{
      content: "";
      position: absolute;
      top: 62px;
      left: 0;
      width: 100%;
      height: 1px;
    }
    .searchPhrase{
      height: 38px;
    }
    #search-btn-mobile{
      img{
        height: 20px;
      }
    }
  
    .cart-icon {
      span {
        color:            #ffffff;
        font-family:      'Roboto';
  
        font-size:        12px;
        line-height:      18px;
        text-align:       center;
        display:          block;
        position:         absolute;
        top:              -7px;
        right:            -10px;
        border-radius:    50%;
        height:           18px;
        width:            18px;
        background-color: #fa6e6e;
      }
    }
  
    z-index: 1043;
  
    .title {
      height:       17px;
      color:        var(--color-dark);
      font-family:  var(--font-family-base);
  
      font-size:    12px;
      font-weight:  bold;
      line-height:  17px;
      text-shadow:  0.5px 1px 0 0 var(--white);
    }
  
    .mobile-title{
      letter-spacing: -1px;
      font-size: 10px;
    }
  
    .mobile-title-menu{
      margin-top: -8px;
      font-size: 10px;
      margin-bottom: 0;
    }
  }
  
  .mobile-menu-container {
    margin-top: 64px;
  
    .locationIcon{
      height: 48px;
      img{
        padding-left: 15px;
      }
    }
    .search {
      max-width:    calc(100vw - 1rem);
      margin-right: auto;
      margin-left:  auto;
  
      input {
        height:           48px;
        border-radius:    4px;
        background-color: #e6e6e6;
  
        &::placeholder {
          height:       19px;
          width:        253px;
          color:        #263b46;
          font-family:  var(--font-family-base);
  
          font-size:    14px;
          line-height:  19px;
        }
      }
  
      .input-group-text {
        background-color: #e6e6e6;
  
        img {
          height: 30px;
        }
      }
    }
  
    .user-section {
      &:after {
        content:    "";
        position:   absolute;
        top:        190px;
        left:       0;
        width:      100%;
        height:     1px;
      }
  
      img {
        width:        36px;
        height:       36px;
        margin-right: auto;
        margin-left:  auto;
      }
  
      p {
        height:        19px;
        color:         #263b46;
        font-family:   var(--font-family-base);
  
        font-size:     14px;
        font-weight:   bold;
        line-height:   19px;
        text-shadow:   0.5px 1px 0 0 #ffffff;
        margin-bottom: 0;
  
        a {
          color: inherit;
        }
      }
  
      a {
        height:       19px;
        width:        46px;
        color:        var(--color-primary);
        font-family:  var(--font-family-base);
  
        font-size:    14px;
        line-height:  19px;
        text-shadow:  0.5px 1px 0 0 #ffffff;
      }
    }
  
    .mega-menu{
      ul{
        list-style: none;
        margin-bottom: 0;
        padding-left: 0;
        li{
          a{
            color:inherit;
          }
          display: flex;
          justify-content: space-between;
          padding: 0 24px;
          outline: none;
          font-size: 20px;
          line-height: 48px;
          font-weight: 100;
          text-shadow: 0 0 1px rgba(255,255,255,0.1);
          color: #6b6f83;
          -webkit-transition: background 0.3s, box-shadow 0.3s;
          -webkit-transition: background 0.3s, -webkit-box-shadow 0.3s;
          transition: background 0.3s, -webkit-box-shadow 0.3s;
          transition: background 0.3s, box-shadow 0.3s;
          transition: background 0.3s, box-shadow 0.3s, -webkit-box-shadow 0.3s;
          text-decoration: none;
          position: relative;
          i{
            float: right;
            padding: 3px 15px 3px 20px;
            &::before{
              line-height: 48px;
            }
            //&:after{
            //  content: "";
            //  position: absolute;
            //  top: 4px;
            //  left: 50%;
            //  background: url(../img/dotted-line-vertical.svg) repeat-y;
            //  width: 1px;
            //  height: calc( 100% - 8px );
            //}
            //&.no-border{
            //  &:after{
            //    content: none;
            //  }
            //}
          }
          &:after{
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
          }
        }
      }
      .sub-menu{
        position: absolute;
        top: 194px;
        left: -100%;
        width: 100%;
        height: 100%;
        padding: 0;
        transition: 0.5s ease-in-out;
        background-color: #f8f9fa;
        //-webkit-transform: translate3d(-100%, 0, 0);
        //transform: translate3d(-100%, 0, 0);
        &.open{
          left: 0;
          //transform: translate3d(0px, 0, 0);
        }
        z-index: 1042;
        &.level-2{
          top:0;
          z-index: 1043;
        }
        .sub-menu-back{
          &:nth-child(1)
          span{
            font-weight: 600;
          }
        }
      }
    }
  }