////----------------------------------------
// Footer
////----------------------------------------
// .error-message-mail-chimp{
//     color: #af0016;
// }

.footer {
  padding: 50px 0 20px;
  color: var(--white);

  a{
    color: inherit;
    &:hover{
      color: var(--color-primary);
    }
  }

  .title {
    color: var(--color-light);
  }
  
  .nav-link {
    color: var(--color-light);
  }

  .fa {
    color: var(--color-light);
  }

  small {
    color:       var(--color-light);
    display:     inline-block;
    line-height: 1.5;
  }
  
  .newsletter {
    .input-group {
        border: none;
    }
  }
  
  .footer-bottom {
    
    p {
      color:     #d8d8d8;
      font-size: 12px;
    }
    
    .nav-link {
      color:       #d8d8d8;
      font-size:   12px;
      font-weight: 700;
    }
    color: var(--white);
    a {
        font-size: 14px;
        color: var(--grey);
        &:hover {
            color: var(--color-primary) !important;
        }
    }
  }

  .footer-wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;

      // a {
      //   font-size: 14px;
      //   color: var(--white);
      //   &:hover {
      //       color: var(--color-primary) !important;
      //   }
      // }

      .col-wrapper {
          padding: 0 30px 0 0;
      }

      .title {
          font-weight: bold;
          padding: 10px 0;
          text-transform: uppercase;
      }

      .body {
          font-size: 14px;
          padding-bottom: 10px;
      }

      .about {
          width: 40%;
          padding-right: 20px;
      }

      .links {
          width: 15%;
      }
      .hours {
          width: 25%;
      }
      .contact {
          width: 20%;
      }
  }
}