//----------------------------------------
// cart popup
//----------------------------------------
.cart-popup {
    right:            0;
    bottom:           0;
    //width:462px;
    height:           100%;
    border-radius:    0;
    box-shadow:       0 2px 4px 0 rgba(0, 0, 0, 0.5);
    border:           none;
  
    background-color: var(--color-light);
    //overflow-y: scroll;
  }
  
  .cart-pop-title {
    background-color: var(--color-bg-dark);
    height:           77px;
  }
  
  .cart-pop-title p {
    font-size:   20px;
    font-weight: bold;
    color:       var(--color-primary);
    margin:      auto 15px;
  }
  
  .cart-prod-title {
    white-space: pre-wrap;
    color:       var(--color-dark);
  }
  
  .cart-pop-img {
    //margin:0 30px;
    border: 1px solid var(--grey);
    width:  100%;
  }
  
  .cart-pop-price {
    color: var(--color-primary);
    //font-weight:bold;
  }
  
  .cart-pop-view {
    border:       2px solid $black;
    font-family:  var(--font-family-base);
  
    font-size:    13px;
    font-weight:  bold;
    height:       39px;
  }
  
  .cart-pop-checkout, .cart-pop-view:hover {
    border:           2px solid var(--color-primary);
    background-color: var(--color-primary);
    color:            var(--white);
    font-family:      var(--font-family-base);
  
    font-size:        13px;
    font-weight:      bold;
    height:           39px;
  }
  
  .cart-pop-close {
    color:     var(--white);
    font-size: 150%;
  }
  
  .cart-pop-variant {
    font-size:     13px;
    color:         #929daf;
    line-height:   1.4;
    margin-bottom: 0;
  }