.products-slider {
    .title {
        font-family: var(--font-family-special);
        font-size: 28px;
        font-weight: 600;
        color: var(--black);
        letter-spacing: 6.5px;
        position: relative;
        @include media-breakpoint-down(md) {
            font-size: 20px;
            letter-spacing: 3px;
        }

        &:before {
            content: '';
            position: absolute;
            top: 20px;
            left: 130px;
            background: var(--color-primary);
            width: 250px;
            height: 4px;
            border-radius: 2px;
            @include media-breakpoint-down(lg) {
                content: none;
            }
        }

        &:after {
            content: '';
            position: absolute;
            top: 20px;
            right: 130px;
            background: var(--color-primary);
            width: 250px;
            height: 4px;
            border-radius: 2px;
            @include media-breakpoint-down(lg) {
                content: none;
            }
        }
    }
}
