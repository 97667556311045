.scrollTop {
    position: fixed; 
    height: 50px;
    right: 20px;
    bottom: 20px;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    opacity: 0.5;
    border-radius: 50%;
    box-shadow: 0 0 15px 0 var(--color-primary);
  
    &:hover{
        opacity: 1;
    }
      
    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0.5;
        }
    }
  }