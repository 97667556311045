.link-block {
    display: flex;
    justify-content: space-around;
    align-items: center;

    background-color: #e6e6e6;
    border-radius: 4px;
    padding: 5px 15px;
    margin-left: 15px;
    font-weight: bold;
    &:hover {
        color: var(--color-third);
    }
}
