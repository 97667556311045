.boxes-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.box-wrapper {
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16), 0 2px 4px 0 rgba(0, 0, 0, 0.12);
    background-clip: border-box;
    padding: 0 30px 10px 30px;
    max-height: 127px;
}

.image-wrapper {
}

.image-text {
    text-align: center;
    color: var(--color-primary); //silver;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    border-width: 0 !important;
}
