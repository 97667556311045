////----------------------------------------
// General Classes
////----------------------------------------
body {
    background: #f1f1f1;
}
.body-cover{
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: var(--white);
  z-index: 10000;
  display: flex;
  img{
      justify-content: center;
        align-items: center;
      height: 65px;
      width: auto;
      box-shadow: 0 0 30rem 10rem rgba(0,0,0,0.1);
      border-radius: 50%;
  }
  p{
      font-size: 16px;
  }
}

.text-green{
  color:  #1bb24b !important
  
  
}
.back-to-top-button {
    display:          none;
    position:         fixed;
    bottom:           20px;
    right:            20px;
    font-size:        50px;
    background-color: transparent;
    width:            50px;
    height:           auto;
}

.container {
max-width: 1280px;
}

@for $i from 1 through 90 {
.max-w-#{$i} {
  max-width: #{$i}em !important;
}

.max-h-#{$i} {
  max-height: #{$i}em !important;
}

.min-w-#{$i} {
  min-width: #{$i}em !important;
}

.min-h-#{$i} {
  min-height: #{$i}em !important;
}
}

.text-white{
  color: var(--white) !important;
}

.nav-navbar {
.arrow {
  display:       inline-block;
  width:         0;
  height:        0;
  margin-bottom: 2px;
  border-left:   3px solid transparent;
  border-right:  3px solid transparent;
}
}

.dropdown-toggle {
&::after {
  content: none !important;
}
}

// .divider-line {
//     position:   relative;
//     display:    inline-block;
//     padding:    5px 0;
//     width:      100%;
//     height:     1px;
//     opacity:    0.2;
// }

.page-header {
text-align: center;
margin-top: 0;

.title-header {
  text-transform: uppercase;
  text-align:     center;
  color:          var(--color-primary);
  font-family:    var(--font-family-special);

  font-size:      36px;
  font-weight:    900;
  letter-spacing: -0.68px;
  line-height:    52px;
}

.short-description {
  white-space:    pre-line;
  margin:         auto;
  color:          #070707;
  font-family:    var(--font-family-base);

  font-size:      16px;
  letter-spacing: -0.3px;
  line-height:    22px;
  text-align:     center;
}
}

.breadcrumb {
margin:          auto;
text-align:      center;
display:         flex;
justify-content: center;

.link {
  display: inherit;
  height:  18px;

  a {
    text-transform: uppercase;
    color:          #070707;
    font-family:    var(--font-family-special);

    font-size:      12px;
    font-weight:    bold;
    letter-spacing: 1.2px;
    line-height:    18px;
    text-align:     center;
  }
}

.label {
  text-transform: uppercase;
  height:         18px;
  color:          var(--color-primary);
  font-family:    var(--font-family-special);

  font-size:      12px;
  font-weight:    bold;
  letter-spacing: 1.2px;
  line-height:    18px;
  text-align:     center;
}

.arrow-right-simple-line {
  margin:         0 10px;
  height:         10px;
  color:          #070707;
  font-size:      12px;
  letter-spacing: -0.19px;
  line-height:    18px;
  text-align:     center;
}
}

.theme-logo {
    max-height: 120px;
  width: 398px;
  height: 125px;
  &-fix{
      width: 180px;
      height: auto;
  }
}

.theme-logo-fix{
  transform: scale(1.4);
}

//hide slick slider if the slider hasn't been initiated
.slick-slider {
display: none;

&.slick-initialized {
  display: block;
}
}

.slick-slide {
display: none;

img {
  height: 0;
}
}

.slick-initialized {
.slick-slide {
  //display: block;

  img {
    height: auto;
  }
}
}

.foot-notes{
  color: #bbbbbb;
  font-size: 11px;
  letter-spacing: 0;
  margin-top: 50px;
}

.tooltip{
  z-index: 1042;
}

.text-underline{
  text-decoration: underline!important;
}

#toTop{
  cursor: pointer;
  position: fixed;
  z-index: 1041;
  height: 50px;
  right: 20px;
  bottom: 20px;
  box-shadow:  0 0 15px 0 rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  border: none;
  display: none;
}

.loading{
  //not supported by Opera Mini and IE 10 and below
  //cursor: wait;
  .card-item{
      cursor: wait!important;
  }
}

.custom-control-label::before {
  border: 1px solid #555555;
}

.mb--50{
  margin-bottom: -50px!important;
}

////----------------------------------------
// Menu System
////----------------------------------------


.nav-fix-top {
//display: none;
//@media (min-height: 678px) and (min-width: 992px) {
  display:          block;
  //}
  position:         fixed;
  width:            100%;
  top:              0;
  z-index:          1043;
  background-color: #f1f1f1;
}

.nav-fix-top-desktop-placeholder {
//display: none;
//@media (min-height: 678px) and (min-width: 992px) {
display: block;
//}
height:  137px;
  @media (max-width: 1020px) and (min-width: 650px){
      height: 187px;
  }
  @media (max-width: 649px) {
      height: 205px;
  }
}

.menu-wrapper {
    margin-top: 10px;
    position:   relative;
    display:    block;
    width:      30px;
    height:     30px;
    cursor:     pointer;
}

.main-menu-mobile {
    overflow-y:    scroll;
    position:      fixed;
    left:          -150%;
    z-index:       1042;
    width:         100%;
    height:        100%;
    border-bottom: 1px solid var(--color-dark);
    transition:    1.35s cubic-bezier(var(--bouncey));
    background:    var(--color-light);
    box-shadow:    4px 6px 8px -4px rgba(0, 0, 0, 0.75);
//@include media-breakpoint-up(lg) {
  //display: none;
//}

&.active {
  left:       0%;
  transition: 0.35s cubic-bezier(var(--sweepinginout));
}

.nav-link {
  color:       var(--color-primary);
  font-weight: 400;

  &:hover {
    &:after {
      background:        var(--grey);
      width:             30px;
      height:            1px;
      content:           "";
      position:          absolute;
      bottom:            10px;
      left:              49%;
      -webkit-transform: translateX(-50%);
      transform:         translateX(-50%)
    }
  }
}

.nav-item {
  .nav {
    border:        1px solid var(--grey);
    border-radius: 0;
    background:    var(--color-dark);
    position:      absolute;
    padding:       40px 0;
    left:          -55px;

    &:before {
      position:   absolute;
      top:        0;
      left:       50%;
      height:     20px;
      width:      1px;
      content:    "";
      background: var(--grey);
    }

    &:after {
      position:   absolute;
      bottom:     0;
      left:       50%;
      height:     20px;
      width:      1px;
      content:    "";
      background: var(--grey)
    }

    .nav-link {
      font-weight: 400;
      color:       var(--color-dark);
      opacity:     1;

      &:hover {
        text-decoration: underline !important;

        &:after {
          content: none !important;
        }
      }
    }
  }
}
}

////----------------------------------------
// Buttons
////----------------------------------------
.btn {
  font-family:    var(--font-family-special);
  font-weight:    400;
  letter-spacing: 2px;
  font-size:      12px;
  @include media-breakpoint-down(md) {
    letter-spacing: 1px;
    font-size:      10px;
    padding:        6px 20px 5px;
  }
  text-transform: uppercase;
  border-radius:  0;
  cursor: pointer;
}

.btn-link {
  font-family:    var(--font-family-special);
  font-size:      14px;
  text-transform: uppercase;
  color:          var(--color-dark);
  transition:     all 0.5s ease-out !important;

  &:hover {
    color:           var(--color-dark);
    text-decoration: none;
    transform:       translate(-2px, -2px);
    transition:      all 0.5s ease-out !important;
  }
}

.button-unstyled {
  -moz-appearance:         none;
  -webkit-appearance:      none;
  -ms-progress-appearance: inherit;
  font-size:               100%;
  font-family:             inherit;
  border:                  0;
  padding:                 0;
}

////----------------------------------------
// Icons
////----------------------------------------
// .arrow-right {
// // background: url(../img/arrow-right.svg) no-repeat;
// width:      20px;
// height:     13px;
// overflow:   hidden;
// display:    inline-block;
// transform:  translateY(2px);
// }

// .arrow-right-white {
// // background: url(../img/arrow-right-white.svg) no-repeat;
// width:      20px;
// height:     13px;
// overflow:   hidden;
// display:    inline-block;
// transform:  translateY(2px);
// }

// .icon-beer-deliver {
// // background:    url(../img/icon-beer-deliver.svg) no-repeat;
//     width:         81px;
//     height:        67px;
//     margin-top:    2px;
//     margin-bottom: 2px;
//     overflow:      hidden;
//     display:       inline-block;
// }

// .icon-pick-chilled {
// // background:    url(../img/icon-pick-chilled.svg) no-repeat;
// width:         83px;
// height:        65px;
// margin-top:    3px;
// margin-bottom: 3px;
// overflow:      hidden;
// display:       inline-block;
// }

// .icon-tastings {
// // background: url(../img/icon-tastings.svg) no-repeat;
// width:      63px;
// height:     71px;
// overflow:   hidden;
// display:    inline-block;
// }

////----------------------------------------
// Products
////----------------------------------------


////----------------------------------------
// Sections
////----------------------------------------
.latest-news {
.title {
  font-family:    var(--font-family-special);

  font-size:      28px;
  font-weight:    900;
  text-transform: uppercase;
  color:          var(--color-dark);
  letter-spacing: 6.5px;
  position:       relative;

  &:before {
    content:    "";
    position:   absolute;
    top:        20px;
    left:       3%;
    width:      215px;
    height:     1px;
    @include media-breakpoint-down(lg) {
      content: none;
    }
  }

  &:after {
    content:    "";
    position:   absolute;
    top:        20px;
    right:      3%;
    width:      215px;
    height:     1px;
    @include media-breakpoint-down(lg) {
      content: none;
    }
  }

}

.item {
  text-align: center;

  small {
    font-size:   12px;
    font-family: var(--font-family-special);

  }

  .title {
    font-size:      18px;
    text-transform: none;
    letter-spacing: 1px;

    &:before, &:after {
      content: none !important;
    }
  }

  .image {
    width:   100%;
    height:  246px;
    display: inline-block;
  }
}
}

.features {
.item {
  .title {
    font-family: var(--font-family-special);

    color:       var(--color-dark);
  }
}
}

.ff-errors {
color: #8e4646;
background-color: #fee2e2;
border-color: #fed6d6;
}

.ff-form-success {
color: #2c7b3a;
background-color: #d8f6de;
border-color: #c8f3d0;
}
////----------------------------------------
// Contact Page
////----------------------------------------
.contact-left-panel{
  min-height: 487px;
}

////----------------------------------------
// Hero Banner
////----------------------------------------
.hero-slider{
  .slick-dots {
      .slick-active {
          button {
              width: 80px;
              background-color: var(--color-primary);
          }
      }

      li {
          button {
              background-color: var(--color-info);//#000000;
              width: 80px;
              height: 5px;
          }
      }
  }
}