//--------------------------------------------------------------------------
// Commerce - critical global class
//--------------------------------------------------------------------------
  
  .square {
    position:       relative;
    display:        block;
    height:         0;
    width:          100%;
    padding-bottom: 100%;
  
    .lazy {
      //object-fit: fill;
      position:            absolute;
      top:                 0;
      left:                0;
      right:               0;
      bottom:              0;
      background-repeat:   no-repeat;
      background-size:     contain;
      background-position: center center;
    }
  }
  
  //--------------------------------------------------------------------------
  // Commerce - order
  //--------------------------------------------------------------------------
  
  .favicon-check-circle {
    color:        var(--green) !important;
    font-size:    50px;
    margin-right: 5px;
  }
  .order-table{
      img{
          max-height: 80px;
      }
  }
  
  //--------------------------------------------------------------------------
  // Commerce - Shop/index
  //--------------------------------------------------------------------------
  
  .border-none {
    .product-border {
      border: none !important;
    }
  }
  
  .discount-icon{
      height: 24px!important;
      width: auto;
      margin-top: -11px;
      margin-right: 3px!important;
  }
  .discount-icon-container{
      position: absolute;
      bottom:-16px;
      left: -16px;
  }
  
  //#collapse-filter-button{
  //	position: fixed;
  //	z-index: 1030;
  //	top: 110px;
  //	border-radius: 15px;
  //	width: 30%;
  //	left: 35%;
  //	right: 35%;
  //}
  
  .filter-box{
      .card-body {
          padding:0;
      }
  
    &.accordion-arrow-right {
      .card-title a {
        padding-left:  0;
        padding-right: 0;
  
        &::before {
          float:        right;
          margin-left:  0;
          margin-right: 3px;
          margin-top:   10px;
        }
      }
    }
  
    &.accordion {
      .card-title {
        padding: 10px;
      }
  
      .card {
        margin-bottom:    0;
        background-color: #fff;
        border:           none !important;
        //margin-bottom: 16px;
  
        &:last-child {
          margin-bottom: 0;
        }
      }
  
      .card-title a {
        display: block;
        padding: 0;
        color:   var(--color-secondary);
  
        // hide "Thesass" original display
        &::before {
          display: none;
        }
  
        &.collapsed::before {
          display: none;
        }
  
        //my new display
        &::after {
          content:     "+";
          color:       #979797;
          margin-top:  4.5px;
          font-size:   1.5em;
          font-weight: 300;
          line-height: 0.5em;
          float:       right;
          transform:   rotate(45deg);
          transition:  transform 0.3s ease-in-out;
        }
  
        &.collapsed::after {
          transform: rotate(-180deg);
        }
  
      }
  
      .card-item-form {
        margin-top:       5px;
        margin-bottom:    5px;
        cursor:           pointer;
        padding:          10px;
        border-radius:    5px;
        background-color: inherit;
      }
  
      .card-item {
        margin-top:       5px;
        margin-bottom:    5px;
        cursor:           pointer;
        padding:          10px;
        border-radius:    5px;
        background-color: inherit;
        &.no-active{
            background-color: inherit!important;
            &::after{
                content: none!important;
            }
        }
  
        &.active {
          background-color: #ebebeb;
  
          &::after {
            content:       '';
            float:         right;
            display:       inline-block;
            width:         6px;
            height:        9px;
            margin-top:    3px;
            margin-right:  5px;
            border-right:  1px solid #a5a5a5;
            border-bottom: 1px solid #a5a5a5;
            transform:     rotate(45deg);
          }
        }
      }
  
    }
  
    box-shadow:       0 2px 4px 0 rgba(0, 0, 0, 0.5);
    margin-bottom:    30px;
    padding:          10px;
    border:           1px solid #bebebe;
    border-radius:    5px;
    background-color: #fff;
  
    .searchString {
      .icon-close {
        cursor:      pointer;
        font-size:   24px;
        font-weight: 300;
        font-family: serif;
  
      }
    }
  
    .filter-title {
      color:          var(--color-dark);
      font-size:      16px;
      font-weight:    bold;
      letter-spacing: -0.3px;
      font-family:    var(--font-family-title);
  
      line-height:    22px;
      //margin-bottom: 18px;
    }
  
    .filter-content {
      line-height: 19px;
      color:       #3e3e3e;
      font-family: var(--font-family-title);
  
      font-size:   16px;
    }
  
    .filter-category-number {
      color: #3e3e3e;
    }
  
    .custom-radio .custom-control-label {
      line-height: 24px;
  
      &::after {
        background-color: var(--color-secondary);
      }
    }
  
    .custom-checkbox .custom-control-label {
      &::after {
        top:               50%;
        left:              7px;
        margin-top:        -6px;
        width:             6px;
        height:            10px;
        border:            solid var(--color-secondary);
        border-width:      0 1.4px 1.4px 0;
        -webkit-transform: scale(0) rotate(35deg);
        transform:         scale(0) rotate(35deg);
      }
  
      &::before {
        height:           20px;
        width:            20px;
        border:           1px solid #979797;
        border-radius:    3px;
        background-color: #ffffff;
      }
    }
  }
  
  //--------------------------------------------------------------------------
  // Commerce - checkout/customer-info
  //--------------------------------------------------------------------------
  .bg-jumbotron {
    background-color: #bfbfbf !important;
  }
  
  //--------------------------------------------------------------------------
  // Commerce - checkout/delivery
  //--------------------------------------------------------------------------
  .transportation {
    .charges {
      &:hover {
        border: 2px solid var(--blue);
      }
  
      border:        2px solid var(--grey);
      padding:       15px 20px;
      cursor:        pointer;
      margin-bottom: 30px;
  
      &.selected-delivery {
        border: 2px solid var(--blue) !important;
      }
  
      .deli-charges {
        color:        #222222;
        font-size:    14px;
        font-weight:  600;
        float:        right;
        width:        auto;
        border-left:  1px solid #e5e5e5;
        padding-left: 3px;
      }
  
      h6 {
        margin:      0 5px 0 0;
        font-weight: 600;
        display:     inline-block;
        font-size:   16px;
        color:       #555555;
        cursor:      pointer;
      }
  
      span {
        cursor: pointer;
        color:  #888888;
      }
  
      label {
        cursor: pointer;
      }
    }
  }
  
  //--------------------------------------------------------------------------
  // Commerce - checkout/payment
  //--------------------------------------------------------------------------
  .payment-product-box {
    padding: 10px 10px;
  
    .description {
      line-height: 14px;
    }
  
    .price {
      padding-left: 10px;
      font-weight:  600;
      border-left:  1px solid var(--color-secondary);
      // @include media-breakpoint-down(sm) {
      //   margin: 0;
      // }
    }
  }
  
  .payment-product-adjustments {
    font-size:  20px;
    margin-top: 10px;
  }
  
  .stripe-form {
    width: 100% !important;
    //margin:20px 0 20px 0 !important;
  }
  
  .card-errors, .info-errors {
    color: var(--red);
  }
  
  .StripeElement {
    height:             45px;
    padding:            12.5px 12px;
  
    background-color:   var(--color-bg-body) !important;
    border:             1px solid #c5c5c5 !important;
  
    box-shadow:         0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition:         box-shadow 150ms ease;
  }
  
  .StripeElement--focus {
    border:     1px solid var(--color-primary) !important;
    box-shadow: 0 1px 3px 0 #cfd7df;
  }
  
  .StripeElement--invalid {
    border-color: #fa755a;
  }
  
  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }
  
  //--------------------------------------------------------------------------
  // Commerce - Header - cart drop down
  //--------------------------------------------------------------------------
  .table-cart.table-cart-dropdown {
    border: none;
  }
  
  .table-cart-dropdown {
    border-top: none;
  }
  
  .cart-dropdown-scale {
    //transform: scale(0.8) !important;
    //transform-origin: right top !important;
    width:   450px;
    z-index: var(--zindex-select-dropdown);
  
    @include media-breakpoint-down(md) {
      width: 290px;
    }
  }
  
  .cart-dropdown-empty {
    width: 180px;
  }
  
  .table-cart.table-cart-dropdown tr td {
    padding: 10px;
  }
  
  .nav-widget {
    width: 100%;
  }
  
  .padding-r-0 {
    padding-right: 0
  }
  
  .fa-shopping-cart {
    pointer-events: none;
  }
  
  .search-bar {
    transform: scale(0.7) !important;
  }
  
  .btn-search {
    background-color: var(--white);
  }
  
  //--------------------------------------------------------------------------
  // Commerce - Product Detail
  //--------------------------------------------------------------------------
  .product-detail-brand {
        font-size: 20px;
      font-weight: 600;
    //text-transform: uppercase;
  }
  
  .product-detail-brand-link{
      max-height: 36px;
      margin-left: 30px;
  }
  
  .saleInformation {
    margin-bottom:  20px;
    width:          100%;
    color:          #919191;
    font-family:    var(--font-family-base);
  
    font-size:      14px;
    font-weight:    600;
    letter-spacing: -0.26px;
    line-height:    19px;
    text-align:     left;
    transition:     transform 0.5s ease-in-out;
  
    span {
      margin-left: 5px;
    }
  }
  
  .product {
    &-detail {
      text-align: left;
  
      h1 {
        font-size:      1.875rem;
        opacity:        .9;
        letter-spacing: -0.6px
      }
  
      h2 {
        font-size:      1rem;
        font-family:    var(--font-family-base);
  
        opacity:        .9;
        letter-spacing: -0.34px;
        line-height:    22px;
  
        &.value {
          color: var(--color-light-grey) !important;
        }
      }
  
      .tag-links a {
        font-size:      .75rem;
        opacity:        .9;
        line-height:    17px;
        letter-spacing: -.26px;
        color:          var(--color-light-grey)
      }
  
      &-description {
        font-size:      1.125rem;
        opacity:        .9;
        letter-spacing: -.39px;
        line-height:    24px
      }
    }
  
    &-terms-conditions {
      font-size:      0.875rem;
      color:          var(--color-light-grey);
      letter-spacing: -0.3px;
      line-height:    19px;
      opacity:        0.9
    }
  }
  
  #add-to-cart-button {
    min-width: 166px;
  }
  
  .variant-panel {
    $color: #4a4a4a;
  
    .btn {
      padding:        8px 15px;
      font-size:      14px;
      letter-spacing: 1.75px;
      line-height:    20px;
      text-transform: none;
      color:          var(--white);
      font-family:    var(--font-family-special);
  
      &-round {
        border-radius: 6px !important;
        box-shadow:    0 2px 0 0 #d7d7d7 !important;
      }
    }
  
    .btn-outline-secondary {
      border-color: $color !important;
      color:        $color;
        &:hover{
            color: #fff;
        }
    }
  
    .active {
      background-color: $color !important;
      color: #fff!important;
    }
  
    .quantity {
      font-size:      1rem;
      letter-spacing: -0.34px;
      line-height:    22px;
      font-weight:    400;
  
      input {
        display:          inline;
        width:            100px;
        height:           45px;
        border-radius:    4px;
        margin:           0;
        padding-left:     20px;
        border:           1px solid #eeeeee;
        background-color: #eeeeee;
        box-shadow:       inset 0 1px 0 0 rgba(0, 0, 0, 0.07);
      }
  
      input[type=number]::-webkit-inner-spin-button,
      input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin:             0;
        /*opacity: 1;*/
      }
  
      input[type=number] {
        -moz-appearance: textfield;
      }
  
      input:focus {
        outline: 0;
      }
  
      .quantity-nav {
        display:     inline;
        position:    absolute;
        margin-top:  3px;
        margin-left: -24px;
        //float: right;
        //position: relative;
        height:      38px;
      }
  
      .quantity-button {
        position:            relative;
        cursor:              pointer;
        border-left:         1px solid #eeeeee;
        width:               20px;
        text-align:          center;
        font-size:           9px;
        font-family:         "Trebuchet MS", Helvetica, sans-serif !important;
        line-height:         6px;
        padding-top:         6px;
        padding-bottom:      6px;
        -webkit-user-select: none;
        -moz-user-select:    none;
        -ms-user-select:     none;
        -o-user-select:      none;
        user-select:         none;
        background:          grey;
        color:               white;
  
        &.quantity-down {
  
          position:      absolute;
          bottom:        -1px;
          height:        50%;
          border-radius: 0 0 4px 4px;
          border:        1px solid rgba(237, 237, 237, 0.15);
        }
  
        &.quantity-up {
          position:      absolute;
          height:        50%;
          top:           0;
          //border-bottom: 1px solid #eee;
          border-radius: 4px 4px 0 0;
          border:        1px solid rgba(237, 237, 237, 0.15);
        }
      }
    }
  
    .price {
      strike {
        font-size: 14px;
        color:     grey;
      }
    }
  
    .price-lg {
      color:          var(--color-primary);
      font-family:    var(--font-family-base);
  
      font-size:      2.625rem;
      font-weight:    600;
      letter-spacing: -0.9px;
      line-height:    57px;
    }
  
    .price-crossed {
      color:          var(--grey);
      font-family:    var(--font-family-base);
  
      font-size:      1.5rem !important;
      font-weight:    600;
      letter-spacing: -0.77px;
      line-height:    33px;
      opacity:        1;
      margin-left:    15px !important;
        text-decoration: line-through;
    }
  }
  
  .purchase-action-block {
    .btn {
      padding: 10px 32.65px;
    }
  
  }
  
  .email-to-us {
    i {
      margin-right: 5px;
      color:        var(--blue);
    }
  
    a {
      color: var(--blue);
    }
  }
  
  .w-250 {
    width: 250px;
  }
  
  .sku {
    opacity:        0.9;
    color:          #a5a5a5;
    font-family:    var(--font-family-base);
  
    font-size:      12px;
    letter-spacing: -0.26px;
    line-height:    17px;
  }
  
  .shop-loading-icon {
    position:        absolute;
    z-index:         1029;
    width:           100%;
    height:          100%;
    display:         flex;
    justify-content: center;
    align-items:     center;
    font-size:       10rem;
      img{
          z-index: 1030;
          height: 65px;
          width: auto;
          box-shadow: 0 0 30rem 10rem rgba(0,0,0,0.1);
          border-radius: 50%;
          position:fixed;
          bottom: 35%
      }
  }
  
  .list-view.recommend-item {
  
    .list-view-image {
      //height:158px;
      //min-height: 158px;
      .square {
        width:          120px;
        padding-bottom: 120px;
  
        img {
          margin:     auto;
          max-height: 120px;
          width:      auto;
        }
      }
    }
  
    @media (max-width: 576px) {
      padding: 5px 5px 0 0;
    }
    height: auto;
  
    .price {
      min-width: auto;
    }
  
    .badge-danger {
      z-index: 1029;
      top:     -13px;
      left:    -13px;
      @media (max-width: 576px) {
        top:  -5px;
        left: 0;
      }
    }
  
    .recommend-item-title {
      line-height: 30px;
      padding-top: 4px;
    }
  }
  
  #discount-offer{
      margin-top: 10px;
      width: 100%;
      //width: 402px;
      color: #050505;
      font-family: var(--font-family-base);
      font-size: 16px;
      letter-spacing: -0.34px;
      line-height: 24px;
      flex-wrap: wrap;
      max-width: 100%;
      span{
          cursor: pointer;
          line-height: inherit;
          font-size: 24px;
          font-weight: bold;
          color: var(--color-primary);
      }
  }
  
  .spinner-purchasable{
      color: var(--white);
  }
  
  .disabled-overlay{
      background-color: rgba(255,255,255,0.2);
      //height: 64px;
      width: 100%;
      position: absolute;
      cursor: wait;
  }
  
  .product-detail{
      .slider-dots-line {
          .slick-dots {
              flex-wrap: wrap;
              .slick-active {
                  button {
                      width:            30px;
                      background-color: var(--color-primary)
                  }
              }
              li {
                  button {
                      background-color: var(--color-grey);
                      width:            30px;
                      height:           5px;
                  }
              }
          }
      }
  }
  //--------------------------------------------------------------------------
  // Commerce - Order page
  //--------------------------------------------------------------------------
  
  .order-table td {
    vertical-align: middle;
  }
  
  //--------------------------------------------------------------------------
  // Commerce - cart page
  //--------------------------------------------------------------------------
  
  .table-cart {
    thead {
      border-top: 1px solid #f1f2f3;
  
      th {
        border-right: 1px solid #f1f2f3;
      }
    }
  
    .price {
      min-width: 120px !important;
    }
  
    .product-title {
      min-width: 250px;
      a{
          font-size: 14px;
          letter-spacing: 0;
      }
    }
  
    img {
      min-width: 50px;
    }
  
    .input-number {
      min-width: 50px;
    }
  }
  
  .error-message {
    position:    absolute;
    font-weight: 600;
    color:       #af0016 !important;
  }
  
  .error-message-coupon{
      color: #af0016;
  }
  
  //--------------------------------------------------------------------------
  // Commerce - stripe class
  //--------------------------------------------------------------------------
  .stripe-charge-form {
    width: 90% !important;
    @media (max-width: 576px) {
      width: 100% !important;
    }
  }
  
  //--------------------------------------------------------------------------
  // Commerce - shop/list pagination widget
  //--------------------------------------------------------------------------
  .pagination {
    .page-item {
      text-align: center;
      max-width:  40px;
  
      p {
        width:          32px;
        line-height:    32px;
        vertical-align: center;
        text-align:     center;
  
        &.page-link {
          cursor: default;
        }
      }
  
      input {
        height:           32px;
        background-image: linear-gradient(var(--color-primary), var(--color-primary)), linear-gradient(#888888, #888888);;
      }
    }
  }
  
  .filter-no-result-container {
    max-height: 100vh;
    height:     100%;
    display:    flex;
  
    p {
      font-size: 20px;
    }
  }
  
  .noUi-connect{
      background: var(--color-primary);
  }
  