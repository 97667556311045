.recommend-item {
    padding:          13px;
    width:            100% !important;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
    border: 1px solid #bebebe;
    border-radius: 5px;
    .divider-dashed{
        height:  2px;
        width:   100%;
        display: block;
        &:after {
            content:    "";
            position:   absolute;
            top:        75px;
            left:       0px;
            width:      100%;
            height:     1px;
            background: none;
            border-top: 0.5px solid var(--color-primary);
        }
    }
    .badge-danger {
        position:       absolute;
        top:            -13px;
        left:           -13px;
        margin:         0;
        font-family:    var(--font-family-special);
        // background-color: var(--color-dark);
        font-weight:    900;
        letter-spacing: -0.23px;
        line-height:    18px;
    }
      
    &-title {
        //min-height:     38px;
        color:          #050505;
        font-size:      18px;
        font-weight:    bold;
        letter-spacing: -0.34px;
        line-height:    18px;
    }

    &-sub-title {
        min-height:     36.36px;
        color:          #000000;
        font-family:    var(--font-family-base);
        font-size: 1rem; //1rem;//14px;
        letter-spacing: 0.5px;
        text-transform: capitalize;
        line-height:    19px;
    }

    //make the product box higher
    .image {
        margin: 20px auto auto auto;
        width:      50%;
        min-width:  100px;
        float:      left;
        height:     auto;
        min-height: 100px;
    }

    .price {
        min-height: 100px;
        min-width:  106px;
        width:      50%;
        float:      right;
    
        p {
          margin-bottom:  6px;
          color:          #919191;
          font-size:      12px;
          letter-spacing: -0.23px;
          line-height:    12px;
        }
    
        .price-lg {
          color:          var(--color-dark);
          font-weight:    bold;
          letter-spacing: -0.6px;
          font-size:      1.5rem;
          line-height:    1.5rem;
          @media(max-width: 1050px) and(min-width: 992px) {
            font-size:   1.4rem;
            line-height: 1.4rem;
          }
          @media(max-width: 850px) and(min-width: 768px) {
            font-size:   1.4rem;
            line-height: 1.4rem;
          }
        }
    
        .price-md {
          color:          var(--color-grey);
          color:        #000000;
          font-size:      1rem;
          font-weight:    600;
          letter-spacing: -0.3px;
          line-height:    1rem;
          @media(max-width: 1050px) and(min-width: 992px) {
            font-size:   0.8rem;
            line-height: 0.8rem;
          }
          @media(max-width: 850px) and(min-width: 768px) {
            font-size:   0.8rem;
            line-height: 0.8rem;
          }
    
          &:hover {
            color:              var(--color-dark);
            -webkit-transition: all 200ms ease-in;
            -webkit-transform:  scale(1.5) translate(8px);
            -ms-transition:     all 200ms ease-in;
            -ms-transform:      scale(1.5) translate(8px);
            -moz-transition:    all 200ms ease-in;
            -moz-transform:     scale(1.5) translate(8px);
            transition:         all 200ms ease-in;
            transform:          scale(1.5) translate(8px);
          }
        }
        .price-lowest {
              p{
                  cursor: pointer;
                  font-size: 0.8rem;
                  font-weight: 600;
                  letter-spacing: -0.3px;
                  line-height: 0.8rem;
                  margin-bottom: 2px;
                  margin-right: -30px;
              }
              span{
                  cursor: pointer;
                  font-weight: 600;
                  letter-spacing: -0.3px;
                  margin-right: -20px;
                  line-height: 1.3rem;
                  font-size: 1.3rem;
              }
              i{
                  color: var(--color-danger);
                  cursor: pointer;
              }
    
              @media(max-width: 1050px) and(min-width: 992px) {
                  span{
                      font-size: 1rem;
                      line-height: 1.3rem;
                  }
              }
              @media(max-width: 850px) and(min-width: 768px) {
                  span{
                      font-size: 1rem;
                      line-height: 1.3rem;
                  }
              }
          }
      }
    
    .label-description {
        font-size: 12px !important;
    }
    .price span {
        font-size: 1rem !important;
    }

    .details {
        .add-to-cart {
            line-height:    28px;
            font-weight:    bold;
            letter-spacing: 1.5px;
            margin-top:     22px;
            text-align:     center;
            position:       relative;
            z-index:        2;
            cursor:         pointer;
        }

        .error-message {
            position:    absolute;
            font-weight: 700;
            color:       #af0016;
            z-index:     3;
            margin-top:  -19px;
            width:       100%;
            left:        0;
        }
    }

    .add-to-cart-list {
        i {
            font-size:      1rem;
            letter-spacing: 5px;
            line-height:    21px;
        }

        .fa-ban {
            color: var(--color-text-light);
        }

        .fa-chevron-up {
            color: var(--color-primary);
        }

        span {
            font-weight: 600;
            color:       var(--color-primary);
            font-size:   1rem;
        }
    }

    .wish-list-icon {
        font-size: 1.56rem;
        color:     var(--color-danger);

        &.fa-heart-o {
            color: #333333;
        }
    }

    .price-description {
        white-space: nowrap;
    }

    .title-section {
        min-height: 77px;
    }
}