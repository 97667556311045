#cart-dropdown-wrapper{
    .cart-dropdown-img{
        img{
            max-height: 80px;
        }
    }
    
    .row-line-items-image{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .cart-dropdown-ptitle{
        font-size: 14px;
    }
}