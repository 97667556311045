.dropdown {
    display: inline-block;
}

.dropdown__content {
    display: none;
    position: absolute;
}

.dropdown--active .dropdown__content {
    display: block;
}

.dropdown--active .dropdown__content{
    position: inherit !important;//absolute;
}

//------Update-------
.dropdown__content {
    display: none;
    position: inherit !important;
}

.dropup, .dropright, .dropdown, .dropleft {
    position: inherit;
}

.section-sub-menu{
    display: flex;
    justify-content: space-around;
    height:310px;
    overflow-y: auto;
}