.body-cover {
    z-index: 1042;
}
.shop-loading-icon {
    img {
        position: fixed;
        bottom: auto;
    }
}
.shop-loading-icon::before {
    background: #fff;
    opacity: 0.8 !important;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
}

#product-container .shop-loading-icon {
    img {
        top: calc(50vh + 32.5px);
    }
}
