//----------------------------------------
// Addresses (Customer Info)
//----------------------------------------
.error-message{
    color: var(--red);
}
  
.custom-radio .custom-control-label::after{
    top: 5px !important;
}
  
.fa-times, .fa-pencil{
    font-size: 18px;
    color: var(--white) !important;
}
  
.fa-times{
    color: var(--red)!important;
}
  
#couponCode,
#customer-info-form input,
#customer-info-form select,
#customer-login-form input,
#paymentForm input{
    height:45px;
    &:focus{
        border-color: var(--color-primary);
    }
}
  
.btn-outline-dark{
    border-color: var(--color-info) !important;
    &:hover{
        background-color: var(--color-info) !important;
    } 
}

.btn-outline-dark.active {
    background-color: var(--color-info) !important;
    border-color: var(--color-info) !important;
    color:white !important
}

#customer-info-form{
    .input-group.focus{
        border-color: var(--color-primary)!important;
    }
}
  
.cart-info{
    &:before{
        content: "";
        @include media-breakpoint-down(sm){
            content: none;
        }
        position: absolute;
        top: 0px;
        left: -1px;
        width: 1px;
        height: 100%;
    }
}
  
  .badge-number-right{
    position: relative;
    font-size: 0.8rem;
    padding: 10px 4px;
    border-radius: 10rem;
    min-width: 2rem;
    min-height: 2rem;
  }
  
  .cart-right-img img{
    border-radius: 3px;
  }
  
  .collectBox{
    flex:50%;
    max-width: 100%;
    line-height: 20px;
    cursor: pointer;
  
    @media (max-width: 400px) {
      flex: 100% !important;
      max-width: 100% !important;
      margin-top:5px;
    }
  }
  
  .jumbotron{
    background-color: #39535F !important;
    color: var(--white) !important;
  }
  
  
  
  
  //----------------------------------------
  // Delivery
  //----------------------------------------
  .place-2-column{
    margin-left: 1px;
    .place-2-column-box{
      border:1px solid #DDE3E8;
    }
    p{
      color: #788995;
    }
  }
  
  .shipping-method-column{
    margin-left: 2%;
    max-width:45%;
    @media (max-width: 767px) {
      max-width:96%;
    }
  
    border:1px solid #DDE3E8;
    border-bottom: 3px solid #DDE3E8;
    p{
      color: #788995;
    }
  }
  
  
  .ml-1px{
    margin-left: 1px;
  }

  .mx-1px{
    margin-left: 1px;
    margin-right: 1px;
  }

  //----------------------------------------
  // Payment
  //----------------------------------------
  .table-summary{
    border:none !important;
  }
  
  .payment-box{
    border:1px solid #DDE3E8;
    p{
      color: #788995
    }
  }
  
  //----------------------------------------
  // Stripe Form
  //----------------------------------------
  .stripe-form{
    width: 100% !important;
    @media (max-width: 992px) {
      width: 100% !important;
    }
  }
  
  .card-errors, .info-errors{
    color: var(--red);
  }
  
  .StripeElement {
    width: 100%;
    //height: calc(1.96875rem + 2px);
    height:45px;
    //padding: .5rem .75rem;
    //font-size: .9375rem;
    //line-height: 1.3;
  
    padding: 0.8rem .75rem;
    &:focus{
      border-color: var(--color-primary);
    }
  
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #eaeff4;
    border-radius: 2px;
    transition: all 0.3s ease-out;
  }
  
  .InputElement::placeholder{
    color: var(--red) !important;
  }
  
  .StripeElement--focus {
    box-shadow: 0 1px 3px 0 var(--color-primary);
    border-color: var(--color-primary);
  }
  
  .StripeElement--invalid {
    border-color: var(--color-red);
  }
  
  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }