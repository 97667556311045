.longTextHide{
    color: inherit;
    .special-overlay{
      width: 100%;
      height: 50%;
      position: absolute;
      bottom: 0;
      left: 0;
  
    }
    .rich-text{
      color: inherit;
      transition:all 2s ease-in-out;
      position: relative;
      &.hide-some{
        max-height: 150px;
        overflow-y: hidden;
        transition:all 2s ease-in-out;
        &:before{
          transition:all 2s ease-in-out;
          top: 20%;
          bottom: 0;
          opacity: .9;
          background: #fff;
          position: absolute;
          content: '';
          left: 0;
          right: 0;
          border-radius: inherit;
          background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, #f1f1f1 100%);
          box-sizing: border-box;
          margin: 0;
          padding: 0;
        }
      }
    }
    .hide-some-button{
      font-size: 14px;
      z-index: 900;
      cursor: pointer;
      color: var(--color-primary);
      text-align: right;
      transition: 2s;
      -webkit-transition: 2s;
      i{
        color: var(--color-primary);
      }
    }
    .show-more-button{
      font-size: 14px;
      z-index: 900;
      text-align: right;
      cursor: pointer;
      color: var(--color-primary);
      transition: 2s;
      -webkit-transition: 2s;
      i{
        color: var(--color-primary);
      }
    }
  }